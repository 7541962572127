/* eslint-disable react/prop-types */
/* eslint-disable react/jsx-props-no-spreading */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import CircularProgress from '@mui/material/CircularProgress';
import InputLabel from '@mui/material/InputLabel';
import ImageSearchIcon from '@mui/icons-material/ImageSearch';
import DeleteIcon from '@mui/icons-material/Delete';
import { makeStyles } from '@mui/styles';
import { Typography } from '@mui/material';

const propTypes = {
  value: PropTypes.string,
  label: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  required: PropTypes.bool.isRequired,
};

const defaultProps = {
  value: '',
};

const useStyles = makeStyles({
  imageWrapper: {
    minHeight: 60,
    backgroundColor: '#eee',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'relative',
  },
  image: {
    maxWidth: '100%',
    overflow: 'hidden',
    padding: 5,
    paddingLeft: 60,
    visibility: 'hidden',
    height: 0,

    '&.loaded': {
      padding: 0,
      visibility: 'visible',
      height: 'initial',
    },

    '&.error': {
      visibility: 'visible',
      height: 'initial',
    },
  },
  deleteButton: {
    '&.MuiIconButton-root': {
      background: 'white',
      position: 'absolute',
      top: 5,
      left: 5,
      margin: 5,
      '&:hover': {
        background: '#f6f6f6',
      },
    },
  },
  imageButton: {
    '&.MuiButton-root': {
      width: 'fit-content',
      background: 'white',
    },
  },
  widgetWrapper: {
    margin: '15px 0',
  },
  label: {
    '&.MuiFormLabel-root': {
      position: 'relative',
      left: -10,
    },
  },
});

const ImageUpload = ({ label, required, onChange, value, uiSchema }) => {
  const classes = useStyles();
  const [imageLoaded, setImageLoaded] = useState(false);
  const [loadingError, setLoadingError] = useState(false);
  const message = uiSchema?.['ui:options']?.['ui:message'];

  useEffect(() => {
    setImageLoaded(false);
    setLoadingError(false);
  }, [value]);

  return (
    <div className={classes.widgetWrapper}>
      <InputLabel shrink error={required && !value} className={classes.label}>
        {label}
      </InputLabel>
      {value && (
        <div className={classes.imageWrapper}>
          {!imageLoaded && !loadingError && (
            <CircularProgress size={20} style={{ padding: 10 }} />
          )}
          {(imageLoaded || loadingError) && (
            <IconButton
              className={classes.deleteButton}
              onClick={() => onChange('')}
            >
              <DeleteIcon />
            </IconButton>
          )}
          <img
            src={value}
            alt={value}
            className={`${classes.image} ${imageLoaded && 'loaded'} ${
              loadingError && 'error'
            }`}
            onLoad={() => setImageLoaded(true)}
            onError={() => setLoadingError(true)}
          />
        </div>
      )}
      {!value && (
        <Button
          disableElevation
          variant="outlined"
          component="label"
          startIcon={<ImageSearchIcon />}
          className={classes.imageButton}
        >
          Bild wählen
          <input
            type="file"
            hidden
            accept="image/*"
            onChange={(e) => {
              const [file] = e.target.files;
              const reader = new FileReader();
              reader.onload = (evt) => onChange(evt.target.result);
              reader.readAsDataURL(file);
            }}
          />
        </Button>
      )}
      {message && (
        <Typography paragraph variant="subtitle2" style={{ marginTop: 15 }}>
          <i>{message}</i>
        </Typography>
      )}
    </div>
  );
};

ImageUpload.propTypes = propTypes;
ImageUpload.defaultProps = defaultProps;
export default ImageUpload;
