import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Draw } from 'ol/interaction';
import TextField from '@mui/material/TextField';
import { makeStyles } from '@mui/styles';
import IconButton from '@mui/material/IconButton';
import MyLocation from '@mui/icons-material/MyLocation';
import { unByKey } from 'ol/Observable';
import AppPropTypes from '../../../model/app/propTypes';

const useStyles = makeStyles({
  iconButtonActive: {
    color: '#c60018',
    backgroundColor: 'rgba(0, 0, 0, 0.10) !important',
  },
});

const drawInteraction = new Draw({
  type: 'Point',
});

const CoordinatesPickerField = ({
  formData,
  onChange,
  formContext,
  schema,
}) => {
  const classes = useStyles();
  const [active, setActive] = useState(false);
  let drawEndKey;

  const removeDrawInteraction = () => {
    const { map } = formContext;
    map.removeInteraction(drawInteraction);
    unByKey(drawEndKey);
    setActive(false);
  };

  const drawEndCallback = (evt) => {
    const stringCoordinates = evt.feature
      .getGeometry()
      .getFlatCoordinates()
      .map((coord) => parseFloat(coord.toFixed(4)))
      .join();
    onChange(stringCoordinates);
    removeDrawInteraction();
  };

  const addDrawInteraction = () => {
    const { map } = formContext;
    drawEndKey = drawInteraction.on('drawend', drawEndCallback);
    map.addInteraction(drawInteraction);
    setActive(true);
  };

  return (
    <TextField
      label={schema.title}
      fullWidth
      InputProps={{
        endAdornment: (
          <IconButton
            size="small"
            className={active ? classes.iconButtonActive : null}
            onClick={() => {
              if (!active) {
                addDrawInteraction();
              } else {
                removeDrawInteraction();
              }
            }}
          >
            <MyLocation />
          </IconButton>
        ),
      }}
      onChange={(evt) => {
        onChange(evt.target.value);
      }}
      value={formData}
    />
  );
};

CoordinatesPickerField.propTypes = {
  schema: AppPropTypes.schema.isRequired,
  formData: PropTypes.string.isRequired,
  formContext: AppPropTypes.formContext.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default CoordinatesPickerField;
