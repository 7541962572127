// From react-jsonschema-form
// https://github.com/rjsf-team/react-jsonschema-form
export const dataUrlRegex = new RegExp(
  /^data:([a-z]+\/[a-z0-9-+.]+)?;(?:name=(.*);)?base64,(.*)$/,
);
export const uriRegex = /^$|^(http|ftp)s?:\/\/\S*(\.[a-zA-Z]{2}|:[0-9]{1,5}).*/;

const coordsRegex = /^(-?\d+(\.\d+)?),\s*(-?\d+(\.\d+)?)$/;
const emptyStringRegex = /^\s*$/;

const customFormats = {
  'data-url-or-uri': new RegExp(
    `${dataUrlRegex.source}|${uriRegex.source}`,
    'i',
  ),
  uri: uriRegex,
  coordinates: new RegExp(`${emptyStringRegex.source}|${coordsRegex.source}`),
};

export default customFormats;
