import Feature from 'ol/Feature';
import WKTFormat from 'ol/format/WKT';
import fields from '../../components/fields';
import {
  ADD_FEATURE,
  SET_SCHEMA,
  SET_UI_SCHEMA,
  SET_FEATURES,
  SET_ROUTING_FEATURES,
  IS_FEATURES_LOADING,
  SET_SELECTED_FEATURE,
  SET_SELECTABLE_FEATURES,
  SET_ACTIVE_BUTTON,
  SET_LIST_VISIBLE,
  SET_FILTERS,
  SET_ERROR,
  UPDATE_FEATURE,
  UPDATE_FEATURES,
  DELETE_FEATURE,
  SET_TOPIC,
  SET_TOPICS,
  SET_DIALOG_VISIBLE,
  UPLOAD_FILE,
  SET_NOTIFICATION,
  SET_CURRENT_PUBLICATION,
  SET_API_KEY,
  SET_API_ENDPOINTS,
  SET_OLE,
  SET_BULK_FEATURES,
  SET_BACKEND_API_URL,
  SET_SIDEBAR_OPEN,
  SET_IS_PREVIEW_ACTIVE,
  SET_PREVIEW_ENABLE_VALUE,
  SET_IS_PREVIEW_LOADING,
  SET_LOGIN_STATUS,
  SET_IS_FORM_UNSAVED,
  SET_IS_CANCEL_DIALOG_VISIBLE,
  SET_CANCEL_DIALOG_ON_ACTION,
  SET_FORM_DATA,
  SET_HISTORY,
  SET_IGNORE_FIELDS_ON_SAVE,
} from './actions';

const initialState = {
  schema: {},
  uiSchema: {},
  features: [],
  routingFeatures: [],
  filters: {},
  isFeaturesLoading: false,
  selectedFeature: null,
  selectableFeatures: [],
  error: null,
  isListVisible: false,
  isMenuOpen: false,
  topic: null,
  topics: null,
  notification: null,
  staticFilesUrl: null,
  apiKey: process.env.REACT_APP_API_KEY,
  apiEndpoints: {},
  currentPublication: null,
  ole: null,
  bulkFeatures: [],
  sidebarOpen: false,
  previewEnabled: true,
  isPreviewActive: false,
  isPreviewLoading: false,
  isFormUnsaved: false,
  loginStatus: null,
  fields,
  isCancelDialogVisible: false,
  history: null,
  ignoreFieldsOnSave: [],
};

const format = new WKTFormat();

export default function messtischApp(state = initialState, action) {
  const { data } = action;

  switch (action.type) {
    case SET_SCHEMA: {
      return {
        ...state,
        schema: data,
      };
    }

    case SET_UI_SCHEMA: {
      return {
        ...state,
        uiSchema: data,
      };
    }

    case SET_BACKEND_API_URL: {
      return {
        ...state,
        backendApiUrl: data,
      };
    }

    case SET_FEATURES: {
      const features = [];
      return {
        ...state,
        allFeatures: features,
        features: action.data,
      };
    }

    case SET_ROUTING_FEATURES: {
      return {
        ...state,
        routingFeatures: action.data,
      };
    }

    case IS_FEATURES_LOADING: {
      return {
        ...state,
        isFeaturesLoading: action.data,
      };
    }

    case ADD_FEATURE: {
      // When we create a new feature, we apply the new properties to the
      // current selected feature (the one without id),
      // to avoid unnecessary render and wierd behaviors.
      let { selectedFeature } = state;
      if (selectedFeature) {
        selectedFeature.setProperties(data);
      } else {
        selectedFeature = new Feature(data);
      }
      if (data.geom) {
        selectedFeature.setGeometry(
          format.readGeometry(data.geom.split(';')[1]),
        );
      }
      return {
        ...state,
        selectedFeature,
      };
    }
    case DELETE_FEATURE: {
      const isNotDeleted = (feature) => feature.get('id') !== action.data;
      return {
        ...state,
        features: state.features.filter(isNotDeleted),
        routingFeatures: state.routingFeatures.filter(isNotDeleted),
      };
    }
    case UPDATE_FEATURE: {
      const { features, selectedFeature, prevSelectedFeature } = state;
      const featureToUpdate = [selectedFeature, prevSelectedFeature].find(
        (feat) => feat.get('id') === data.id,
      );
      featureToUpdate.setProperties(data);

      if (data.geom) {
        featureToUpdate.setGeometry(
          format.readGeometry(data.geom.split(';')[1]),
        );
      }

      for (let i = 0, len = features.length; i < len; i += 1) {
        if (features[i].get('id') === featureToUpdate.get('id')) {
          features[i] = selectedFeature;
          break;
        }
      }

      return {
        ...state,
        features,
        selectedFeature,
      };
    }
    case UPDATE_FEATURES: {
      const { features } = state;

      for (let i = 0; i < features.length; i += 1) {
        const newData = data.find((d) => d.id === features[i].get('id'));
        if (newData) {
          features[i].setProperties({
            ...features[i].getProperties(),
            ...newData,
          });
        }
      }
      return {
        ...state,
        features: [...features],
        selectedFeature: null,
      };
    }
    case SET_SELECTED_FEATURE:
      return data
        ? {
            ...state,
            selectedFeature: data,
            prevSelectedFeature: state.selectedFeature,
          }
        : {
            ...state,
            selectedFeature: data,
            isFormUnsaved: false,
            prevSelectedFeature: undefined,
            isCancelDialogVisible: false,
            formData: undefined,
          };
    case SET_SELECTABLE_FEATURES:
      return {
        ...state,
        selectableFeatures: data,
      };
    case SET_ACTIVE_BUTTON:
      return {
        ...state,
        activeButton: action.data,
      };
    case SET_LIST_VISIBLE:
      return {
        ...state,
        isListVisible: data,
      };
    case SET_FILTERS:
      return {
        ...state,
        filters: { ...data },
      };
    case SET_DIALOG_VISIBLE:
      return {
        ...state,
        ...data,
      };
    case SET_ERROR:
      return {
        ...state,
        error: { ...data },
      };
    case SET_TOPIC:
      state.history?.push(`/${data.key}`);
      return {
        ...state,
        topic: data,
        schema: data.schema,
        uiSchema: data.uiSchema,
      };
    case SET_TOPICS:
      return {
        ...state,
        topics: data,
      };
    case UPLOAD_FILE:
      return {
        ...state,
        uploadedData: { ...data },
      };
    case SET_NOTIFICATION:
      return {
        ...state,
        notification: data,
      };
    case SET_CURRENT_PUBLICATION:
      return {
        ...state,
        currentPublication: data,
      };
    case SET_API_KEY:
      return {
        ...state,
        apiKey: data,
      };
    case SET_API_ENDPOINTS:
      return {
        ...state,
        apiEndpoints: data,
      };
    case SET_OLE:
      return {
        ...state,
        ole: data,
      };
    case SET_BULK_FEATURES:
      return {
        ...state,
        bulkFeatures: [...data],
      };
    case SET_SIDEBAR_OPEN:
      return {
        ...state,
        sidebarOpen: data,
      };
    case SET_IS_PREVIEW_ACTIVE:
      return {
        ...state,
        isPreviewActive: data,
      };
    case SET_PREVIEW_ENABLE_VALUE:
      return {
        ...state,
        previewEnableValue: data,
      };
    case SET_IS_PREVIEW_LOADING:
      return {
        ...state,
        isPreviewLoading: data,
      };
    case SET_IS_FORM_UNSAVED:
      return {
        ...state,
        isFormUnsaved: data,
      };
    case SET_LOGIN_STATUS:
      return {
        ...state,
        loginStatus: data,
      };
    case SET_IS_CANCEL_DIALOG_VISIBLE:
      return {
        ...state,
        isCancelDialogVisible: data,
        isCancelDialogOnAction: null,
      };
    case SET_CANCEL_DIALOG_ON_ACTION:
      return {
        ...state,
        cancelDialogOnAction: data,
      };
    case SET_FORM_DATA:
      return {
        ...state,
        formData: data,
      };
    case SET_HISTORY:
      return {
        ...state,
        history: data,
      };
    case SET_IGNORE_FIELDS_ON_SAVE:
      return {
        ...state,
        ignoreFieldsOnSave: data,
      };
    default:
      return {
        ...state,
      };
  }
}
