import { createTheme } from '@mui/material';
import { deDE } from '@mui/x-data-grid';

const muiTranslations = { de: deDE };

const primary = '#515151';

const fontSize = '1rem';
const theme = createTheme(
  {
    palette: {
      primary: {
        main: primary,
      },
      secondary: {
        main: '#eb0000',
      },
      text: {
        primary,
      },
    },
    typography: {
      // override mui controlled font sizes
      // the initial font size has to be set on the page's html element
      // see https://material-ui.com/customization/typography/
      body1: { fontSize, color: primary },
      subtitle2: { color: primary },
      button: { fontSize },
    },
    components: {
      MuiDataGrid: {
        defaultProps: {
          disableVirtualization: true,
        },
        styleOverrides: {
          root: {
            borderRadius: 0,
            '& .MuiDataGrid-footerContainer': {
              minHeight: 59,
            },
          },
        },
      },
      MuiPaper: {
        defaultProps: {
          square: true,
        },
      },
      MuiFormControl: {
        defaultProps: {
          margin: 'dense',
        },
      },
      MuiTextField: {
        defaultProps: {
          margin: 'dense',
          variant: 'standard',
        },
        styleOverrides: {
          root: {
            '& .MuiInput-root': {
              padding: '0 5px',
            },
          },
        },
      },
      MuiAutocomplete: {
        styleOverrides: {
          endAdornment: {
            padding: '2px 10px',
          },
        },
      },
      MuiButtonBase: {
        defaultProps: {
          disableRipple: true,
        },
        styleOverrides: {
          root: {
            '&.Mui-focusVisible': {
              outline: `1px solid ${primary}`,
            },
          },
        },
      },
      MuiToggleButtonBase: {
        defaultProps: {
          disableRipple: true,
        },
      },
      MuiButton: {
        styleOverrides: {
          root: {
            textTransform: 'unset',
            borderRadius: 0,
            '&:hover': {
              backgroundColor: '#eee',
            },
            '&.MuiButton-outlined': {
              borderColor: '#eee',
            },
          },
          text: {
            padding: '5px 10px',
          },
        },
      },
      MuiIconButton: {
        styleOverrides: {
          root: {
            padding: 10,
          },
        },
      },
      MuiCheckbox: {
        styleOverrides: {
          root: {
            padding: 3,
            margin: '0 5px',
            '&.Mui-checked': {
              color: '#eb0000',
            },
          },
        },
      },
      MuiSelect: {
        defaultProps: {
          variant: 'standard',
        },
      },
      MuiInput: {
        styleOverrides: {
          root: {
            '& .MuiInput-input': {
              maxWidth: 450,
            },
          },
          underline: {
            '&:before': {
              borderBottom: `1.5px solid rgba(0, 0, 0, 0.26)`,
            },
          },
        },
      },
      MuiListItemIcon: {
        styleOverrides: {
          root: {
            width: 45,
            height: 40,
            padding: 10,
            minWidth: 0,
            display: 'flex',
            justifyContent: 'center',
          },
        },
      },
      MuiAccordion: {
        styleOverrides: {
          root: {
            '& fieldset': {
              border: 'none',
            },
          },
        },
      },
      MuiAccordionDetails: {
        styleOverrides: {
          root: {
            padding: 0,
          },
        },
      },
      MuiDrawer: {
        styleOverrides: {
          root: {
            '& .MuiPaper-root': {
              boxShadow: '1px 2px 6px rgba(0, 0, 0, 0.199)',
            },
          },
        },
      },
      MuiFormControlLabel: {
        styleOverrides: {
          root: {
            marginLeft: -8,
          },
        },
      },
    },
  },
  muiTranslations,
);

export default theme;
