import React from 'react';
import PropTypes from 'prop-types';
import { TextField } from '@mui/material';
import { format, isValid } from 'date-fns';

const propTypes = {
  value: PropTypes.string,
  label: PropTypes.string.isRequired,
  options: PropTypes.shape({
    dateFormat: PropTypes.string,
    required: PropTypes.bool,
    readonly: PropTypes.bool,
  }),
};

const defaultProps = {
  value: '#000000',
  options: {},
};

/**
 * This component display a date string using a specific locale from ISO string in a text input.
 */
const LocaleDateTime = (props) => {
  const {
    value,
    label,
    options: { dateFormat, required, readonly },
  } = props;
  const date = new Date(value);
  return (
    <>
      <TextField
        label={label}
        required={required}
        disabled={readonly}
        value={
          isValid(date) ? format(date, dateFormat || 'yyyy-MM-dd HH:mm:ss') : ''
        }
      />
    </>
  );
};

LocaleDateTime.propTypes = propTypes;
LocaleDateTime.defaultProps = defaultProps;
export default LocaleDateTime;
