import React, { useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { makeStyles } from '@mui/styles';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { setSelectedFeature } from '../../model/app/actions';

const propTypes = {
  onClose: PropTypes.func.isRequired,
};

const useStyles = makeStyles({
  root: {
    padding: '10px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    background: '#eee',
    zIndex: 500,
    boxShadow: '0 1px 1px 0 #e3e3e3',
    '& div[role="button"]': {
      height: '1em',
      width: '1em',
    },
  },
  closeButton: {
    padding: 8,
  },
  formTitle: {
    padding: 10,
  },
});

const FormHeader = ({ onClose }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const dispatch = useDispatch();

  // redux
  const topic = useSelector((state) => state.app.topic);
  const selectedFeature = useSelector((state) => state.app.selectedFeature);
  const bulkFeatures = useSelector((state) => state.app.bulkFeatures);
  const selectableFeatures = useSelector(
    (state) => state.app.selectableFeatures,
  );

  const uniqueFeats = useMemo(() => {
    return selectableFeatures.reduce((uniques, current) => {
      return uniques.find((f) => f.get('id') === current.get('id'))
        ? uniques
        : [...uniques, current];
    }, []);
  }, [selectableFeatures]);

  // state
  const [selectFeatureAnchorEl, setSelectFeatureAnchorEl] = useState(null);

  return (
    <div className={classes.root}>
      {uniqueFeats.length < 2 && (
        <span className={classes.formTitle}>
          {bulkFeatures.length < 2 &&
            topic &&
            topic.getFeatureTitle &&
            topic.getFeatureTitle(selectedFeature)}
          {bulkFeatures.length > 1
            ? `${bulkFeatures.length} Elemente gewählt`
            : ''}
        </span>
      )}
      {uniqueFeats.length >= 2 && (
        <>
          <Button
            onClick={(evt) => setSelectFeatureAnchorEl(evt.currentTarget)}
            endIcon={
              selectFeatureAnchorEl ? <ExpandLessIcon /> : <ExpandMoreIcon />
            }
          >
            {topic &&
              topic.getFeatureTitle &&
              topic.getFeatureTitle(selectedFeature)}
          </Button>
          <Menu
            anchorEl={selectFeatureAnchorEl}
            open={!!selectFeatureAnchorEl}
            onClose={() => setSelectFeatureAnchorEl()}
          >
            {uniqueFeats.map((f) => (
              <MenuItem
                disabled={f.get('id') === selectedFeature.get('id')}
                onClick={() => {
                  dispatch(setSelectedFeature(f));
                  setSelectFeatureAnchorEl(null);
                }}
                key={f.get('id')}
              >
                {topic && topic.getFeatureTitle && topic.getFeatureTitle(f)}
              </MenuItem>
            ))}
          </Menu>
        </>
      )}
      <IconButton
        className={classes.closeButton}
        title={t('Schliessen')}
        onClick={onClose}
      >
        <CloseIcon />
      </IconButton>
    </div>
  );
};

FormHeader.propTypes = propTypes;

export default React.memo(FormHeader);
