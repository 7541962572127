import React, { useEffect, useState } from 'react';
import * as Sentry from '@sentry/react';
import {
  BrowserRouter,
  Switch as Routes,
  Route,
  Redirect,
} from 'react-router-dom';
import Messtisch from './components/Messtisch';
import HttpForbidden from './HttpForbidden';
import topics from './topics';

import './App.css';

const {
  REACT_APP_SENTRY_PROJECT_DSN,
  REACT_APP_ENVIRONMENT,
  REACT_APP_SENTRY_RELEASE,
  REACT_APP_API_KEY,
  REACT_APP_BACKEND_URL,
  REACT_APP_ALLOW_PUBLICATION,
} = process.env;

// Only use Sentry on deployed instance, not locally.
if (REACT_APP_ENVIRONMENT) {
  Sentry.init({
    dsn: REACT_APP_SENTRY_PROJECT_DSN,
    environment: REACT_APP_ENVIRONMENT,
    release: REACT_APP_SENTRY_RELEASE,
  });
}

const App = () => {
  const backendApiUrl = REACT_APP_BACKEND_URL;
  const [loginStatus, setLoginStatus] = useState();
  const [permissionTopics, setPermissionTopics] = useState();

  useEffect(() => {
    fetch(`${backendApiUrl}/accounts/users/current/`, {
      credentials: 'include',
    })
      .then((res) => res.json())
      .then((res) => setLoginStatus(res))
      .catch(() => setLoginStatus({}));
  }, [backendApiUrl]);

  useEffect(() => {
    if (loginStatus) {
      fetch(`${backendApiUrl}/permission_topics/`, { credentials: 'include' })
        .then((res) => res.json())
        .then((res) => {
          const pTopics = res.write_permissions;
          setPermissionTopics(
            topics.filter(
              (t) =>
                pTopics.includes(`trafimage_cartaro.topics.${t.key}`) ||
                pTopics.includes(
                  `trafimage_cartaro.topics.${t.permissionTopic}`,
                ),
            ),
          );
        })
        .catch(() => setPermissionTopics([]));
    }
  }, [backendApiUrl, loginStatus]);

  if (!loginStatus || !permissionTopics) {
    return <div className="login-message">Logging in...</div>;
  }

  // 2nd condition avoid infinite loop
  if (!loginStatus.is_authenticated) {
    const next = `${encodeURIComponent(window.location.href)}`;
    window.location.assign(`${loginStatus.authentication_url}?next=${next}`);
  }

  if (!permissionTopics.length) {
    return <HttpForbidden />;
  }

  return (
    <BrowserRouter>
      <Routes>
        <Route exact path="/">
          <Redirect to={`/${permissionTopics[0].key}`} />
        </Route>
        <Route path="/:topicKey+">
          <div className="App">
            <Messtisch
              apiKey={REACT_APP_API_KEY}
              backendApiUrl={REACT_APP_BACKEND_URL}
              allowPublication={REACT_APP_ALLOW_PUBLICATION === 'true'}
              topics={permissionTopics}
              loginStatus={loginStatus}
            />
          </div>
        </Route>
      </Routes>
    </BrowserRouter>
  );
};

export default App;
