import { useEffect, useState } from 'react';

let stateTimout = null;

const useTransientState = (timeout = 5000) => {
  const [data, setData] = useState(null);

  useEffect(() => {
    clearTimeout(stateTimout);
    if (data) {
      stateTimout = setTimeout(() => setData(null), timeout);
    }
    return () => clearTimeout(stateTimout);
  }, [timeout, data]);

  return [data, setData];
};

export default useTransientState;
