import { Style, Stroke } from 'ol/style';
import uiSchemas from '../uiSchemas';
import { toDateString } from '../utils';

const { REACT_APP_BACKEND_URL, REACT_APP_ENVIRONMENT } = process.env;

const choicesFromArray = (arr, attr) => {
  const choices = {};
  arr.forEach((item) => {
    choices[item[attr]] = item[attr];
  });

  // sort by key and return
  return Object.entries(choices)
    .sort((a, b) => a[1].localeCompare(b[1]))
    .reduce((r, [k, v]) => ({ ...r, [k]: v }), {});
};

const regionColors = {
  Ost: '#2F9F48',
  Süd: '#DC320A',
  West: '#FFCC00',
  Mitte: '#A083C7',
};

const fallbackRegionColor = '#00FFFF';

const regionSelect = {
  type: 'select',
  field: 'region__name',
  label: 'Regionen',
  multiple: true,
  fetchChoices: () => {
    return fetch(`${REACT_APP_BACKEND_URL}/netzentwicklung/region/form/`, {
      credentials: 'include',
    })
      .then((res) => res.json())
      .then((res) => choicesFromArray(res, 'name'));
  },
};

const lineSelect = {
  type: 'search',
  field: 'line_number',
  label: 'Linien-Nummer',
};

const roleSelect = {
  type: 'select',
  field: 'rollen__typ',
  label: 'Rollen',
  multiple: true,
  fetchChoices: () => {
    return fetch(`${REACT_APP_BACKEND_URL}/netzentwicklung/rollen/`, {
      credentials: 'include',
    })
      .then((res) => res.json())
      .then((res) => {
        const choices = {};
        res.sort().forEach((rolleTyp) => {
          choices[rolleTyp] = rolleTyp;
        });
        return choices;
      });
  },
};

const getFeatureTitle = (f) => {
  /* eslint-disable camelcase */
  const { line_number, km_start, km_end } = f.get('line_segment') || {};
  return `Linie ${line_number} (${km_start} - ${km_end})`;
};

export default (baseLayers) => [
  {
    key: 'netzentwicklung',
    name: 'Netzentwicklung',
    oleConfig: {
      canAddGeometries: true,
    },
    uiSchema: uiSchemas.netzentwicklung,
    allowTilePublication: REACT_APP_ENVIRONMENT === 'prod',
    baseLayers,
    styleFunction: (f) =>
      new Style({
        stroke: new Stroke({
          width: 5,
          color: regionColors[f.get('region')] || fallbackRegionColor,
        }),
      }),
    getFeatureTitle,
    filterConfig: [lineSelect, regionSelect, roleSelect],
    listConfig: {
      checkboxSelection: true,
      getColumns: () => [
        { field: 'linie', headerName: 'Linie', flex: 1 },
        { field: 'region', headerName: 'Region', flex: 1 },
        { field: 'rollen', headerName: 'Rollen', flex: 1 },
        { field: 'modified_at', headerName: 'Bearbeitet am', flex: 1 },
        { field: 'modified_by', headerName: 'Bearbeitet durch', flex: 1 },
      ],
      getRow: (feature) => ({
        linie: getFeatureTitle(feature),
        region: feature.get('region'),
        rollen: feature.get('rollen')
          ? feature
              .get('rollen')
              .map((r) => `${r.person?.label}: ${r.typ}`)
              .join(', ')
          : '',
        modified_at: toDateString(feature.get('modified_at')),
        modified_by: feature.get('modified_by'),
      }),
    },
    personMutateEnabled: true,
  },
  {
    key: 'netzentwicklung/region',
    name: 'Netzentwicklung Regionen',
    permissionTopic: 'netzentwicklung',
    uiSchema: uiSchemas.netzentwicklungRegion,
    oleConfig: {
      canAddGeometries: true,
    },
    getFeatureTitle: (feat) => feat.get('name'),
    listConfig: {
      getColumns: () => [
        { field: 'region', headerName: 'Region', flex: 1 },
        { field: 'rollen', headerName: 'Rollen', flex: 1 },
        { field: 'modified_at', headerName: 'Bearbeitet am', flex: 1 },
        { field: 'modified_by', headerName: 'Bearbeitet durch', flex: 1 },
      ],
      getRow: (feature) => ({
        region: feature.get('name'),
        rollen: feature.get('rollen')
          ? feature
              .get('rollen')
              .map((r) => `${r.bezeichnung}: ${r.person?.label}`)
              .join(', ')
          : '',
        modified_at: toDateString(feature.get('modified_at')),
        modified_by: feature.get('modified_by'),
      }),
    },
  },
];
