import { Style, Stroke, Fill, Circle, Icon, RegularShape } from 'ol/style';
import uiSchemas from '../uiSchemas';
import { toDateString } from '../utils';

const zweitausbildungStationCache = {};

const zweitausbildungStationStyle = (feature) => {
  const selection = feature.get('selection');
  const isBorderStation = feature.get('is_border_station');
  const key = `${selection}_${isBorderStation}`;

  if (!zweitausbildungStationCache[key]) {
    let color;
    if (selection === 'Basis') {
      color = 'rgb(239,43,43)';
    }
    if (selection === 'Aufbau') {
      color = 'rgb(78,126,240)';
    }
    if (color) {
      zweitausbildungStationCache[key] = new Style({
        zIndex: selection ? 10 : 0,
        image: feature.get('is_border_station')
          ? new RegularShape({
              fill: new Fill({ color: 'white' }),
              stroke: new Stroke({ color, width: 4 }),
              points: 4,
              radius: 8,
              angle: Math.PI / 4,
            })
          : new Circle({
              radius: 8,
              fill: new Fill({ color: 'white' }),
              stroke: new Stroke({ color, width: 4 }),
            }),
      });
    }
  }
  return zweitausbildungStationCache[key];
};

const zweitausbildungPoisCache = {};

const zweitausbildungPoisStyle = (feature, isSelected) => {
  const style = [];
  const railAway = feature.get('rail_away');
  const key = `${railAway}_${isSelected}`;
  zweitausbildungPoisCache[key] = zweitausbildungPoisCache[key] || null;

  if (!zweitausbildungPoisCache[key]) {
    const color = railAway ? 'rgba(236, 0, 0, 0.8)' : 'rgba(0, 31, 135, 0.8)';
    style.push(
      new Style({
        image: new Circle({
          radius: isSelected ? 17 : 12,
          fill: new Fill({
            color,
          }),
        }),
      }),
    );
    style.push(
      new Style({
        image: new Icon({
          src: 'https://maps2.trafimage.ch/img/layers/zweitausbildung/poi.png',
        }),
      }),
    );
    zweitausbildungPoisCache[key] = style;
  }
  return zweitausbildungPoisCache[key];
};

const toExport = (baseLayers, noDataStyle) => [
  {
    key: 'zweitausbildung_pois',
    name: 'Zweitausbildung-POIs',
    allowTilePublication: true,
    search: 'default',
    baseLayers,
    uiSchema: uiSchemas.zweitausbildung_pois,
    listConfig: {
      getColumns: () => [
        { field: 'name', headerName: 'Name', flex: 1 },
        { field: 'rail_away', headerName: 'RailAway', flex: 1 },
        { field: 'modified_at', headerName: 'Bearbeitet am', flex: 1 },
        { field: 'modified_by', headerName: 'Bearbeitet durch', flex: 1 },
      ],
      getRow: (feature) => ({
        name: feature.get('name'),
        rail_away: feature.get('rail_away') ? '✓' : '✖',
        modified_at: toDateString(feature.get('modified_at')),
        modified_by: feature.get('modified_by'),
      }),
    },
    getFeatureTitle: (feature) => feature.get('name'),
    styleFunction: zweitausbildungPoisStyle,
    oleConfig: {
      canAddGeometries: true,
      canEditGeometries: true,
    },
  },
  {
    key: 'zweitausbildung_stations',
    name: 'Zweitausbildung-Haltestellen',
    allowTilePublication: true,
    baseLayers,
    uiSchema: uiSchemas.zweitausbildung_stations,
    search: 'stationFinder',
    listConfig: {
      getColumns: () => [
        { field: 'stationsbezeichnung', headerName: 'Station', flex: 1 },
        { field: 'is_border_station', headerName: 'Grenzstation', flex: 1 },
        { field: 'selection', headerName: 'Auswahl', flex: 1 },
        { field: 'modified_at', headerName: 'Bearbeitet am', flex: 1 },
        { field: 'modified_by', headerName: 'Bearbeitet durch', flex: 1 },
      ],
      getRow: (feature) => ({
        stationsbezeichnung: feature.get('stationsbezeichnung'),
        is_border_station: feature.get('is_border_station') ? '✓' : '✖',
        selection: feature.get('selection'),
        modified_at: toDateString(feature.get('modified_at')),
        modified_by: feature.get('modified_by'),
      }),
    },
    filterConfig: [
      {
        type: 'boolean',
        field: 'is_border_station',
        label: 'Grenzstation',
      },
      {
        type: 'select',
        field: 'selection',
        label: 'Selektion',
        choices: {
          '': 'Keine',
          Basis: 'Basis',
          Aufbau: 'Aufbau',
        },
      },
    ],
    getFeatureTitle: (feature) =>
      feature.get('name') || feature.get('stationsbezeichnung'),
    styleFunction: (feature, isSelected) => {
      const style = zweitausbildungStationStyle(feature) || noDataStyle;
      if (isSelected) {
        const hlStyle = new Style({
          zIndex: 20,
          image: new Circle({
            radius: 15,
            fill: new Fill({
              color: 'rgba(0, 31, 135, 0.5)',
            }),
          }),
        });
        return [hlStyle, style];
      }
      return style;
    },
  },
];

export default toExport;
