import Feature from 'ol/Feature';
import WKTFormat from 'ol/format/WKT';
import set from 'lodash.set';
import get from 'lodash.get';
// eslint-disable-next-line no-unused-vars
import merge from 'deepmerge';
import getCookie from '../../utils/getCookie';
import { transformToExtraErrors } from '../../utils';

export const SET_SCHEMA = 'SET_SCHEMA';
export const SET_UI_SCHEMA = 'SET_UI_SCHEMA';
export const SET_FEATURES = 'SET_FEATURES';
export const SET_ROUTING_FEATURES = 'SET_ROUTING_FEATURES';
export const IS_FEATURES_LOADING = 'IS_FEATURES_LOADING';
export const UPDATE_FEATURE = 'UPDATE_FEATURE';
export const UPDATE_FEATURES = 'UPDATE_FEATURES';
export const DELETE_FEATURE = 'DELETE_FEATURE';
export const ADD_FEATURE = 'ADD_FEATURE';
export const SET_ACTIVE_BUTTON = 'SET_ACTIVE_BUTTON';
export const SET_SELECTED_FEATURE = 'SET_SELECTED_FEATURE';
export const SET_SELECTABLE_FEATURES = 'SET_SELECTABLE_FEATURES';
export const SET_LIST_VISIBLE = 'SET_LIST_VISIBLE';
export const SET_FILTERS = 'SET_FILTERS';
export const SET_ERROR = 'SET_ERROR';
export const SET_TOPIC = 'SET_TOPIC';
export const SET_TOPICS = 'SET_TOPICS';
export const SET_DIALOG_VISIBLE = 'SET_DIALOG_VISIBLE';
export const UPLOAD_FILE = 'UPLOAD_FILE';
export const SET_NOTIFICATION = 'SET_NOTIFICATION';
export const SET_CURRENT_PUBLICATION = 'SET_CURRENT_PUBLICATION';
export const SET_API_KEY = 'SET_API_KEY';
export const SET_API_ENDPOINTS = 'SET_API_ENDPOINTS';
export const SET_OLE = 'SET_OLE';
export const SET_BULK_FEATURES = 'SET_BULK_FEATURES';
export const SET_BACKEND_API_URL = 'SET_BACKEND_API_URL';
export const SET_SIDEBAR_OPEN = 'SET_SIDEBAR_OPEN';
export const SET_IS_PREVIEW_ACTIVE = 'SET_IS_PREVIEW_ACTIVE';
export const SET_PREVIEW_ENABLE_VALUE = 'SET_PREVIEW_ENABLE_VALUE';
export const SET_IS_PREVIEW_LOADING = 'SET_IS_PREVIEW_LOADING';
export const SET_IS_FORM_UNSAVED = 'SET_IS_FORM_UNSAVED';
export const SET_LOGIN_STATUS = 'SET_LOGIN_STATUS';
export const SET_IS_CANCEL_DIALOG_VISIBLE = 'SET_IS_CANCEL_DIALOG_VISIBLE';
export const SET_CANCEL_DIALOG_ON_ACTION = 'SET_CANCEL_DIALOG_ON_ACTION';
export const SET_FORM_DATA = 'SET_FORM_DATA';
export const SET_HISTORY = 'SET_HISTORY';
export const SET_IGNORE_FIELDS_ON_SAVE = 'SET_IGNORE_FIELDS_ON_SAVE';

let abortCtrlFeatures = new AbortController();
let abortCtrlSchema = new AbortController();
const wktFormat = new WKTFormat();

export const fetchJsonData = (url, method = 'GET', options) => {
  const opts = {
    method,
    credentials: 'include',
    headers: {
      'Content-Type': 'application/json',
    },
    ...options,
  };
  const csrftoken = getCookie('csrftoken');
  if (csrftoken) {
    opts.headers = {
      ...opts.headers,
      'X-CSRFToken': csrftoken,
    };
  }
  return fetch(url, opts).then((res) => {
    if (!res.ok) {
      return res.json().then((data) => {
        // if the error is a json error return the json object.
        throw data;
      });
    }

    // If the server respond a good http response but without json content,
    // we return an empty object
    return res.json().catch(() => {
      return Promise.resolve({});
    });
  });
};

export const fetchApiEndpoints = (topic) => (dispatch, getState) => {
  const { backendApiUrl } = getState().app;
  return fetch(`${backendApiUrl}/${topic.key}/`, {
    method: 'GET',
    credentials: 'include',
    headers: {
      'X-CSRFToken': getCookie('csrftoken'),
    },
  })
    .then((res) => res.json())
    .then((data) => {
      dispatch({ type: SET_API_ENDPOINTS, data });
    })
    .catch((error) => {
      dispatch({
        type: SET_ERROR,
        data: { ...error, type: SET_API_ENDPOINTS },
      });
    });
};

export const fetchSchema = (topic) => (dispatch, getState) => {
  const { backendApiUrl } = getState().app;
  const url = `${backendApiUrl}/${topic.key}/form/schema/`;
  abortCtrlSchema.abort();
  abortCtrlSchema = new AbortController();
  const { signal } = abortCtrlSchema;
  return fetchJsonData(url, 'GET', { signal })
    .then((data) => {
      dispatch({
        type: SET_SCHEMA,
        data: merge(data, topic.schemaOverrides || {}),
      });
    })
    .catch((error) => {
      dispatch({
        type: SET_ERROR,
        data: { ...error, type: SET_SCHEMA },
      });
    });
};

export const setTopic = (data) => ({ type: SET_TOPIC, data });
export const setTopics = (data) => ({ type: SET_TOPICS, data });
export const setListVisible = (data) => ({ type: SET_LIST_VISIBLE, data });
export const setSchema = (data) => ({ type: SET_SCHEMA, data });
export const setUiSchema = (data) => ({ type: SET_UI_SCHEMA, data });
export const setFilters = (data) => ({ type: SET_FILTERS, data });
export const setOle = (data) => ({ type: SET_OLE, data });
export const setApiKey = (data) => ({ type: SET_API_KEY, data });
export const setFeatures = (data) => ({ type: SET_FEATURES, data });
export const setRoutingFeatures = (data) => ({
  type: SET_ROUTING_FEATURES,
  data,
});

export const setDialogVisible = (visible, data) => ({
  type: SET_DIALOG_VISIBLE,
  data: { dialogVisible: visible, dialogData: data },
});

export const setNotification = (data) => ({
  type: SET_NOTIFICATION,
  data,
});

export const setCurrentPublication = (data) => ({
  type: SET_CURRENT_PUBLICATION,
  data,
});

export const setActiveButton = (data) => ({
  type: SET_ACTIVE_BUTTON,
  data,
});

export const cleanError = () => ({
  type: SET_ERROR,
  data: null,
});

export const setSelectableFeatures = (data) => ({
  type: SET_SELECTABLE_FEATURES,
  data,
});

export const setSelectedFeature = (data) => ({
  type: SET_SELECTED_FEATURE,
  data,
});

export const setBulkFeatures = (data) => ({
  type: SET_BULK_FEATURES,
  data,
});
export const setBackendApiUrl = (data) => ({
  type: SET_BACKEND_API_URL,
  data,
});
export const setSidebarOpen = (data) => ({
  type: SET_SIDEBAR_OPEN,
  data,
});
export const setIsPreviewActive = (data) => ({
  type: SET_IS_PREVIEW_ACTIVE,
  data,
});
export const setPreviewEnableValue = (data) => ({
  type: SET_PREVIEW_ENABLE_VALUE,
  data,
});
export const setIsPreviewLoading = (data) => ({
  type: SET_IS_PREVIEW_LOADING,
  data,
});
export const setLoginStatus = (data) => ({
  type: SET_LOGIN_STATUS,
  data,
});
export const setIsFormUnsaved = (data) => ({
  type: SET_IS_FORM_UNSAVED,
  data,
});
export const setIsCancelDialogVisible = (data) => ({
  type: SET_IS_CANCEL_DIALOG_VISIBLE,
  data,
});
export const setCancelDialogOnAction = (data) => ({
  type: SET_CANCEL_DIALOG_ON_ACTION,
  data,
});
export const setFormData = (data) => ({
  type: SET_FORM_DATA,
  data,
});
export const setHistory = (data) => ({
  type: SET_HISTORY,
  data,
});
export const setIgnoreFieldsOnSave = (data) => ({
  type: SET_IGNORE_FIELDS_ON_SAVE,
  data,
});

export const subscribeToPublication = (currentPublication) => (
  dispatch,
  getState,
) => {
  const { backendApiUrl } = getState().app;
  // Make request to know when the publication finished.
  const requestInterval = setInterval(() => {
    fetchJsonData(
      `${backendApiUrl}/-/gitlab/?id=${currentPublication[1]}`,
      'GET',
    ).then((data) => {
      if (
        data &&
        data.status &&
        ['FAILED', 'SKIPPED', 'CANCELED'].includes(data.status[0])
      ) {
        dispatch(setCurrentPublication());
        dispatch(
          setNotification({
            type: 'error',
            message: data.status[0],
            onCloseButtonClick: () => dispatch(setNotification()),
            hideDuration: null,
            closeEvents: ['clickaway'],
          }),
        );
        dispatch(setIsPreviewLoading(false));
        clearInterval(requestInterval);
      }
      if (data && data.status && data.status[0] === 'SUCCESS') {
        dispatch(setCurrentPublication());
        dispatch(
          setNotification({
            type: 'success',
            message: 'Publikation erfolgreich',
            onCloseButtonClick: () => dispatch(setNotification()),
            hideDuration: null,
            closeEvents: ['clickaway'],
          }),
        );
        dispatch(setIsPreviewLoading(false));
        dispatch(setPreviewEnableValue('networkReady'));
        clearInterval(requestInterval);
      }
    });
  }, 2000);
};

const getFeature = (geom, props) => {
  let geometry;
  if (geom) {
    geometry = wktFormat.readGeometry(geom.split(';')[1]);
  }
  return new Feature({ ...props, geometry });
};

const getFeaturesGen = (item, props) =>
  [
    getFeature(item.geom, { graph: 'osm', ...props }),
    getFeature(item.geom_gen5, { graph: 'gen5', ...props }),
    getFeature(item.geom_gen10, { graph: 'gen10', ...props }),
    getFeature(item.geom_gen30, { graph: 'gen30', ...props }),
    getFeature(item.geom_gen100, { graph: 'gen100', ...props }),
  ].filter((f) => f.getGeometry());

const setNewFeatures = (dispatch, data, selectedFeature) => {
  const features = [];
  const routingFeatures = [];
  for (let i = 0, len = data.length; i < len; i += 1) {
    const { geom, route, ...props } = data[i];
    if (route) {
      const routeProps = { ...props, route };
      routingFeatures.push(
        ...getFeaturesGen(route, routeProps),
        getFeature(route.start.geom, routeProps),
        getFeature(route.end.geom, routeProps),
        ...route.vias
          .map((v) => getFeature(v.geom, { ...v, ...routeProps }))
          .flat(),
      );
    } else {
      features.push(getFeature(geom, props));
    }
  }

  /* Remove selected feature in case it is filtered out, reselect it
   * if features are reloaded (e.g. filter applied) but it is not filtered out
   */
  // Always use the id to find the feature in the list,
  // there could mulitple features with the same name
  const id = selectedFeature && selectedFeature.get('id');
  const findFunc = id
    ? (f) => f.get('id') === selectedFeature.get('id')
    : (f) => f.get('name') === selectedFeature.get('name');
  const featureToSelect =
    selectedFeature && [...features, ...routingFeatures].find(findFunc);

  dispatch({ type: SET_FEATURES, data: features });
  dispatch({ type: SET_ROUTING_FEATURES, data: routingFeatures });
  dispatch({ type: IS_FEATURES_LOADING, data: false });
  dispatch({ type: SET_SELECTED_FEATURE, data: featureToSelect });
};

export const getFeatures = (topic, filters, selectedFeature, search) => (
  dispatch,
  getState,
) => {
  const { backendApiUrl } = getState().app;
  let url = `${backendApiUrl}/${topic.key}/form/?`;
  abortCtrlFeatures.abort();
  abortCtrlFeatures = new AbortController();
  const { signal } = abortCtrlFeatures;
  dispatch({ type: IS_FEATURES_LOADING, data: true });
  if (filters) {
    Object.entries(filters).forEach(([filter, value]) => {
      if (value instanceof Array) {
        value.forEach((val) => {
          url +=
            val !== null && val !== undefined
              ? `&${filter}=${encodeURIComponent(val)}`
              : '';
        });
      } else {
        url += value ? `&${filter}=${encodeURIComponent(value)}` : '';
      }
    });
  }

  if (search) {
    // No implemented in backend
    // url += `&search=${encodeURIComponent(search)}`;
  }

  const { initialFeatures } = topic;
  if (initialFeatures) {
    setNewFeatures(dispatch, initialFeatures, selectedFeature);
    return null;
  }
  return fetchJsonData(url, 'GET', { signal })
    .then((data) => {
      setNewFeatures(dispatch, data, selectedFeature);
    })
    .catch((error) => {
      dispatch({ type: SET_FEATURES, data: [] });
      dispatch({ type: IS_FEATURES_LOADING, data: signal?.aborted });
      dispatch({
        type: SET_ERROR,
        data: { ...error, type: SET_FEATURES },
      });
    });
};

export const uploadFile = (file) => (dispatch, getState) => {
  const { topic, backendApiUrl } = getState().app;
  if (!backendApiUrl) {
    return Promise.resolve({});
  }
  const url = `${backendApiUrl}/${topic.key}/files/`;
  const formData = new FormData();
  formData.append('file', file);
  return fetch(url, {
    method: 'POST',
    body: formData,
    credentials: 'include',
    headers: {
      'X-CSRFToken': getCookie('csrftoken'),
    },
  })
    .then((res) => res.json())
    .then((data) => {
      if (data.success) {
        return dispatch({ type: UPLOAD_FILE, data });
      }
      return dispatch({
        type: SET_ERROR,
        data: { ...data, type: UPLOAD_FILE },
      });
    })
    .catch((error) => {
      // That means we have an response from the server.
      if (error.ok === false) {
        return error.json().then((content) =>
          dispatch({
            type: SET_ERROR,
            data: { reason: content.file.toString(), type: UPLOAD_FILE },
          }),
        );
      }
      return dispatch({
        type: SET_ERROR,
        data: { ...error, type: UPLOAD_FILE },
      });
    });
};

export const deleteFeature = (feature) => (dispatch, getState) => {
  const { topic, backendApiUrl } = getState().app;
  const id = feature.get('id');
  const url = `${backendApiUrl}/${topic.key}/form/${id}/`;

  if (!id) {
    // This is a new feature so we just close the form without the cancel dialog.
    dispatch(setSelectedFeature());
    return Promise.resolve();
  }

  return fetchJsonData(url, 'DELETE')
    .then((data) => {
      if (data && Object.keys(data).length) {
        // In some topics we never really delete a feature (immobilien),
        // but we set the default value instead.
        // If delete request returns default feature value,
        // we replace the current feature with default values.
        dispatch({ type: UPDATE_FEATURE, data });
      } else {
        dispatch({ type: DELETE_FEATURE, data: id });
      }
      // We unselect the selectableFeature
      dispatch({ type: SET_SELECTED_FEATURE, data: null });
    })
    .catch((error) =>
      dispatch({
        type: SET_ERROR,
        data: { ...error, type: DELETE_FEATURE },
      }),
    );
};

// Get fields in uiSchema that use ui:hackEmptyValue.
const getHackEmptyValuesFields = (obj, path = []) => {
  const fields = [];
  if (typeof obj !== 'object') {
    return [];
  }
  Object.entries(obj).forEach(([key, props]) => {
    if (props['ui:hackEmptyValue'] !== undefined) {
      fields.push([[...path, key], props]);
    } else {
      fields.push(...getHackEmptyValuesFields(props, [key]));
    }
  });
  return fields;
};

// We export it for easy testing.
// This function replace empty values (undefined for a string or an empty object for an object) of field that uses ui:hackEmptyValue in their uiSchema definitions.
// ui:emptyValue doesn't work for select field, see https://github.com/rjsf-team/react-jsonschema-form/issues/1041
export const applyHackEmptyValues = (formData, uiSchema) => {
  getHackEmptyValuesFields(uiSchema).forEach(([path, props]) => {
    const value = get(formData, path);
    if (value === undefined || JSON.stringify(value) === '{}') {
      set(formData, path, props['ui:hackEmptyValue']);
    }
  });
  return formData;
};

export const bulkSaveFormData = (url, formData, bulkFeatures) => (
  dispatch,
  getState,
) => {
  const { uiSchema, ignoreFieldsOnSave } = getState().app;
  const payload = applyHackEmptyValues({ ...formData }, uiSchema);
  ignoreFieldsOnSave.forEach((field) => {
    delete payload[field];
  });
  const body = bulkFeatures.map((f) => ({ ...payload, id: f.get('id') }));
  const bulkUrl = `${url}bulk_update/`;

  return fetchJsonData(bulkUrl, 'POST', { body: JSON.stringify(body) })
    .then((data) => {
      return dispatch({ type: UPDATE_FEATURES, data });
    })
    .catch((error) =>
      dispatch({
        type: SET_ERROR,
        data: { formErrors: error, UPDATE_FEATURES },
        selectedFeature: null,
      }),
    );
};
export const saveFormData = (url, formData) => (dispatch, getState) => {
  const { uiSchema, ignoreFieldsOnSave } = getState().app;
  const body = applyHackEmptyValues({ ...formData }, uiSchema);
  ignoreFieldsOnSave.forEach((field) => {
    delete body[field];
  });
  const backendUrl = body.id ? `${url}${body.id}/` : `${url}`;
  const method = body.id ? 'PATCH' : 'POST';
  const type = body.id ? UPDATE_FEATURE : ADD_FEATURE;
  return fetchJsonData(backendUrl, method, { body: JSON.stringify(body) })
    .then((data) => {
      return dispatch({ type, data: { ...data, id: data.id || body.id } });
    })
    .catch((error) =>
      dispatch({
        type: SET_ERROR,
        data: { extraErrors: transformToExtraErrors(error || {}), type },
      }),
    );
};
