import React, { useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Alert from '@mui/material/Alert';
import Snackbar from '@mui/material/Snackbar';
import { setNotification } from '../../model/app/actions';

function Notification() {
  const dispatch = useDispatch();
  const { notification } = useSelector((state) => state.app);

  const handleClose = useCallback(
    (evt, reason, closeEvents = ['timeout']) => {
      if (closeEvents.includes(reason)) {
        dispatch(setNotification(null));
      }
    },
    [dispatch],
  );

  if (!notification) return null;

  return (
    <Snackbar
      open={!!notification}
      autoHideDuration={
        notification?.hideDuration === null
          ? null
          : notification?.hideDuration || 8000
      }
      onClose={(evt, reason) =>
        handleClose(evt, reason, notification.closeEvents)
      }
      anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
    >
      <Alert
        severity={notification.type}
        elevation={6}
        variant="filled"
        onClose={
          typeof notification?.onCloseButtonClick === 'function'
            ? (evt) => notification.onCloseButtonClick(evt)
            : undefined
        }
      >
        {notification.message}
      </Alert>
    </Snackbar>
  );
}
export default Notification;
