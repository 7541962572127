/* eslint-disable react/jsx-props-no-spreading */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useDropzone } from 'react-dropzone';
import DeleteIcon from '@mui/icons-material/Delete';
import { InputLabel, IconButton, CircularProgress } from '@mui/material';
import { makeStyles } from '@mui/styles';

const propTypes = {
  label: PropTypes.string,
  accept: PropTypes.string,
  // isWidget configure the behavior to be used in FormWrapper
  isWidget: PropTypes.bool,
  multiple: PropTypes.bool,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.shape(),
    PropTypes.arrayOf(
      PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    ),
  ]),
  onChange: PropTypes.func,
  required: PropTypes.bool,
  disabled: PropTypes.bool,
  uploading: PropTypes.bool,
  file: PropTypes.shape(),
  statusMsg: PropTypes.shape(),
};

const defaultProps = {
  label: null,
  accept: null,
  isWidget: true,
  multiple: false,
  value: null,
  required: false,
  disabled: false,
  uploading: false,
  file: null,
  statusMsg: null,
  onChange: () => {},
};

const useStyles = makeStyles({
  dropZone: {
    cursor: 'pointer',
    border: '1px dashed #e3e3e3',
    padding: 20,
    marginTop: 20,
    marginBottom: 10,
    fontStyle: 'italic',
  },
  uploadLoading: {
    display: 'flex',
    alignItems: 'center',
    minWidth: '350px',

    '& span': {
      marginLeft: '10px',
    },
  },
  imageTitle: {
    paddingBottom: '10px',
  },
  imagePreviewWrapper: {
    overflow: 'hidden',
    whiteSpace: 'nowrap',
  },
  imagePreview: {
    maxWidth: '100%',
    maxHeight: '100%',
  },
  fileInfo: {
    display: 'flex',
    alignItems: 'center',
    height: '40px',
  },
  fileName: {
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    maxWidth: '390px',
  },
  fileButton: {
    height: '0.8em',
    width: '0.8em',
  },
  errors: {
    color: 'red',
  },
});

const MAX_SIZE = 5; // in megabytes
function InputFile({
  label,
  value,
  isWidget,
  required,
  disabled,
  accept,
  multiple,
  onChange,
  uploading,
  statusMsg,
  file,
}) {
  const classes = useStyles();
  const { t } = useTranslation();
  const [files, setFiles] = useState(null);
  const [errors, setErrors] = useState([]);
  const [imagePreviewUrls, setImagePreviewUrls] = useState([]);

  const dataUrlRegex = new RegExp(
    /^data:([a-z]+\/[a-z0-9-+.]+)?;(?:name=(.*);)?base64,(.*)$/,
  );
  const isImage = (fileName) => /(png|jpe?g|gif)$/i.test(fileName);
  const isImageDataUrl = (val) => dataUrlRegex.test(val);

  const getBase64Extension = (val) =>
    val.substring('data:image/'.length, val.indexOf(';base64'));

  const asyncToDataUrl = (fileVal) => {
    return new Promise((resolve) => {
      const reader = new FileReader();
      reader.readAsDataURL(fileVal);
      reader.onload = () => {
        resolve(reader.result);
      };
    });
  };

  const urltoFile = (url, filename) => {
    return fetch(url)
      .then((res) => res.arrayBuffer())
      .then((buf) => new File([buf], filename));
  };

  const updateImagePreviewUrl = (values) => {
    if (!values) {
      return;
    }
    const previewUrls = values.map((val) => {
      if (val instanceof File) {
        return URL.createObjectURL(val);
      }
      if (typeof val === 'string') {
        return val;
      }
      return '';
    });
    setImagePreviewUrls(previewUrls);
  };

  const updateValue = (val) => {
    if (val && isImage(val)) {
      const filenames = val.split('/media/');
      const fileBlob = new Blob();
      fileBlob.lastModifiedDate = new Date();
      fileBlob.name = filenames.length
        ? filenames[1]
        : `preview.${val.split(/\.(?=[^.]+$)/)[1]}`;

      setFiles([fileBlob]);
      updateImagePreviewUrl(Array.isArray(val) ? val : [val]);
    } else if (isImageDataUrl(val)) {
      urltoFile(val, `preview.${getBase64Extension(val)}`).then((fileValue) => {
        setFiles([fileValue]);
        updateImagePreviewUrl(Array.isArray(val) ? val : [val]);
      });
    } else {
      setFiles(null);
      setImagePreviewUrls([]);
    }
  };

  const onDrop = (acceptedFiles, fileRejections) => {
    if (acceptedFiles) {
      if (acceptedFiles.map((f) => isImage(f.path)).every((v) => v === true)) {
        const promises = [];
        for (let i = 0; i < acceptedFiles.length; i += 1) {
          promises.push(asyncToDataUrl(acceptedFiles[i]));
        }

        Promise.all(promises).then((results) => {
          setFiles(acceptedFiles);
          onChange(multiple ? results : results[0]);
          updateImagePreviewUrl(acceptedFiles);
        });
      } else {
        setFiles(acceptedFiles);
        onChange(acceptedFiles);
      }

      if (fileRejections) {
        const errorMessages = [];
        fileRejections.forEach((err) => {
          if (err.errors[0] && err.errors[0].code === 'file-too-large') {
            errorMessages.push({
              name: err.file.name,
              message: t(`Die Datei ist grösser als ${MAX_SIZE}MB.`),
            });
          }
        });
        setErrors(errorMessages);
      }
    }
  };

  const { getRootProps, getInputProps, acceptedFiles, inputRef } = useDropzone({
    onDrop,
    // bytes are used in decimal in react-dropzone: 1 Byte = 0.000001 MB
    maxSize: isWidget ? MAX_SIZE * 10 ** 6 : undefined, // in bytes
  });

  const remove = () => {
    acceptedFiles.length = 0;
    acceptedFiles.splice(0, acceptedFiles.length);
    inputRef.current.value = '';
    updateValue();
    onChange(null);
  };

  const renderFiles = () => {
    if (files && files.length) {
      return (
        <div>
          <div className={classes.imageTitle}>Gewählte Datei</div>
          {files.map((f, idx) => (
            <div key={f.path || f.name}>
              {isImage(f.name) ? (
                <div className={classes.imagePreviewWrapper}>
                  <img
                    src={imagePreviewUrls[idx]}
                    className={classes.imagePreview}
                    alt="Preview"
                  />
                  <div className={classes.fileInfo}>
                    <div className={classes.fileName}>{f.name}</div>
                    <div>
                      <IconButton title="Delete" onClick={remove}>
                        <DeleteIcon className={classes.fileButton} />
                      </IconButton>
                    </div>
                  </div>
                </div>
              ) : (
                `${f.path} - ${f.size} bytes`
              )}
            </div>
          ))}
        </div>
      );
    }
    return null;
  };

  useEffect(() => {
    updateValue(value);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  return (
    <div>
      <div {...getRootProps()}>
        {label && <InputLabel shrink>{label}</InputLabel>}
        <input
          accept={accept}
          required={required}
          disabled={disabled}
          multiple={multiple}
          data-testid="cartaro-input-file"
          // we can't set value programatically,
          // if this component is used as widget in FormWrapper.
          {...(isWidget ? getInputProps() : getInputProps({ value }))}
        />
        {uploading ? (
          <div className={classes.dropZone}>
            <div className={classes.uploadLoading}>
              <CircularProgress size={20} />
              <span>
                {file.name} {t('wird hochgeladen...')}
              </span>
            </div>
          </div>
        ) : (
          <div className={classes.dropZone}>
            {t(`Klicken Sie hier, um die Datei zu öffnen oder ziehen Sie
            die Datei per Drag & Drop auf diese Schaltfläche.`)}
          </div>
        )}
      </div>

      {!uploading && !statusMsg && renderFiles()}

      {errors ? (
        <div className={classes.errors}>
          {errors.map((err, idx) => (
            // eslint-disable-next-line react/no-array-index-key
            <span key={idx}>{`${err.name} - ${err.message}`}</span>
          ))}
        </div>
      ) : null}
    </div>
  );
}

InputFile.propTypes = propTypes;
InputFile.defaultProps = defaultProps;

export default InputFile;
