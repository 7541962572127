/* eslint-disable react/jsx-props-no-spreading */

import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { Typography } from '@mui/material';

/**
 * Hardcoded from https://github.com/rjsf-team/react-jsonschema-form/blob/dff7b1f889381bfa179b08436c83867cc4a4471d/packages/core/src/components/fields/SchemaField.tsx#L28
 */
const COMPONENT_TYPES = {
  array: 'ArrayField',
  boolean: 'BooleanField',
  integer: 'NumberField',
  number: 'NumberField',
  object: 'ObjectField',
  string: 'StringField',
  null: 'NullField',
};

const FormSectionTitledField = (props) => {
  const messtischFields = useSelector((state) => state.app.fields);
  const { uiSchema, registry, schema } = props;
  const { fields } = registry;
  const customFieldName = uiSchema['ui:options']?.['ui:customfield'];
  const componentName = COMPONENT_TYPES[schema.type];
  const sectionTitle = uiSchema['ui:options']?.['ui:title'];
  const FieldComponent = messtischFields[customFieldName]
    ? messtischFields[customFieldName]
    : fields[componentName];

  return (
    <>
      {sectionTitle && (
        <Typography
          variant="h5"
          style={{
            color: 'rgba(0, 0, 0, 0.6)',
            fontWeight: 'bold',
            marginTop: 30,
          }}
        >
          {sectionTitle}
        </Typography>
      )}
      <FieldComponent {...props} uiSchema={{}} />
    </>
  );
};

const propTypes = {
  onChange: PropTypes.func.isRequired,
  registry: PropTypes.shape().isRequired,
  schema: PropTypes.shape().isRequired,
  required: PropTypes.bool,
  uiSchema: PropTypes.shape(),
};

const defaultProps = {
  required: false,
  uiSchema: {},
};

FormSectionTitledField.propTypes = propTypes;
FormSectionTitledField.defaultProps = defaultProps;

export default FormSectionTitledField;
