/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { styled } from '@mui/styles';
import { Switch } from '@mui/material';

const StyledSwitch = styled((props) => (
  <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
  '&.MuiSwitch-root': {
    width: 42,
    height: 42,
    padding: 0,
    marginRight: 10,
    overflow: 'visible',
    alignItems: 'center',
  },
  '& .MuiSwitch-switchBase': {
    top: 6,
    padding: '4px 3px 4px 3px',
    color: 'white',
    '&.Mui-checked': {
      transform: 'translateX(14px)',
      color: 'white',
      '& + .MuiSwitch-track': {
        backgroundColor: theme.palette.secondary.main,
        opacity: 1,
      },
      '&.Mui-disabled + .MuiSwitch-track': {
        opacity: 0.5,
      },
    },
    '&.Mui-focusVisible .MuiSwitch-thumb': {
      color: '#33cf4d',
      border: '6px solid #fff',
    },
    '&.MuiSwitch-thumb': {
      width: 20,
      height: 20,
      boxShadow:
        '0 1px 1px 0 rgb(0 0 0 / 7%), 0 0 1px 1px rgb(0 0 0 / 11%), 0 4px 2px 0 rgb(0 0 0 / 10%), 0 4px 9px 2px rgb(0 0 0 / 8%)',
    },
  },
  '& .MuiSwitch-thumb': {
    boxSizing: 'border-box',
    width: 22,
    height: 22,
  },
  '& .MuiSwitch-track': {
    height: 24,
    borderRadius: 24,
    border: `1px solid #e5e5e5`,
    opacity: 1,
    backgroundColor: theme.palette.grey[500],
  },
}));

function SBBSwitch(props) {
  return <StyledSwitch {...props} />;
}

export default SBBSwitch;
