/* eslint-disable no-underscore-dangle */
import React from 'react';
import { List, ListItem, FormHelperText } from '@mui/material';
import { makeStyles } from '@mui/styles';
import PropTypes from 'prop-types';

const useStyles = makeStyles({
  dense: {
    '& > .MuiListItem-dense': {
      paddingTop: 0,
      paddingBottom: 0,
    },
    '& > .MuiListItem-dense:first-child': {
      paddingTop: 4,
    },
    '& > .MuiListItem-dense:last-child': {
      paddingBottom: 4,
    },
  },
});

/**
 * This component display a list of text errors to display as helper text of a input.
 * Errors must be defined like in the errorSchema object and looks like this
 * { __errors: ['error1', 'error2'] }
 */
const Errors = ({ errors }) => {
  const classes = useStyles();
  if (!errors || !errors.__errors || !errors.__errors.length) {
    return null;
  }

  // eslint-disable-next-line react/prop-types
  const uniqueErrors = [...new Set(errors.__errors)];

  return (
    <List dense disablePadding classes={classes}>
      {uniqueErrors.map((error) => {
        return (
          <ListItem disableGutters key={error}>
            <FormHelperText required error>
              {error}
            </FormHelperText>
          </ListItem>
        );
      })}
    </List>
  );
};

Errors.propTypes = {
  errors: PropTypes.shape({ __errors: PropTypes.arrayOf(PropTypes.string) }),
};

Errors.defaultProps = {
  errors: null,
};

export default React.memo(Errors);
