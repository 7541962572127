import { Style, Icon, Circle, Fill, Stroke } from 'ol/style';
import { MapboxLayer, MapboxStyleLayer } from 'mobility-toolbox-js/ol';

import AusbauBahnhof from './images/construction/Ausbau_Bahnhof.svg';
import AusbauBahnhofundStrecke from './images/construction/Ausbau_BahnhofundStrecke.svg';
import AusbauBrigeStandorte from './images/construction/Ausbau_brigeStandorte.svg';
import AusbauStrecke from './images/construction/Ausbau_Strecke.svg';

import UnterhaltBahnhof from './images/construction/Unterhalt_Bahnhof.svg';
import UnterhaltBahnhofundStrecke from './images/construction/Unterhalt_BahnhofundStrecke.svg';
import UnterhaltBrigeStandorte from './images/construction/Unterhalt_brigeStandorte.svg';
import UnterhaltStrecke from './images/construction/Unterhalt_Strecke.svg';

import mietveloIcon from './images/mietvelo/bike_markerx100.svg';
import mietveloSelectedIcon from './images/mietvelo/bike_marker_selectedx100.svg';

import uiSchemas from './uiSchemas';
import getEnergieTopicConf from './topics/energie';
import getAnlageVerantwortlicheTopicConf from './topics/anlagenverantwortliche';
import getNetzentwicklungTopicConf from './topics/netzentwicklung';
import getZweitausbildungTopicConf from './topics/zweitausbildung';
import getImmobilienTopicConf from './topics/immobilien';
import getFunkmesswagenTopicConf from './topics/funkmesswagen';
import getRailplusBetreiberinnenTopicConf from './topics/railplusBetreiberinnen';

import { toDateString } from './utils';

const constructionIcons = {
  ausbau_bahnhof: AusbauBahnhof,
  ausbau_bahnhof_und_strecke: AusbauBahnhofundStrecke,
  ausbau_uebrige: AusbauBrigeStandorte,
  ausbau_strecke: AusbauStrecke,
  unterhalt_bahnhof: UnterhaltBahnhof,
  unterhalt_bahnhof_und_strecke: UnterhaltBahnhofundStrecke,
  unterhalt_uebrige: UnterhaltBrigeStandorte,
  unterhalt_strecke: UnterhaltStrecke,
};

const {
  REACT_APP_ENVIRONMENT,
  REACT_APP_VECTOR_TILES_KEY,
  REACT_APP_VECTOR_TILES_URL,
} = process.env;
const vectorTilesUrl = REACT_APP_VECTOR_TILES_URL || '//maps.geops.io';

const netzkarteLayer = new MapboxLayer({
  name: 'ch.sbb.netzkarte',
  copyright: 'OpenStreetMap contributors, © SBB/CFF/FFS',
  visible: true,
  isBaseLayer: true,
  radioGroup: 'baseLayer',
  zIndex: -1, // Add zIndex as the MapboxLayer would block tiled layers
  url:
    `${vectorTilesUrl}/styles/base_bright_v2_ch.sbb.netzkarte/style.json` +
    `?key=${REACT_APP_VECTOR_TILES_KEY}`,
});

const noDataStyle = new Style({
  zIndex: 0,
  image: new Circle({
    radius: 5,
    fill: new Fill({
      color: 'rgba(200, 200, 230, 0.5)',
    }),
    stroke: new Stroke({
      color: 'rgb(20, 58, 133)',
    }),
  }),
});

const mietveloStyle = new Style({
  image: new Icon({
    src: mietveloIcon,
    scale: 0.5,
    offsetOrigin: 'bottom-left',
    offset: [0, -85],
    size: [60, 175],
  }),
});

const mietveloSelectedStyle = new Style({
  image: new Icon({
    src: mietveloSelectedIcon,
    scale: 0.45,
    offsetOrigin: 'bottom-left',
    offset: [0, -103],
    size: [80, 230],
  }),
});

const bauprojekteStyle = (feature) => {
  const { ort, art, angebotsschritt } = feature.getProperties();
  const style = [];

  if (angebotsschritt) {
    style.push(
      new Style({
        image: new Circle({
          radius: 15,
          fill:
            angebotsschritt === '2030'
              ? new Fill({ color: 'rgba(44, 130, 201, 0.6)' })
              : new Fill({ color: 'rgba(250, 190, 88, 0.6)' }),
        }),
      }),
    );
  }
  if (ort && art) {
    const filename = `${art}_${ort}`.replace(/[^A-Z,^0-9,-_]/gi, '');

    if (constructionIcons[filename]) {
      style.push(
        new Style({
          image: new Icon({
            scale: 0.7,
            src: constructionIcons[filename],
          }),
        }),
      );
    }
  }

  return style.length ? style : [noDataStyle];
};

const dvPreviewLayer = new MapboxLayer({
  name: 'ch.sbb.direktverbindungen-preview-data',
  key: 'ch.sbb.direktverbindungen-preview',
  copyright: 'OpenStreetMap contributors, © SBB/CFF/FFS',
  visible: true,
  isBaseLayer: true,
  radioGroup: 'baseLayer',
  zIndex: -1, // Add zIndex as the MapboxLayer would block tiled layers
  url:
    `${vectorTilesUrl}/styles/base_bright_v2_direktverbindungen_preview/style.json` +
    `?key=${REACT_APP_VECTOR_TILES_KEY}`,
});

dvPreviewLayer.children = [
  new MapboxStyleLayer({
    key: 'ch.sbb.direktverbindungen-preview-lines',
    visible: true,
    mapboxLayer: dvPreviewLayer,
    styleLayersFilter: ({ metadata }) =>
      /^ipv_all$/.test(metadata?.['trafimage.filter']),
  }),
];

const topics = [
  ...getEnergieTopicConf([netzkarteLayer]),
  ...getNetzentwicklungTopicConf([netzkarteLayer]),
  ...getAnlageVerantwortlicheTopicConf([netzkarteLayer]),
  ...getImmobilienTopicConf([netzkarteLayer]),
  ...getFunkmesswagenTopicConf([netzkarteLayer]),
  ...getZweitausbildungTopicConf([netzkarteLayer], noDataStyle),
  ...getRailplusBetreiberinnenTopicConf([netzkarteLayer]),
  {
    key: 'bauprojekte',
    name: 'Bauprojekte Bahninfrastruktur',
    allowTilePublication: REACT_APP_ENVIRONMENT === 'prod',
    baseLayers: [netzkarteLayer],
    uiSchema: uiSchemas.bauprojekte,
    listColumns: ['projektname', 'ort', 'art', 'modified_at', 'modified_by'],
    styleFunction: (feature, isSelected) => {
      const styles = bauprojekteStyle(feature);

      if (isSelected) {
        const hlStyle = new Style({
          image: new Circle({
            radius: 15,
            fill: new Fill({
              color: 'rgba(0, 31, 135, 0.5)',
            }),
          }),
        });
        return [hlStyle, ...styles];
      }

      return styles;
    },
    oleConfig: {
      canAddGeometries: true,
      canEditGeometries: true,
    },
    getFeatureTitle: (feature) => feature.get('projektname'),
    filterConfig: [
      {
        type: 'select',
        field: 'ort',
        label: 'Ort',
        multiple: true,
        choices: {
          '': '- Keine -',
          bahnhof: 'Bahnhof',
          strecke: 'Strecke',
          bahnhof_und_strecke: 'Bahnhof und Strecke',
          uebrige: 'Übrige Standorte',
        },
      },
      {
        type: 'select',
        field: 'art',
        label: 'Art',
        multiple: true,
        choices: {
          unterhalt: 'Unterhalt',
          ausbau: 'Ausbau',
        },
      },
    ],
  },
  {
    key: 'mietvelo',
    name: 'Mietvelo',
    allowTilePublication: false,
    baseLayers: [netzkarteLayer],
    uiSchema: uiSchemas.mietvelo,
    search: 'default',
    listColumns: [
      'general.name',
      'site.location',
      'site.street',
      'modified_at',
      'modified_by',
    ],
    filterConfig: [
      {
        type: 'boolean',
        label: 'Durch SBB betrieben',
        field: 'operated_by_sbb',
      },
    ],
    styleFunction: (feat, selected) => {
      return selected ? mietveloSelectedStyle : mietveloStyle;
    },
    getFeatureTitle: (feature) => {
      const general = feature.get('general');
      return general ? general.name : '';
    },
    oleConfig: {
      canAddGeometries: true,
      canEditGeometries: true,
    },
  },
  {
    key: 'direktverbindungen',
    name: 'Direktverbindungen',
    baseLayers: [netzkarteLayer],
    syncFeaturesOnSave: true,
    allowTilePublication: REACT_APP_ENVIRONMENT === 'prod',
    uiSchema: uiSchemas.direktverbindungen,
    getFeatureTitle: (feature) => feature?.get('name') || '',
    oleConfig: {
      canAddGeometries: true,
      canEditGeometries: false,
    },
    openFormOnAdd: true,
    search: 'default',
    listConfig: {
      getColumns: () => [
        { field: 'name', headerName: 'Name', flex: 1 },
        {
          field: 'tagnacht',
          headerName: 'Tag- / Nachtverbindung',
          flex: 1,
        },
        { field: 'modified_at', headerName: 'Bearbeitet am', flex: 1 },
        { field: 'modified_by', headerName: 'Bearbeitet durch', flex: 1 },
      ],
      getRow: (feature) => ({
        name: feature.get('name'),
        tagnacht: feature.get('nachtverbindung') ? 'Nacht' : 'Tag',
        modified_at: toDateString(feature.get('modified_at')),
        modified_by: feature.get('modified_by'),
      }),
    },
    styleFunction: (feature, resolution, isSelected) => {
      const minResolution = feature.get('minResolution');
      const maxResolution = feature.get('maxResolution');
      const inRange = resolution <= minResolution && resolution > maxResolution;

      const nachtverbindung = feature.get('nachtverbindung');
      const viaType = feature.get('via_type');

      let color = nachtverbindung ? 'blue' : 'yellow';

      if (feature.getGeometry().getType() === 'Point') {
        if (viaType === 'visible') {
          color = 'lightGreen';
        } else if (viaType === 'switch') {
          color = 'green';
        } else if (viaType === 'none') {
          color = 'gray';
        }
      }

      // Default style for route lines and stations
      const style = new Style({
        stroke: inRange && new Stroke({ color, width: isSelected ? 6 : 3 }),
        image: new Circle({
          radius: isSelected ? 8 : 6,
          fill: new Fill({ color }),
          stroke: new Stroke({ color: 'black', width: 1 }),
        }),
        zIndex: isSelected ? 100 : 0,
      });

      const blackBorder = new Style({
        stroke:
          inRange && new Stroke({ color: 'black', width: isSelected ? 8 : 5 }),
        zIndex: isSelected ? 100 : 0,
      });
      return [blackBorder, style];
    },
    customSidebarItems: [
      {
        position: 2,
        component: 'CalculateNetworkPlan',
      },
    ],
    previewConfig: {
      previewLayer: dvPreviewLayer,
      previewEnableValue: 'networkReady',
    },
    publishTopic: 'direktverbindungen_public',
  },
];

const toExport = topics.sort((a, b) =>
  a.name.toLowerCase().localeCompare(b.name.toLowerCase()),
);

export default toExport;
