import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import { InputLabel } from '@mui/material';
import { makeStyles } from '@mui/styles';
import React from 'react';
import PropTypes from 'prop-types';
import AppPropTypes from '../../../model/app/propTypes';

const useStyles = makeStyles(() => ({
  container: {
    display: 'grid',
    gridTemplateColumns: 'repeat(2, 1fr)',
    columnGap: 15,
  },
  rowTitle: {
    marginTop: 10,
    position: 'relative',
  },
}));

const DirektverbindungViaField = ({
  formData,
  onChange,
  schema,
  index,
  registry,
}) => {
  const classes = useStyles();
  const { RoutingStopsFinderWidget } = registry.widgets;

  return (
    <div className={classes.container}>
      {index === 0 && (
        <>
          <div className={classes.rowTitle}>
            <InputLabel required={false} shrink>
              Zwischenstation
            </InputLabel>
          </div>
          <div className={classes.rowTitle}>
            <InputLabel required={false}>Typ</InputLabel>
          </div>
        </>
      )}
      <RoutingStopsFinderWidget
        value={formData.name}
        onChange={({ name, uid }) => onChange({ ...formData, name, uid })}
      />
      <Select
        onChange={(event) =>
          onChange({ ...formData, via_type: event.target.value })
        }
        value={formData.via_type}
      >
        {schema.properties.via_type.enum.map((type, i) => (
          <MenuItem value={type} key={type}>
            {schema.properties.via_type.enumNames[i]}
          </MenuItem>
        ))}
      </Select>
    </div>
  );
};

DirektverbindungViaField.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  formData: PropTypes.object,
  index: PropTypes.number.isRequired,
  onChange: PropTypes.func.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  registry: PropTypes.object,
  schema: AppPropTypes.schema.isRequired,
};

DirektverbindungViaField.defaultProps = {
  formData: {},
  registry: {},
};

export default DirektverbindungViaField;
