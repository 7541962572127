/**
 * This function has to goal to transform formular errors from backedn to an extraErrors object manageable by the rjsf form.
 *
 * Backend errors:
 *
 *  {"barrierefreie_touristische_angebote":[["At least one label must be defined."]]}
 *
 * Extra errors (see https://react-jsonschema-form.readthedocs.io/en/latest/api-reference/form-props/#extraerrors)
 *
 * {
 *   barrierefreie_touristische_angebote: [
 *     {
 *       __errors: ['At least one label must be defined.'],
 *     },
 *   ],
 * };
 */
const tranformToExtraErrors = (errors = {}) => {
  const extraErrors = {};

  // Special case
  //   {
  //     "non_field_errors": [
  //         "Unable to log in with provided credentials."
  //     ]
  // }
  if (errors.non_field_errors) {
    // eslint-disable-next-line no-underscore-dangle
    extraErrors.__errors = errors.non_field_errors;
    // eslint-disable-next-line no-param-reassign
    delete errors.non_field_errors;
  }

  if (Array.isArray(errors)) {
    return errors.map((value) => {
      if (typeof value === 'string') {
        return {
          __errors: [value],
        };
      }
      if (Array.isArray(value)) {
        const obj = {};
        // The value is an array of errors, so we convert to an object with the __errors prop.
        const eltPropertyErrors = value.filter(
          (elt) => typeof elt !== 'string',
        );
        const eltErrors = value.filter((elt) => typeof elt === 'string');
        if (eltErrors.length) {
          // eslint-disable-next-line no-underscore-dangle
          obj.__errors = eltErrors;
        }

        eltPropertyErrors.forEach((objProperty) => {
          Object.entries(objProperty).forEach(([key, valuee]) => {
            obj[key] = {
              __errors: Array.isArray(valuee) ? valuee : [valuee],
            };
          });
        });
        return obj;
      }
      return tranformToExtraErrors(value);
    });
  }
  if (typeof errors === 'object') {
    Object.entries(errors).forEach(([key, value]) => {
      if (Array.isArray(value) && typeof value[0] === 'string') {
        // The value is an array of errors, so we convert to an object with the __errors prop.
        extraErrors[key] = {
          __errors: value,
        };
        // These are errors related to an specific property of an element in the arraythe array
      } else if (typeof value === 'string') {
        // The value is an error, so we convert to an object with the __errors prop.
        extraErrors[key] = {
          __errors: [value],
        };
      } else {
        extraErrors[key] = tranformToExtraErrors(value);
      }
    });
  }

  return extraErrors;
};

export default tranformToExtraErrors;
