import PropTypes from 'prop-types';
import OLMap from 'ol/Map';
import Feature from 'ol/Feature';
import { Layer } from 'mobility-toolbox-js/ol';

const topic = PropTypes.shape({
  key: PropTypes.string,
  name: PropTypes.string,
  baseLayers: PropTypes.arrayOf(PropTypes.instanceOf(Layer)),
});

const schema = PropTypes.shape({
  properties: PropTypes.shape(),
  required: PropTypes.arrayOf(PropTypes.string),
  type: PropTypes.string,
  title: PropTypes.string,
});

const uiSchema = PropTypes.shape({
  'ui:title': PropTypes.string,
  'ui:order': PropTypes.arrayOf(PropTypes.string),
});

const fieldsets = PropTypes.arrayOf(
  PropTypes.shape({
    title: PropTypes.string,
    fields: PropTypes.PropTypes.arrayOf(PropTypes.string),
  }),
);

const formContext = PropTypes.shape({
  map: PropTypes.instanceOf(OLMap),
  backendApiUrl: PropTypes.string,
  selectedFeature: PropTypes.instanceOf(Feature),
  topic,
});

const lineSegment = PropTypes.shape({
  line_number: PropTypes.string,
  km_start: PropTypes.number,
  km_end: PropTypes.number,
});

const station = PropTypes.shape({
  name: PropTypes.string,
  uid: PropTypes.string,
});

export default {
  topic,
  schema,
  uiSchema,
  fieldsets,
  formContext,
  lineSegment,
  station,
};
