import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@mui/styles';
import { MdDone, MdClear } from 'react-icons/md';

const propTypes = {
  isSaveSuccess: PropTypes.bool,
};

const defaultProps = {
  isSaveSuccess: undefined,
};

const useStyles = makeStyles({
  footerMsg: {
    display: 'flex',
    alignItems: 'center',
    color: '#c00',
    padding: 5,

    '& svg': {
      paddingRight: 5,
    },
  },

  success: {
    display: 'flex',
    alignItems: 'center',
    color: 'green',
  },

  error: {
    display: 'flex',
    alignItems: 'center',
    color: 'red',
  },
});

/**
 * This component displays a saving success or error message.
 *
 * isSaveSuccess === undefined || null --> display nothing
 * isSaveSuccess === true --> display success message
 * isSaveSuccess === false --> display error message
 */

const SaveResultMessage = ({ isSaveSuccess }) => {
  const { t } = useTranslation();
  const classes = useStyles();

  if (isSaveSuccess === undefined || isSaveSuccess === null) {
    return null;
  }

  return (
    <>
      {(isSaveSuccess === true || isSaveSuccess === false) && (
        <div className={classes.footerMsg}>
          {isSaveSuccess === true && (
            <span className={classes.success}>
              <MdDone />
              {t('Speichern erfolgreich.')}
            </span>
          )}
          {isSaveSuccess === false && (
            <span className={classes.error}>
              <MdClear />
              {t('Fehler beim Speichern.')}
            </span>
          )}
        </div>
      )}
    </>
  );
};

SaveResultMessage.propTypes = propTypes;
SaveResultMessage.defaultProps = defaultProps;

export default React.memo(SaveResultMessage);
