import React from 'react';
import { useSelector } from 'react-redux';
import { makeStyles } from '@mui/styles';
import { Chip } from '@mui/material';
import { ReactComponent as UserIcon } from '../../images/user.svg';

const flexBox = {
  display: 'flex',
  alignItems: 'center',
};

const useStyles = makeStyles({
  container: {
    position: 'absolute',
    top: 7,
    right: 15,
    ...flexBox,
    zIndex: 1300,
    textAlign: 'center',
    color: '#515151',
    gap: 10,
  },
  user: flexBox,
  name: {
    margin: '0 5px 0 5px',
    height: 40,
    lineHeight: 2.5,
  },
  icon: {
    height: 30,
    marginBottom: 5,
  },
});

function User() {
  const { container, name, icon, user } = useStyles();
  const loginStatus = useSelector((state) => state.app.loginStatus);
  return (
    <div className={container}>
      {process.env.REACT_APP_ENVIRONMENT === 'dev' && (
        <Chip
          label="Development"
          color="warning"
          variant="outlined"
          // sx={{ position: 'absolute', top: 10, right: 200, zIndex: 9999 }}
        />
      )}
      <span className={user}>
        <UserIcon focusable={false} className={icon} />
        <div className={name}>{loginStatus.username}</div>
      </span>
    </div>
  );
}

export default User;
