/* eslint-disable react/jsx-props-no-spreading */
import { InputLabel, TextField, CircularProgress } from '@mui/material';
import { makeStyles } from '@mui/styles';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Feature } from 'ol';
import { toLonLat } from 'ol/proj';
import AppPropTypes from '../../../model/app/propTypes';
import Search from '../../Search/Search';
import fetchStops from '../../../utils/fetchStops';

const useStyles = makeStyles(() => ({
  autocomplete: {
    minWidth: 150,
    padding: '0 0 0 10px',
    '& .MuiTextField-root': {
      minWidth: '0 !important',
    },
  },
  label: {
    marginTop: 15,
  },
}));

const useFetchStops = (val, apiKey, center) => {
  const [stops, setStops] = useState([]);
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    setLoading(true);
    if (val) {
      fetchStops(val, apiKey, null, `&mots=rail&ref_location=${center}`).then(
        (data) => {
          setLoading(false);
          setStops(data);
        },
      );
    } else {
      setLoading(false);
    }
  }, [apiKey, val, center]);
  return { stops: [...stops], loading };
};

function RoutingStopsFinderWidget(props) {
  const { onChange, value, options, rawErrors, required, label } = props;
  const { t } = useTranslation();
  const apiKey = useSelector((state) => state.app.apiKey);
  const center = useSelector((state) => state.map.center);
  const classes = useStyles();
  // For some backend reason we can't make the name field required so we implement it ourselves
  const isRequired = required || options.required;
  const helperText = isRequired && !value && 'is a required property';
  const hasError = !!helperText || !!rawErrors.length;
  const { stops, loading } = useFetchStops(
    value,
    apiKey,
    toLonLat(center).join(','),
  );

  return (
    <>
      {label && (
        <div className={classes.label}>
          <InputLabel required={isRequired} error={hasError} shrink>
            {label}
          </InputLabel>
        </div>
      )}
      <Search
        searchEndpoint="https://api.geops.io/stops/v1"
        onSelect={(stop) => onChange(stop.getProperties())}
        onInputChange={(e, val, abortController) =>
          fetchStops(
            val,
            apiKey,
            abortController,
            `&mots=rail&ref_location=${toLonLat(center).join(',')}`,
          )
        }
        value={stops[0] || new Feature()}
        className={classes.autocomplete}
        renderInput={(params) => (
          <span style={{ position: 'relative' }}>
            <TextField placeholder={t('Haltestelle...')} {...params} />
            {loading && (
              <CircularProgress
                size={15}
                sx={{ position: 'absolute', top: 12, right: 5 }}
              />
            )}
          </span>
        )}
      />
    </>
  );
}

RoutingStopsFinderWidget.propTypes = {
  onChange: PropTypes.func.isRequired,
  options: PropTypes.shape(),
  value: PropTypes.oneOfType([PropTypes.string, AppPropTypes.station]),
  rawErrors: PropTypes.arrayOf(PropTypes.shape()),
  required: PropTypes.bool,
  label: PropTypes.string,
};

RoutingStopsFinderWidget.defaultProps = {
  value: '',
  options: {},
  rawErrors: [],
  required: false,
  label: null,
};

export default RoutingStopsFinderWidget;
