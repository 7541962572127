import React from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import Button from '@mui/material/Button';
import Dialog from '../Dialog';
import { setDialogVisible } from '../../model/app/actions';

function PreviewWarnDialog() {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  return (
    <Dialog
      title={<b>{t('Achtung')}</b>}
      body={
        <div>
          {t(
            'Der Liniennetzplan wird gerade neu berechnet. Damit Sie Ihre Aktualisierungen in der Vorschau sehen können, müssen Sie diesen erneut berechnen.',
          )}
        </div>
      }
      footer={
        <>
          <Button
            className="ms-dialog-footer-button"
            onClick={() => {
              dispatch(setDialogVisible());
            }}
          >
            {t('Abbrechen')}
          </Button>
        </>
      }
    />
  );
}

const memoized = React.memo(PreviewWarnDialog);
memoized.NAME = 'preview.warn';
export default memoized;
