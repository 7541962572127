import uiSchemas from '../uiSchemas';
import { toDateString } from '../utils';

const { REACT_APP_ENVIRONMENT } = process.env;

const toExport = (baselayers) => [
  {
    key: 'railplus_betreiberinnen',
    name: 'Railplus - Betreiberinnen',
    allowTilePublication: REACT_APP_ENVIRONMENT === 'prod',
    getFeatureTitle: (feature) => feature.get('long_name') || '',
    search: 'default',
    baseLayers: baselayers,
    uiSchema: uiSchemas.railplusBetreiberinnen,
    schemaOverrides: {
      properties: {
        logo: { type: ['string', 'null'] },
        picture: { type: ['string', 'null'] },
      },
    },
    listConfig: {
      getColumns: () => [
        { field: 'name', headerName: 'Kürzel', flex: 0.5 },
        { field: 'long_name', headerName: 'Name der Bahn', flex: 1 },
        { field: 'modified_at', headerName: 'Bearbeitet am', flex: 1 },
        { field: 'modified_by', headerName: 'Bearbeitet durch', flex: 1 },
      ],
      getRow: (feature) => ({
        name: feature.get('name'),
        long_name: feature.get('long_name'),
        modified_at: toDateString(feature.get('modified_at')),
        modified_by: feature.get('modified_by'),
      }),
    },
  },
];

export default toExport;
