import React, { useState, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { makeStyles } from '@mui/styles';

import { Typography } from '@mui/material';
import User from '../User';
import PreviewSwitch from '../PreviewSwitch';

import {
  setTopic,
  setFeatures,
  setSelectedFeature,
  setRoutingFeatures,
  setIsCancelDialogVisible,
  setCancelDialogOnAction,
} from '../../model/app/actions';

const flexAlignCenter = {
  display: 'flex',
  alignItems: 'center',
  height: '100%',
};

const useStyles = makeStyles({
  root: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    backgroundColor: 'white',
    borderBottom: '1px solid #e3e3e3',
    height: 50,
    zIndex: 1300,
  },
  left: {
    ...flexAlignCenter,
    gap: 40,
  },
  right: {
    ...flexAlignCenter,
  },
  topicMenuToggler: {
    height: '100%',
  },
});

const urlSearchParams = new URLSearchParams(window.location.search);

function Header() {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState(null);
  const topics = useSelector((state) => state.app.topics);
  const topic = useSelector((state) => state.app.topic);
  const dispatch = useDispatch();
  const previewTrigger = topic?.previewConfig?.previewEnableValue;
  const previewEnableValue = useSelector(
    (state) => state.app.previewEnableValue,
  );
  const isFormUnsaved = useSelector((state) => state.app.isFormUnsaved);
  const overridePreviewEnabled = urlSearchParams.get('preview') === 'true'; // Activate preview mode by adding ?preview=true to the URL for testing

  const handleTopicClick = useCallback(
    (newTopic) => {
      const switchTopic = () => {
        setAnchorEl(null);
        // Empty the features before changing topic.
        dispatch(setFeatures([]));
        dispatch(setRoutingFeatures([]));
        dispatch(setSelectedFeature());
        dispatch(setTopic(newTopic));
      };
      if (isFormUnsaved) {
        return () => {
          dispatch(setIsCancelDialogVisible(true));
          dispatch(setCancelDialogOnAction(switchTopic));
        };
      }
      return switchTopic;
    },
    [dispatch, isFormUnsaved],
  );

  return (
    <div className={classes.root}>
      <div className={classes.left}>
        {topics.length === 1 && (
          <Typography style={{ margin: '0 10px' }}>{topic.name}</Typography>
        )}
        {topics.length > 1 && (
          <>
            <Button
              className={classes.topicMenuToggler}
              onClick={(evt) => setAnchorEl(evt.currentTarget)}
              endIcon={anchorEl ? <ExpandLessIcon /> : <ExpandMoreIcon />}
            >
              {topic && topic.name}
            </Button>
            <Menu
              anchorEl={anchorEl}
              open={!!anchorEl}
              onClose={() => setAnchorEl()}
            >
              {topics.map((tpc) => (
                <MenuItem
                  onClick={handleTopicClick(tpc)}
                  key={tpc.key}
                  disabled={topic.key === tpc.key}
                >
                  {tpc.name}
                </MenuItem>
              ))}
            </Menu>
          </>
        )}
        {topic.previewConfig ? (
          <PreviewSwitch
            enabled={
              overridePreviewEnabled || previewEnableValue === previewTrigger
            }
          />
        ) : null}
      </div>
      <div className={classes.right}>
        <User />
      </div>
    </div>
  );
}

export default React.memo(Header);
