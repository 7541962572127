import { Style, Fill, Stroke, RegularShape, Circle } from 'ol/style';
import uiSchemas from '../uiSchemas';
import { toDateString } from '../utils';

const kategorieChoices = {
  UW: 'Unterwerk',
  KW: 'Produktionsanlage',
};

const kategorieSelect = {
  type: 'select',
  field: 'kategorie',
  label: 'Kategorie',
  multiple: true,
  choices: kategorieChoices,
};

const loseColorMapping = {
  1: 'rgba(1,160,170, 1)',
  2: 'rgba(228,45,42, 1)',
  3: 'rgba(128,204,40, 1)',
  4: 'rgba(245,115,192,1)',
  5: 'rgba(246,135,18, 1)',
  6: 'rgba(7,154,225, 1)',
  7: 'rgba(134,95,200 ,1)',
  8: 'rgba(2,200,200, 1)',
  9: 'rgba(251,195,20, 1)',
  10: 'rgba(0,151,72, 1)',
  11: 'rgba(180,20,110, 1)',
};

const getLeitungStyles = (feat) => {
  return [
    new Style({
      stroke: new Stroke({
        color: loseColorMapping[feat.get('los_nr')],
        width: 5,
      }),
    }),
  ];
};

const produktionsanlageStyles = [
  new Style({
    image: new Circle({
      radius: 8.2,
      fill: new Fill({
        color: 'rgba(245, 215, 66, 1)',
      }),
      stroke: new Stroke({ color: 'black', width: 2.5 }),
    }),
  }),
  new Style({
    image: new Circle({
      radius: 4.8,
      fill: new Fill({
        color: 'rgba(0, 0, 0, 1)',
      }),
    }),
  }),
];

const unterwerkStyles = [
  new Style({
    image: new RegularShape({
      fill: new Fill({ color: 'rgba(245, 215, 66, 1)' }),
      stroke: new Stroke({ color: 'black', width: 2 }),
      points: 4,
      radius: 10,
      angle: Math.PI / 4,
    }),
  }),
];

const getAnlageStyles = (feat) => {
  const category = feat.get('kategorie');
  return category === 'KW'
    ? [...produktionsanlageStyles]
    : [...unterwerkStyles];
};

const getColumns = (isAnlagen) => {
  const columns = [
    {
      field: isAnlagen ? 'anlage_id' : 'trassennummer',
      headerName: isAnlagen ? 'Anlage-ID' : 'Trassennummer',
      minWidth: isAnlagen ? 100 : 150,
    },
    {
      field: 'bezeichnung',
      headerName: 'Bezeichnung',
    },
    {
      field: 'anlagebetreuer',
      headerName: isAnlagen
        ? 'AVANT/Anlagebetreuer'
        : 'Anlagenverantwortliche (AnV) ',
      minWidth: 300,
    },
    {
      field: 'betrieb_instandhaltung',
      headerName: 'Verantwortlich Betrieb und Instandhaltung',
      minWidth: 300,
    },
    {
      field: 'life_cycle_manager',
      headerName: 'Life-Cycle Manager',
      minWidth: 250,
    },
    {
      field: 'intervention_pikettnummer_tag',
      headerName: 'Intervention - Pikettnummer (Tag)',
      minWidth: 250,
    },
    {
      field: 'intervention_pikettnummer_nacht',
      headerName: 'Intervention - Pikettnummer (Nacht)',
      minWidth: 250,
    },
    {
      field: 'intervention_pikettnummer_detail',
      headerName: 'Intervention - Pikettnummer Details',
      minWidth: 250,
    },
    {
      field: 'intervention_mail',
      headerName: 'Intervention - E-Mail',
      minWidth: 200,
    },
    {
      field: 'intervention_mail_detail',
      headerName: 'Intervention - E-Mail Details',
      minWidth: 200,
    },
    {
      field: 'intervention_bemerkungen',
      headerName: 'Intervention - Bemerkungen',
      minWidth: 200,
    },
    {
      field: 'intervention_persons',
      headerName: 'Intervention - Personen',
      minWidth: 300,
    },
    {
      field: 'intervention_energie_persons',
      headerName: 'Intervention - Externe Personen',
      minWidth: 300,
    },
    {
      field: 'sicherheitsrelevant_link',
      headerName: 'Sicherheitsrelevant - Link',
      minWidth: 200,
    },
    {
      field: 'sicherheitsrelevant_bemerkungen',
      headerName: 'Sicherheitsrelevant - Bemerkungen',
      minWidth: 200,
    },
    {
      field: 'sicherheitsrelevant_schalt_erd_berechtigt_persons',
      headerName: 'Sicherheitsrelevant - Schalt-Erd-Berechtigte Personen',
      minWidth: 300,
    },
    {
      field: 'sicherheitsrelevant_schalt_erd_berechtigt_energie_persons',
      headerName:
        'Sicherheitsrelevant - Externe Schalt-Erd-Berechtigte Personen',
      minWidth: 300,
    },
    {
      field: 'sicherheitsrelevant_sachverstaendige_persons',
      headerName: 'Sicherheitsrelevant - Sachverständige Personen EN',
      minWidth: 300,
    },
    {
      field: 'sicherheitsrelevant_sachverstaendige_energie_persons',
      headerName: 'Sicherheitsrelevant - Externe Sachverständige Personen',
      minWidth: 300,
    },
    {
      field: 'sicherheitsrelevant_instruiert_persons',
      headerName: 'Sicherheitsrelevant - Instruierte Personen EN',
      minWidth: 300,
    },
    {
      field: 'sicherheitsrelevant_instruiert_energie_persons',
      headerName: 'Sicherheitsrelevant - Externe Instruierte Personen',
      minWidth: 300,
    },
  ];
  if (isAnlagen) {
    columns.splice(1, 0, {
      field: 'kategorie',
      headerName: 'Kategorie',
    });
    columns.splice(2, 0, {
      field: 'unterkategorie',
      headerName: 'Unter-Kategorie',
    });
  }
  const fittedColumns = columns.map((column) => {
    const newColumn = { ...column };
    newColumn.minWidth = column.minWidth || 150; // makes the table grow horizontally with scrollbar
    return newColumn;
  });
  if (!isAnlagen) {
    fittedColumns.splice(1, 0, {
      field: 'los_nr',
      headerName: 'Losnummer',
      minWidth: 50,
    });
  }
  return fittedColumns;
};

const getCommonListValues = (feature, schema) => {
  if (!schema) return {};
  const externalPersonMapping = (
    schema.definitions['energie_personen_form-detail'].enum || []
  ).reduce((finalMapping, uri, index) => {
    return {
      ...finalMapping,
      [uri]:
        schema.definitions['energie_personen_form-detail'].enumNames[index],
    };
  }, {});
  const anlagebetreuer = feature.get('anlagebetreuer');
  const betriebInstandhaltung = feature.get('betrieb_instandhaltung');
  const lifeCycleManager = feature.get('life_cycle_manager');
  const interventionPikettTag = feature.get('intervention_pikettnummer_tag');
  const interventionPikettNacht = feature.get(
    'intervention_pikettnummer_nacht',
  );
  const interventionPikettDetails = feature.get(
    'intervention_pikettnummer_detail',
  );
  const interventionEmail = feature.get('intervention_mail');
  const interventionEmailDetails = feature.get('intervention_mail_detail');
  const interventionBemerkungen = feature.get('intervention_bemerkungen');
  const interventionPersonen = feature.get('intervention_persons');
  const interventionExternePersonen = feature.get(
    'intervention_energie_persons',
  );
  const sicherheitsrelevantLink = feature.get('sicherheitsrelevant_link');
  const sicherheitsrelevantBemerkungen = feature.get(
    'sicherheitsrelevant_bemerkungen',
  );
  const sicherheitSchaltErdPersonen = feature.get(
    'sicherheitsrelevant_schalt_erd_berechtigt_persons',
  );
  const sicherheitSchaltErdExternePersonen = feature.get(
    'sicherheitsrelevant_schalt_erd_berechtigt_energie_persons',
  );
  const sicherheitInstruiertPersonen = feature.get(
    'sicherheitsrelevant_instruiert_persons',
  );
  const sicherheitInstruiertExternePersonen = feature.get(
    'sicherheitsrelevant_instruiert_energie_persons',
  );
  const sicherheitSachverstaendigPersonen = feature.get(
    'sicherheitsrelevant_sachverstaendige_persons',
  );
  const sicherheitSachverstaendigExternePersonen = feature.get(
    'sicherheitsrelevant_sachverstaendige_energie_persons',
  );
  return {
    anlagebetreuer: anlagebetreuer?.label,
    betrieb_instandhaltung: betriebInstandhaltung?.label,
    life_cycle_manager: lifeCycleManager?.label,
    intervention_pikettnummer_tag: interventionPikettTag,
    intervention_pikettnummer_nacht: interventionPikettNacht,
    intervention_pikettnummer_detail: interventionPikettDetails,
    intervention_mail: interventionEmail,
    intervention_mail_detail: interventionEmailDetails,
    intervention_bemerkungen: interventionBemerkungen,
    intervention_persons: interventionPersonen
      .map((person) => person?.person?.label)
      .join(', '),
    intervention_energie_persons: interventionExternePersonen
      .map((person) => externalPersonMapping[person?.energie_person])
      .join(', '),
    sicherheitsrelevant_link: sicherheitsrelevantLink,
    sicherheitsrelevant_bemerkungen: sicherheitsrelevantBemerkungen,
    sicherheitsrelevant_schalt_erd_berechtigt_persons: sicherheitSchaltErdPersonen
      .map((person) => person?.label)
      .join(', '),
    sicherheitsrelevant_schalt_erd_berechtigt_energie_persons: sicherheitSchaltErdExternePersonen
      .map((person) => externalPersonMapping[person])
      .join(', '),
    sicherheitsrelevant_instruiert_persons: sicherheitInstruiertPersonen
      .map((person) => person?.label)
      .join(', '),
    sicherheitsrelevant_instruiert_energie_persons: sicherheitInstruiertExternePersonen
      .map((person) => externalPersonMapping[person])
      .join(', '),
    sicherheitsrelevant_sachverstaendige_persons: sicherheitSachverstaendigPersonen
      .map((person) => person?.label)
      .join(', '),
    sicherheitsrelevant_sachverstaendige_energie_persons: sicherheitSachverstaendigExternePersonen
      .map((person) => externalPersonMapping[person])
      .join(', '),
  };
};

const getListConfig = (isAnlagen) => {
  const getRow = isAnlagen
    ? (feature, schema) => ({
        bezeichnung: feature.get('bezeichnung'),
        anlage_id: feature.get('anlage_id'),
        kategorie:
          kategorieChoices[feature.get('kategorie')] || kategorieChoices.UW,
        unterkategorie: feature.get('unterkategorie'),
        ...getCommonListValues(feature, schema),
      })
    : (feature, schema) => ({
        bezeichnung: feature.get('bezeichnung'),
        trassennummer: feature.get('trassennummer'),
        los_nr: feature.get('los_nr'),
        ...getCommonListValues(feature, schema),
      });
  return {
    getColumns: () => getColumns(isAnlagen),
    getRow: (feature, schema) => getRow(feature, schema),
  };
};

const getLeitungen = (baseLayers) => ({
  key: 'energie_leitungen',
  name: 'Energie - Leitungen',
  allowTilePublication: process.env.REACT_APP_ENVIRONMENT === 'prod',
  baseLayers,
  search: 'default',
  uiSchema: uiSchemas.energieLeitungen,
  styleFunction: (feat, isSelected) => {
    const styles = getLeitungStyles(feat);
    if (isSelected) {
      styles.unshift(
        new Style({
          stroke: new Stroke({ color: 'rgba(0, 31, 135, 0.5)', width: 10 }),
        }),
      );
    }
    return styles;
  },
  getFeatureTitle: (feature) => {
    return feature.get('bezeichnung');
  },
  oleConfig: {
    canAddGeometries: false,
    canEditGeometries: false,
    canDeleteGeometries: false,
  },
  listConfig: getListConfig(),
  personMutateEnabled: true,
});

const getAnlagen = (baseLayers) => ({
  key: 'energie_anlagen',
  name: 'Energie - Anlagen',
  allowTilePublication: process.env.REACT_APP_ENVIRONMENT === 'prod',
  baseLayers,
  filterConfig: [kategorieSelect],
  search: 'default',
  uiSchema: uiSchemas.energieAnlagen,
  styleFunction: (feat, isSelected) => {
    const styles = getAnlageStyles(feat);
    if (isSelected) {
      styles.unshift(
        new Style({
          image: new Circle({
            radius: 15,
            fill: new Fill({
              color: 'rgba(0, 31, 135, 0.5)',
            }),
          }),
        }),
      );
    }
    return styles;
  },
  getFeatureTitle: (feature) => {
    return `${feature.get('bezeichnung')} (${feature.get('anlage_id')})`;
  },
  oleConfig: {
    canAddGeometries: false,
    canEditGeometries: false,
    canDeleteGeometries: false,
  },
  listConfig: getListConfig(true),
  personMutateEnabled: true,
});

const getPersonen = (baseLayers) => ({
  key: 'energie_personen',
  name: 'Energie - Externe Personen',
  baseLayers,
  search: 'default',
  uiSchema: uiSchemas.energiePersonen,
  oleConfig: { canAddGeometries: true },
  getFeatureTitle: (feature) =>
    `${feature.get('first_name') || ''} ${feature.get('last_name') || ''}`,
  listConfig: {
    getColumns: () => [
      { field: 'first_name', headerName: 'Vorname', flex: 1 },
      { field: 'last_name', headerName: 'Nachname', flex: 1 },
      { field: 'phone', headerName: 'Telefon', flex: 1 },
      { field: 'email', headerName: 'E-Mail', flex: 1 },
      { field: 'modified_at', headerName: 'Bearbeitet am', flex: 1 },
      { field: 'modified_by', headerName: 'Bearbeitet durch', flex: 1 },
    ],
    getRow: (feature) => ({
      first_name: feature.get('first_name'),
      last_name: feature.get('last_name'),
      phone: feature.get('phone'),
      email: feature.get('email'),
      modified_at: toDateString(feature.get('modified_at')),
      modified_by: feature.get('modified_by'),
    }),
  },
});

const toExport = (baseLayers) => [
  getAnlagen(baseLayers),
  getLeitungen(baseLayers),
  getPersonen(baseLayers),
];

export default toExport;
