import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { TextField } from '@mui/material';

import { setFilters } from '../../model/app/actions';

const propTypes = {
  filterConfig: PropTypes.shape({
    label: PropTypes.string.isRequired,
    field: PropTypes.string.isRequired,
  }).isRequired,
};

const SearchFilter = ({ filterConfig }) => {
  const dispatch = useDispatch();
  const { filters } = useSelector((state) => state.app);
  const { label, field } = filterConfig;

  return (
    <TextField
      fullWidth
      variant="standard"
      label={label}
      onChange={(evt) => {
        dispatch(
          setFilters({
            ...filters,
            [field]: evt.target.value,
          }),
        );
      }}
    />
  );
};

SearchFilter.propTypes = propTypes;
export default SearchFilter;
