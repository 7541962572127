import { Style, Circle, Fill, Stroke, RegularShape } from 'ol/style';
import { Point } from 'ol/geom';
import uiSchemas from '../uiSchemas';
import customFormats from '../customFormats';

const { REACT_APP_BACKEND_URL, REACT_APP_ENVIRONMENT } = process.env;

const noDataStyle = new Style({
  zIndex: 0,
  image: new Circle({
    radius: 5,
    fill: new Fill({
      color: 'rgba(200, 200, 230, 0.5)',
    }),
    stroke: new Stroke({
      color: 'rgb(20, 58, 133)',
    }),
  }),
});

const immoColors = {
  RME: {
    'IM-FM-TIRM-T1': 'rgb(48, 159, 207)',
    'IM-FM-TIRM-T2': 'rgb(0, 127, 173)',
    'IM-FM-TIRM-T3': 'rgb(0, 96, 140)',
    'IM-FM-TIRM-T4': 'rgb(0, 67, 109)',
    'IM-FM-TIRM-T5': 'rgb(0, 40, 79)',
  },
  RWT: {
    'IM-FM-TIRW-T1': 'rgb(242, 126, 0)',
    'IM-FM-TIRW-T2': 'rgb(201, 92, 0)',
    'IM-FM-TIRW-T3': 'rgb(160, 60, 0)',
    'IM-FM-TIRW-T4': 'rgb(123, 25, 0)',
    'IM-FM-TIRW-T5': 'rgb(90, 0, 0)',
  },
  ROT: {
    'IM-FM-TIRO-T1': 'rgb(97, 199, 81)',
    'IM-FM-TIRO-T2': 'rgb(52, 159, 43)',
    'IM-FM-TIRO-T3': 'rgb(0, 121, 0)',
    'IM-FM-TIRO-T4': 'rgb(0, 84, 0)',
    'IM-FM-TIRO-T5': 'rgb(0, 49, 0)',
  },
};

const immoShapes = {
  // Circle
  'Kat. 1': new Circle({
    radius: 4,
    fill: new Fill({
      color: 'rgba(255, 255, 255, 1)',
    }),
  }),
  // Square
  'Kat. 2': new RegularShape({
    fill: new Fill({
      color: 'rgba(255, 255, 255, 1)',
    }),
    points: 4,
    radius: 6,
    angle: Math.PI / 4,
  }),
  // Triangle
  'Kat. 3': new RegularShape({
    fill: new Fill({
      color: 'rgba(255, 255, 255, 1)',
    }),
    points: 3,
    radius: 6,
    angle: 0,
  }),
  // Empty
  INFRA: new RegularShape({}),
  // Cross
  BP: new RegularShape({
    stroke: new Stroke({ color: 'rgba(255, 255, 255, 1)', width: 2 }),
    points: 4,
    radius: 4,
    radius2: 0,
    angle: 0,
  }),
  // Star
  AO: new RegularShape({
    fill: new Fill({
      color: 'rgba(255, 255, 255, 1)',
    }),
    points: 5,
    radius: 6,
    radius2: 3,
    angle: 0,
  }),
};

const getImmoStyle = (feat, isSelected, geometry) => {
  const { bahnhofkategorie, region, portfolio, team } = feat.getProperties();
  const cat = bahnhofkategorie || portfolio;
  const newStyle = [];

  if (isSelected) {
    newStyle.push(
      new Style({
        zIndex: 20,
        image: new Circle({
          radius: 15,
          fill: new Fill({
            color: 'rgba(0, 31, 135, 0.5)',
          }),
        }),
        geometry,
      }),
    );
  }

  if (immoColors[region] && immoColors[region][team] && immoShapes[cat]) {
    const color = immoColors[region][team];
    newStyle.push(
      new Style({
        zIndex: 10,
        image: new Circle({
          radius: 10,
          fill: new Fill({
            color,
          }),
        }),
        geometry,
      }),
    );
    newStyle.push(
      new Style({
        zIndex: 10,
        image: immoShapes[cat],
        geometry,
      }),
    );
  } else {
    newStyle.push(noDataStyle);
  }

  return newStyle;
};

const toExport = (baseLayers) => [
  {
    key: 'immobilien',
    name: 'Immobilien - Facility Management',
    allowTilePublication: REACT_APP_ENVIRONMENT === 'prod',
    baseLayers,
    uiSchema: uiSchemas.immobilien,
    search: 'default',
    listColumns: [
      'stationsbezeichnung',
      'bezeichnung',
      'region',
      'portfolio',
      'modified_at',
      'modified_by',
    ],
    styleFunction: (feature, isSelected) => {
      const coords = feature.get('geom_overwrite');

      const styles = [
        ...getImmoStyle(
          feature,
          isSelected,
          coords && coords !== '' && customFormats.coordinates.test(coords)
            ? new Point(coords.split(',').map((c) => parseFloat(c)))
            : feature.getGeometry(),
        ),
        noDataStyle,
      ];

      if (isSelected) {
        styles.unshift(
          new Style({
            zIndex: 20,
            image: new Circle({
              radius: 15,
              fill: new Fill({
                color: 'rgba(0, 31, 135, 0.5)',
              }),
            }),
          }),
        );
      }

      return styles;
    },
    oleConfig: {
      canAddGeometries: false,
      canEditGeometries: false,
    },
    copyFeatureProperties: (feature) => {
      const clone = feature.clone();
      clone.set('geom_overwrite', '');
      clone.set('bezeichnung', `${feature.get('bezeichnung')} - Kopie`);
      clone.set('id', null);
      return clone;
    },
    fetchFeatureOnFormOpen: true,
    getFeatureTitle: (feature) =>
      feature.get('bezeichnung') || feature.get('stationsbezeichnung') || '',
    filterConfig: [
      {
        type: 'select',
        field: 'region',
        label: 'Regionen',
        multiple: true,
        choices: {
          ROT: 'ROT',
          RME: 'RME',
          RWT: 'RWT',
        },
      },
      {
        type: 'select',
        field: 'portfolio',
        label: 'Portfolio',
        multiple: true,
        choices: {
          AO: 'AO',
          BHF: 'BHF',
          BP: 'BP',
          INFRA: 'INFRA',
        },
      },
      {
        type: 'select',
        field: 'bahnhofkategorie',
        label: 'Bahnhofkategorie',
        multiple: true,
        choices: {
          'Kat. 1': 'Kategorie 1',
          'Kat. 2': 'Kategorie 2',
          'Kat. 3': 'Kategorie 3',
        },
      },
      {
        type: 'select',
        field: 'leistungstypen_el',
        label: 'Leistungstypen (EL)',
        multiple: true,
        fetchChoices: () => {
          return fetch(`${REACT_APP_BACKEND_URL}/immobilien/leistungstypen`)
            .then((res) => res.json())
            .then((res) =>
              res.reduce((acc, lt) => {
                acc[lt.key] = `${lt.key} - ${lt.description}`;
                return acc;
              }, {}),
            );
        },
      },
      {
        type: 'select',
        field: 'leistungstypen_fl',
        label: 'Leistungstypen (FL)',
        multiple: true,
        fetchChoices: () => {
          return fetch(`${REACT_APP_BACKEND_URL}/immobilien/leistungstypen`)
            .then((res) => res.json())
            .then((res) =>
              res.reduce((acc, lt) => {
                acc[lt.key] = `${lt.key} - ${lt.description}`;
                return acc;
              }, {}),
            );
        },
      },
      {
        type: 'select',
        field: 'team',
        label: 'Team TFM/IFM',
        multiple: true,
        fetchChoices: () => {
          return fetch(`${REACT_APP_BACKEND_URL}/immobilien/team`)
            .then((res) => res.json())
            .then((res) =>
              res.reduce((acc, t) => {
                acc[t] = t;
                return acc;
              }, {}),
            );
        },
      },
      {
        type: 'select',
        field: 'team_reinigung',
        label: 'Team Reinigung',
        multiple: true,
        emptyChipLabel: '- Kein Team Reinigung -',
        fetchChoices: () => {
          return fetch(`${REACT_APP_BACKEND_URL}/immobilien/team_reinigung`)
            .then((res) => res.json())
            .then((res) => {
              const choices = res
                .filter((t) => t)
                .reduce((acc, t) => {
                  acc[t] = t;
                  return acc;
                }, {});
              return {
                '': '- Kein Team Reinigung -',
                ...choices,
              };
            });
        },
      },
    ],
  },
];

export default toExport;
