import React, { useState, useEffect } from 'react';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import InputLabel from '@mui/material/InputLabel';
import Checkbox from '@mui/material/Checkbox';
import Chip from '@mui/material/Chip';
import { makeStyles } from '@mui/styles';
import PropTypes from 'prop-types';
import AppPropTypes from '../../../model/app/propTypes';

const backendApiUrl = process.env.REACT_APP_BACKEND_URL;

const useStyles = makeStyles({
  root: {
    width: '100%',
  },
  chips: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  chip: {
    margin: 2,
    height: 20,
    lineHeight: 20,
  },
  menu: {
    maxHeight: '550px',
  },
  checkbox: {
    padding: '0 5px',
  },
});

const ServicesTypesField = ({ uiSchema, formData, onChange }) => {
  const classes = useStyles();
  const [serviceTypes, setServiceTypes] = useState([]);

  useEffect(() => {
    fetch(`${backendApiUrl}/immobilien/leistungstypen`)
      .then((data) => data.json())
      .then((data) => setServiceTypes(data));
  }, []);

  return (
    <FormControl className={classes.root}>
      <InputLabel>{uiSchema['ui:title']}</InputLabel>
      <Select
        className={classes.root}
        classes={{
          select: classes.select,
        }}
        renderValue={(selected) => (
          <div className={classes.chips}>
            {selected
              .sort((a, b) => (a.key < b.key ? -1 : 1))
              .map((sel) => (
                <Chip key={sel.key} label={sel.key} className={classes.chip} />
              ))}
          </div>
        )}
        MenuProps={{
          className: classes.menu,
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'left',
          },
          transformOrigin: {
            vertical: 'top',
            horizontal: 'left',
          },
        }}
        value={formData}
        multiple
      >
        {serviceTypes.map((st) => (
          <MenuItem key={st.key} value={st.key}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={formData.find((fd) => fd.key === st.key) || false}
                  className={classes.checkbox}
                  onChange={(evt) => {
                    if (evt.target.checked) {
                      onChange([...formData, st]);
                    } else {
                      onChange(formData.filter((fd) => fd.key !== st.key));
                    }
                  }}
                />
              }
              label={`${st.key} - ${st.description}`}
            />
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

ServicesTypesField.propTypes = {
  formData: PropTypes.oneOfType([
    PropTypes.shape({}),
    PropTypes.arrayOf(PropTypes.shape()),
  ]),
  uiSchema: AppPropTypes.uiSchema.isRequired,
  onChange: PropTypes.func.isRequired,
};

ServicesTypesField.defaultProps = {
  formData: [],
};

export default ServicesTypesField;
