import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { makeStyles } from '@mui/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { setDialogVisible } from '../../model/app/actions';

const propTypes = {
  title: PropTypes.element,
  body: PropTypes.element,
  footer: PropTypes.element,
  onClose: PropTypes.func,
};

const defaultProps = {
  title: null,
  body: null,
  footer: null,
  onClose: () => {},
};

const useStyles = makeStyles({
  closeButton: {
    '&.MuiIconButton-root': {
      position: 'absolute',
      right: 10,
      top: 5,
    },
  },
  footer: {
    '& .ms-dialog-footer-button': {
      margin: 10,
      border: '1px solid #eee',
      cursor: 'pointer',
    },
  },
  dialogContent: {
    padding: 0,
    margin: '10px 24px',
  },
});

function MesstischDialog(props) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { body, title, footer, onClose, ...otherProps } = props;

  const onCloseDialog = useCallback(() => {
    onClose();
    dispatch(setDialogVisible());
  }, [dispatch, onClose]);

  return (
    <Dialog
      open
      onClose={onCloseDialog}
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...otherProps}
    >
      <DialogTitle>
        {title}
        <IconButton
          className={classes.closeButton}
          aria-label="Schliessen"
          title="Schliessen"
          onClick={onCloseDialog}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent className={classes.dialogContent}>{body}</DialogContent>
      <DialogActions className={classes.footer}>{footer}</DialogActions>
    </Dialog>
  );
}

MesstischDialog.propTypes = propTypes;
MesstischDialog.defaultProps = defaultProps;

export default MesstischDialog;
