export const isNewFeature = (feature) => {
  // if feature id is not defined, the feature is a new feature.
  return !feature.get('id');
};

// Function to access feature property, even when nested.
export const getFeatureProperty = (feature, name) => {
  const column = name.split('.');
  if (column.length === 1) {
    return feature.get(name);
  }
  const [first, ...nestedParam] = column;
  let featureProp = feature.getProperties()[first];
  nestedParam.forEach((element, index, array) => {
    featureProp =
      index === array.length - 1
        ? featureProp[element]
        : featureProp.properties[element];
  });
  return featureProp;
};
