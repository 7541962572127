import React, { useEffect, useState, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import Input from '@mui/material/Input';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Checkbox from '@mui/material/Checkbox';
import { makeStyles } from '@mui/styles';
import Chip from '@mui/material/Chip';
import { Typography } from '@mui/material';
import { setFilters } from '../../model/app/actions';

const propTypes = {
  filterConfig: PropTypes.shape({
    // Type of the Field ('select').
    type: PropTypes.string.isRequired,
    // Filed used from the form.
    field: PropTypes.string.isRequired,
    // Label of the select Filter.
    label: PropTypes.string.isRequired,
    // values of the select Filter.
    choices: PropTypes.objectOf(
      PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    ),
    // Possiblity to select multiple values.
    multiple: PropTypes.bool,
    // function returning a promise resolving choices
    fetchChoices: PropTypes.func,
    // Label to show if the chip value is empty
    emptyChipLabel: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    // Use enumNames value to display input value.
    useEnumNames: PropTypes.bool,
  }).isRequired,
};

const useStyles = makeStyles(() => ({
  root: {
    width: '100%',
  },
  menu: {
    maxHeight: 350,
    '& li': {
      padding: '10px 8px',
    },
  },
  chips: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  chip: {
    '&.MuiChip-root': {
      height: 25,
      margin: 2,
    },
  },
  checkbox: {
    padding: '0 5px',
  },
}));

const SelectFilter = ({ filterConfig }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { filters } = useSelector((state) => state.app);
  const {
    label,
    choices,
    field,
    multiple,
    fetchChoices,
    emptyChipLabel,
    useEnumNames,
  } = filterConfig;
  const [filterChoices, setFilterChoices] = useState(choices || []);

  useEffect(() => {
    if (fetchChoices) {
      fetchChoices().then((c) => setFilterChoices(c));
    }
  }, [fetchChoices]);

  const renderValue = useCallback(
    (selected) => {
      if (useEnumNames) {
        return choices[selected];
      }
      return selected;
    },
    [useEnumNames, choices],
  );

  return (
    <FormControl classes={{ root: classes.root }}>
      <InputLabel id="ms-select-label" sx={{ left: -12, top: 4 }}>
        {label}
      </InputLabel>
      <Select
        MenuProps={{
          className: classes.menu,
          variant: 'menu',
        }}
        labelId="ms-select-label"
        id="ms-select"
        value={filters[field] || (multiple ? [] : '')}
        onChange={(e) => {
          // Avoid onChange to be trigerred twice.
          e.preventDefault();
          e.stopPropagation();
          dispatch(
            setFilters({
              ...filters,
              [field]: e.target.value,
            }),
          );
        }}
        multiple={multiple}
        input={<Input />}
        renderValue={(selected) => {
          if (multiple) {
            return (
              <div className={classes.chips}>
                {selected.map((value) => (
                  <Chip
                    key={value}
                    label={renderValue(value) || emptyChipLabel}
                    className={classes.chip}
                    onDelete={(e) => {
                      e.preventDefault();
                      e.stopPropagation();
                      dispatch(
                        setFilters({
                          ...filters,
                          [field]: filters[field].filter(
                            (val) => val !== value,
                          ),
                        }),
                      );
                    }}
                    onMouseDown={(e) => {
                      e.stopPropagation();
                    }}
                  />
                ))}
              </div>
            );
          }
          return renderValue(selected);
        }}
      >
        {Object.entries(filterChoices).map((entry) => {
          const [key, value] = entry;
          return (
            <MenuItem key={key} value={key} sx={{ paddingLeft: 0 }}>
              {multiple ? (
                <>
                  <Checkbox
                    className={classes.checkbox}
                    checked={(filters[field] || []).includes(key)}
                  />
                  <Typography>{key}</Typography>
                </>
              ) : (
                value
              )}
            </MenuItem>
          );
        })}
      </Select>
    </FormControl>
  );
};

SelectFilter.propTypes = propTypes;
export default SelectFilter;
