import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import Button from '@mui/material/Button';
import Dialog from '../Dialog';
import { setDialogVisible, deleteFeature } from '../../model/app/actions';

function DeleteDialog() {
  const selectedFeature = useSelector((state) => state.app.selectedFeature);
  const dispatch = useDispatch();
  const { t } = useTranslation();

  if (!selectedFeature) {
    return null;
  }

  return (
    <Dialog
      title={<b>{t('Achtung')}</b>}
      body={<div>{t('Möchten Sie wirklich den Datensatz löschen?')}</div>}
      footer={
        <>
          <Button
            className="ms-dialog-footer-button"
            onClick={() => {
              dispatch(deleteFeature(selectedFeature));
              dispatch(setDialogVisible());
            }}
          >
            {t('Anwenden')}
          </Button>
          <Button
            className="ms-dialog-footer-button"
            onClick={() => {
              dispatch(setDialogVisible());
            }}
          >
            {t('Abbrechen')}
          </Button>
        </>
      }
    />
  );
}

const memoized = React.memo(DeleteDialog);
memoized.NAME = 'delete';
export default memoized;
