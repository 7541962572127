import { Map as OLMap } from 'ol';
import { defaults as defaultInteractions } from 'ol/interaction';
import { SET_LAYERS, SET_CENTER, SET_RESOLUTION, SET_ZOOM } from './actions';

const initialState = {
  map: new OLMap({
    controls: [],
    interactions: defaultInteractions({
      altShiftDragRotate: false,
      pinchRotate: false,
    }),
  }),
  center: [903638.5484, 5898492.5987],
  zoom: 9,
};

export default function app(state = initialState, action) {
  switch (action.type) {
    case SET_LAYERS:
      return {
        ...state,
        layers: [...action.data],
      };
    case SET_CENTER:
      return {
        ...state,
        center: [...action.data],
      };
    case SET_RESOLUTION:
      return {
        ...state,
        resolution: action.data,
      };
    case SET_ZOOM:
      return {
        ...state,
        zoom: action.data,
      };
    default:
      return {
        ...state,
      };
  }
}
