import React from 'react';
import { styled } from '@mui/styles';
import LinearProgress, {
  linearProgressClasses,
} from '@mui/material/LinearProgress';

const BorderLinearProgress = styled(LinearProgress)(() => ({
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor: '#fbcccc',
  },
  [`& .${linearProgressClasses.bar}`]: {
    backgroundColor: '#eb0000',
  },
}));

const ProgressLine = () => (
  <BorderLinearProgress
    data-testid="cartaro-progress-line"
    thickness={5}
    sx={{ height: 2, width: '100%', zIndex: 5 }}
  />
);

export default ProgressLine;
