import React, { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { DataGrid } from '@mui/x-data-grid';
import Feature from 'ol/Feature';
import ProgressLine from '../ProgressLine';
import { setSelectedFeature, setBulkFeatures } from '../../model/app/actions';
import { isNewFeature } from '../../utils/featureUtils';

/**
 * From a given list of objects search for properties that all objects
 * have in common and return an object with these common properties.
 */
const getCommonPropsFromObjects = (propList) => {
  let commonProps = null;
  propList.forEach((props) => {
    if (!commonProps) {
      commonProps = { ...props };
    } else {
      const commonKeys = Object.keys({ ...props, ...commonProps });
      commonKeys.forEach((key) => {
        if (Array.isArray(props[key])) {
          // compare array properties
          const commonArrayProps = [];
          for (let i = 0; i < props[key].length; i += 1) {
            const sibling =
              commonProps[key] && commonProps[key].length > i
                ? commonProps[key][i]
                : {};
            commonArrayProps.push(
              getCommonPropsFromObjects([props[key][i], sibling]),
            );
          }
          commonProps[key] = commonArrayProps;
        } else if (typeof props[key] === 'object') {
          // Currently a simple comparision is enough.
          // You may later want to add a more sophisticated object comparision.
          if (JSON.stringify(props[key]) !== JSON.stringify(commonProps[key])) {
            // simple properties
            delete commonProps[key];
          }
        } else if (props[key] !== commonProps[key]) {
          delete commonProps[key];
        }
      });
    }
  });

  if (propList.find((p) => p.id)) {
    // generate a new id to ensure everything updates correctly
    commonProps.id = propList.map((p) => p.id).join();
  }

  return commonProps;
};

const ConfigurableList = () => {
  const dispatch = useDispatch();
  const schema = useSelector((state) => state.app.schema);
  const features = useSelector((state) => state.app.features);
  const routingFeatures = useSelector((state) => state.app.routingFeatures);
  const topic = useSelector((state) => state.app.topic);
  const isFeaturesLoading = useSelector((state) => state.app.isFeaturesLoading);
  const selectedFeature = useSelector((state) => state.app.selectedFeature);
  const formData = useSelector((state) => state.app.formData);
  const {
    listConfig: { getColumns, getRow, checkboxSelection },
  } = topic;
  const columns = getColumns(schema);

  // Clear bulk features on unmount
  useEffect(() => {
    return function cleanup() {
      dispatch(setBulkFeatures([]));
    };
  }, [dispatch]);

  const allFeatures = useMemo(
    () => [
      ...features,
      ...routingFeatures.filter(
        (feature) =>
          feature.get('graph') === 'osm' &&
          feature.getGeometry().getType() !== 'Point',
      ),
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [features, routingFeatures, formData],
  );

  const rows = useMemo(
    () =>
      allFeatures
        .filter((feature) => feature.get('id'))
        .map((feature) => ({
          ...getRow(feature, schema),
          id: feature.get('id'),
        })),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [getRow, allFeatures, selectedFeature, schema],
  );

  const rowSelectionModel = useMemo(() => {
    if (!selectedFeature) return undefined;
    if (checkboxSelection) {
      return (
        selectedFeature?.get('selectedFeaturesIds') || [
          selectedFeature.get('id'),
        ]
      );
    }
    return [selectedFeature.get('id')];
  }, [checkboxSelection, selectedFeature]);

  return (
    <>
      {isFeaturesLoading && <ProgressLine />}
      <DataGrid
        sx={{
          '& .MuiDataGrid-cell': {
            cursor: 'pointer',
          },
        }}
        checkboxSelection={checkboxSelection}
        disableRowSelectionOnClick
        onRowSelectionModelChange={(selectedFeaturesIds) => {
          const bulkFeats = allFeatures.filter((f) =>
            selectedFeaturesIds.includes(f.get('id')),
          );

          if (selectedFeature && isNewFeature(selectedFeature)) {
            return;
          }

          if (bulkFeats.length === 0) {
            dispatch(setSelectedFeature(null));
          } else if (bulkFeats.length === 1) {
            dispatch(setSelectedFeature(bulkFeats[0]));
          } else {
            const featureProps = bulkFeats.map((f) => f.getProperties());
            const commonProps = getCommonPropsFromObjects(featureProps);
            const combinedFeature = new Feature({
              ...commonProps,
              selectedFeaturesIds,
            });
            dispatch(setSelectedFeature(combinedFeature));
          }
          dispatch(setBulkFeatures(bulkFeats));
        }}
        onRowClick={({ row }) => {
          dispatch(setBulkFeatures([]));
          dispatch(
            setSelectedFeature(
              allFeatures.find((f) =>
                row.id ? f.get('id') === row.id : f.get('name') === row.name,
              ),
            ),
          );
        }}
        rows={rows}
        columns={columns}
        rowSelectionModel={rowSelectionModel || []}
      />
    </>
  );
};

export default React.memo(ConfigurableList);
