import React from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { Button, Typography } from '@mui/material';
import Dialog from '../Dialog';
import { setFilters } from '../../model/app/actions';

const propTypes = {
  onClose: PropTypes.func,
  onFilterReset: PropTypes.func,
};

const defaultProps = {
  onClose: () => {},
  onFilterReset: () => {},
};

function DuplicateWarnDialog({ onClose, onFilterReset }) {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  return (
    <Dialog
      title={<b>{t('Achtung')}</b>}
      onClose={() => onClose()}
      body={
        <div>
          <Typography>
            {t(
              'Für diesen Ort gibt es schon einen Stationseintrag. Möglicherweise wird die Station durch die aktuellen Filter-Einstellungen ausgeblendet.',
            )}
          </Typography>
          <br />
          <Typography>
            {t(
              'Entfernen Sie die Filter und selektieren Sie die Station erneut, um diese zu bearbeiten.',
            )}
          </Typography>
        </div>
      }
      footer={
        <>
          <Button
            className="ms-dialog-footer-button"
            onClick={() => {
              dispatch(setFilters());
              onFilterReset();
            }}
          >
            {t('Filter entfernen')}
          </Button>
          <Button className="ms-dialog-footer-button" onClick={() => onClose()}>
            {t('Abbrechen')}
          </Button>
        </>
      }
    />
  );
}

DuplicateWarnDialog.propTypes = propTypes;
DuplicateWarnDialog.defaultProps = defaultProps;

const memoized = React.memo(DuplicateWarnDialog);
memoized.NAME = 'duplicate';
export default memoized;
