import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@mui/styles';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import DragHandle from '@mui/icons-material/DragHandle';
import IconButton from '@mui/material/IconButton';
import { useDrag, useDrop } from 'react-dnd';
import Delete from '@mui/icons-material/Delete';

const propTypes = {
  id: PropTypes.string.isRequired,
  index: PropTypes.number.isRequired,
  moveCard: PropTypes.func,
  uiSchema: PropTypes.shape(),
  element: PropTypes.shape(),
};

const defaultProps = {
  moveCard: () => {},
  uiSchema: {},
  element: {},
};

const flex = { display: 'flex' };
const useStyles = makeStyles({
  item: {
    display: 'grid',
    gridTemplateColumns: '5fr 1fr',
    alignItems: 'center',
    border: ({ compact }) => (compact ? null : '1px solid #eee'),
    margin: ({ compact }) => (compact ? 0 : 10),
    padding: ({ compact }) => (compact ? 0 : '0 10px 10px 10px'),
    '& .MuiTextField-root': { minWidth: 280 },
  },
  itemButton: {
    '&.MuiIconButton-root': {
      margin: '13px 0 0 0',
      padding: 0,
    },
  },
  childrenContainer: flex,
  orderableBtnsContainer: {
    ...flex,
    alignItems: 'center',
    '& .MuiIconButton-root': {
      width: 35,
      height: 35,
    },
  },
  deleteBtnWrapper: {
    height: '100%',
    position: 'relative',
    '& .MuiIconButton-root': {
      position: 'absolute',
      top: '10%',
      left: 0,
      right: 0,
      marginLeft: 'auto',
      marginRight: 'auto',
      width: 35,
      height: 35,
    },
  },
});

function ItemFieldTemplate(props) {
  const { id, index, uiSchema, element, moveCard } = props;
  const classes = useStyles(props);

  const orderable =
    uiSchema && uiSchema['ui:options'] && uiSchema['ui:options'].orderable;

  const ref = useRef(null);

  const [
    { handlerId, insertBottom, insertTop, highlighted, hovered },
    drop,
  ] = useDrop({
    accept: 'card',
    collect(monitor) {
      let moveFromUp = false;
      let moveFromDown = false;
      if (ref.current && monitor.getItem() && monitor.getClientOffset()) {
        const dragIndex = monitor.getItem().index;
        const hoverIndex = index;
        // Don't replace items with themselves
        if (dragIndex !== hoverIndex) {
          // Determine rectangle on screen
          const hoverBoundingRect =
            ref.current && ref.current.getBoundingClientRect();
          // Determine mouse position
          const clientOffset = monitor.getClientOffset();

          // Get pixels to the top
          const hoverClientY = clientOffset.y - hoverBoundingRect.top;

          // Only perform the move when the mouse has crossed half of the items height
          // When dragging downwards, only move when the cursor is below 50%
          // When dragging upwards, only move when the cursor is above 50%
          // Dragging downwards
          if (dragIndex < hoverIndex && hoverClientY >= 0) {
            moveFromUp = true;
          }
          // Dragging upwards
          if (
            dragIndex > hoverIndex &&
            hoverClientY < hoverBoundingRect.bottom - hoverBoundingRect.top
          ) {
            moveFromDown = true;
          }
        }
      }
      return {
        handlerId: monitor.getHandlerId(),
        insertBottom: moveFromUp,
        insertTop: moveFromDown,
        highlighted: monitor.canDrop(),
        hovered: monitor.isOver(),
      };
    },
    drop(item) {
      if (!ref.current) {
        return;
      }
      const dragIndex = item.index;
      const hoverIndex = index;
      // Don't replace items with themselves
      if (dragIndex === hoverIndex) {
        return;
      }

      // Time to actually perform the action
      moveCard(dragIndex, hoverIndex);
      // Note: we're mutating the monitor item here!
      // Generally it's better to avoid mutations,
      // but it's good here for the sake of performance
      // to avoid expensive index searches.
      // eslint-disable-next-line no-param-reassign
      item.index = hoverIndex;
    },
  });

  const [{ isDragging }, drag] = useDrag({
    type: 'card',
    item: () => {
      return { id, index };
    },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });
  const opacity = isDragging ? 0 : 1;
  if (orderable) {
    drag(drop(ref));
  }

  return (
    <div
      className={
        classes.item +
        (highlighted && hovered && insertTop ? ' dnd-sortable-top' : '') +
        (highlighted && hovered && insertBottom ? ' dnd-sortable-bottom' : '')
      }
      key={element.key}
      ref={ref}
      style={{
        opacity,
      }}
      data-handler-id={handlerId}
    >
      <div className={classes.childrenContainer}>
        {element.children}
        {orderable ? (
          <div className={classes.orderableBtnsContainer}>
            <IconButton title="Element bewegen" className={classes.itemButton}>
              <DragHandle style={{ margin: 5 }} />
            </IconButton>
            <IconButton
              title="Element hoch"
              className={classes.itemButton}
              onClick={element.onReorderClick(element.index, element.index - 1)}
            >
              <ArrowUpwardIcon style={{ margin: 5 }} />
            </IconButton>
            <IconButton
              title="Element runter"
              className={classes.itemButton}
              onClick={element.onReorderClick(element.index, element.index + 1)}
            >
              <ArrowDownwardIcon style={{ margin: 5 }} />
            </IconButton>
          </div>
        ) : null}
      </div>
      <div className={classes.deleteBtnWrapper}>
        <IconButton
          title="Element entfernen"
          className={classes.itemButton}
          onClick={element.onDropIndexClick(element.index)}
        >
          <Delete style={{ margin: 5 }} />
        </IconButton>
      </div>
    </div>
  );
}

ItemFieldTemplate.propTypes = propTypes;
ItemFieldTemplate.defaultProps = defaultProps;

export default ItemFieldTemplate;
