/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import PropTypes from 'prop-types';
import { getDefaultRegistry } from '@rjsf/core';
import TemplateWrapper from '../TemplateWrapper';

const registry = getDefaultRegistry();
const RJSFObjectFieldTemplate = registry.templates.ObjectFieldTemplate;

const propTypes = {
  compact: PropTypes.bool,
  title: PropTypes.string,
};

const defaultProps = {
  compact: false,
  title: undefined,
};

function TitleField() {
  return null;
}

function ObjectFieldTemplate(props) {
  const { compact, title } = props;

  return (
    <TemplateWrapper title={title} compact={compact}>
      <RJSFObjectFieldTemplate {...props} TitleField={TitleField} />
    </TemplateWrapper>
  );
}

ObjectFieldTemplate.propTypes = propTypes;
ObjectFieldTemplate.defaultProps = defaultProps;

export default ObjectFieldTemplate;
