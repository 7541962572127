/* eslint-disable react/jsx-props-no-spreading */
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import SidebarMenuItem from '../../Sidebar/SidebarMenuItem';
import getCookie from '../../../utils/getCookie';

import { ReactComponent as NetworkPlans } from '../../../images/liniennetzplan.svg';
import {
  setIsPreviewLoading,
  setNotification,
  setCurrentPublication,
  setIsPreviewActive,
} from '../../../model/app/actions';

let abortController = new AbortController();

const CalculateNetworkPlan = (props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const sidebarOpen = useSelector((state) => state.app.sidebarOpen);
  const isPreviewLoading = useSelector((state) => state.app.isPreviewLoading);
  const backendApiUrl = useSelector((state) => state.app.backendApiUrl);

  useEffect(() => {
    return () => abortController.abort();
  }, []);

  return (
    <SidebarMenuItem
      title={
        isPreviewLoading
          ? t('Linen werden berechnet...')
          : t('Linien berechnen')
      }
      icon={<NetworkPlans focusable={false} />}
      sidebarOpen={sidebarOpen}
      disabled={isPreviewLoading}
      onClick={() => {
        abortController.abort();
        abortController = new AbortController();
        dispatch(setIsPreviewActive(false));
        dispatch(setIsPreviewLoading(true));
        return fetch(`${backendApiUrl}/-/gitlab/`, {
          method: 'POST',
          body: JSON.stringify({
            topic: 'direktverbindungen_preview',
            url: `${backendApiUrl
              .split('://')
              .pop()}/direktverbindungen/vtiles/`,
          }),
          credentials: 'include',
          headers: {
            'Content-Type': 'application/json',
            'X-CSRFToken': getCookie('csrftoken'),
          },
          signal: abortController.signal,
        })
          .then((response) => {
            const { status, statusText } = response;
            if (status === 200) {
              dispatch(
                setNotification({
                  type: 'info',
                  message: 'Linien werden berechnet...',
                  hideDuration: null,
                }),
              );
            } else {
              dispatch(
                setNotification({
                  type: 'error',
                  message: statusText,
                }),
              );
            }
            return response.json();
          })
          .then((data) => {
            dispatch(setCurrentPublication(data));
          })
          .catch(() => {
            dispatch(setIsPreviewLoading(false));
            dispatch(
              setNotification({
                type: 'error',
                message: 'Problem beim Berechnen der Linien',
              }),
            );
            return null;
          });
      }}
      {...props}
    />
  );
};

export default React.memo(CalculateNetworkPlan);
