import PermIdentity from '@mui/icons-material/PermIdentity';
import PropTypes from 'prop-types';
import {
  CircularProgress,
  IconButton,
  TextField,
  Tooltip,
} from '@mui/material';
import React, { useCallback, useState } from 'react';
import { useSelector } from 'react-redux';
import { Close } from '@mui/icons-material';
import Errors from '../Errors/Errors';

const propTypes = {
  onChange: PropTypes.func,
  endpoint: PropTypes.string,
  label: PropTypes.string,
  inputValue: PropTypes.string,
  isRequired: PropTypes.bool,
  errors: PropTypes.arrayOf(PropTypes.string),
  onClear: PropTypes.func,
};

function PersonSearch({
  onChange = () => {},
  endpoint = null,
  label = 'Personensuche',
  inputValue = '',
  isRequired = false,
  errors = [],
  onClear = null,
  ...otherProps
}) {
  const backendApiUrl = useSelector((state) => state.app.backendApiUrl);
  const [loading, setLoading] = useState(false);
  const onInputChange = useCallback(
    ({ target: { value } }) => {
      const abortController = new AbortController();
      if (value && value.length === 7) {
        const uri = `${endpoint || `${backendApiUrl}/person/person/`}${value}/`;
        setLoading(true);
        fetch(uri, {
          credentials: 'include',
          signal: abortController.signal,
        })
          .then((res) => res.json())
          .then((res) => {
            if (res.sbb_uid) {
              const val = `${res.first_name} ${res.last_name} (${res.sbb_uid})`;
              onChange({
                uri,
                label: val,
                uNummer: res.sbb_uid,
                firstName: res.first_name,
                lastName: res.last_name,
              });
            } else if (res.detail) {
              onChange({ label: value, error: res.detail });
            }
            setLoading(false);
          })
          .catch((err) => {
            onChange({ label: value, error: err });
            setLoading(false);
          });
      } else if (!value) {
        // Reset the status of the uri field so you can save.
        onChange({});
      } else {
        onChange({ label: value });
      }
      return () => {
        abortController.abort();
      };
    },
    [onChange, endpoint, backendApiUrl],
  );

  return (
    <>
      <TextField
        InputProps={{
          endAdornment: (
            <>
              {loading && <CircularProgress size={15} sx={{ mr: 1 }} />}
              {onClear && inputValue && !loading && (
                <IconButton
                  onClick={onClear}
                  title="Suchtext löschen"
                  sx={{ py: 0.5, px: 0.5, mr: 0.5 }}
                >
                  <Close fontSize="small" />
                </IconButton>
              )}
              <Tooltip
                title={
                  <span style={{ fontSize: '0.9rem', lineHeight: 'initial' }}>
                    Geben Sie eine SBB-Mitarbeiter-ID ein, um nach einer Person
                    zu suchen.
                  </span>
                }
                placement="left"
              >
                <PermIdentity />
              </Tooltip>
            </>
          ),
        }}
        label={label}
        value={inputValue}
        onChange={onInputChange}
        required={isRequired}
        error={!!errors.length}
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...otherProps}
      />
      <Errors errors={{ __errors: errors }} />
    </>
  );
}

PersonSearch.propTypes = propTypes;
export default PersonSearch;
