/* eslint-disable react/jsx-props-no-spreading */
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RoutingControl } from 'mobility-toolbox-js/ol';
import red from '@mui/material/colors/red';
import { createTheme, ThemeProvider } from '@mui/material';
import { makeStyles } from '@mui/styles';
import CircularProgress from '@mui/material/CircularProgress';
import WKTFormat from 'ol/format/WKT';
import MultiLineString from 'ol/geom/MultiLineString';
import PropTypes from 'prop-types';
import Form from '@rjsf/mui';
import validator from '@rjsf/validator-ajv8';
import theme from '../../../theme';
import RoutingStopsFinderWidget from './RoutingStopsFinderWidget';
import ArrayFieldTemplate from '../../ArrayFieldTemplate';
import ObjectFieldTemplate from '../../ObjectFieldTemplate';
import AppPropTypes from '../../../model/app/propTypes';
import { setRoutingFeatures } from '../../../model/app/actions';

const useStyles = makeStyles(() => ({
  form: {
    margin: '20px 0',
    '& fieldset': {
      padding: 0,
    },
    '& .MuiFormLabel-root': {
      left: -12,
    },
    '& .MuiInput-root': {
      maxHeight: 36,
    },
    '& .MuiAutocomplete-root': {
      paddingLeft: 0,
    },
  },
  error: {
    color: red[800],
    paddingTop: 10,
  },
  loading: {
    opacity: 0.5,
    pointerEvents: 'none',
    position: 'relative',
  },
  loader: {
    position: 'absolute',
    top: 'calc(50% - 60px)',
    left: 'calc(50% - 30px)',
  },
}));

export const graphs = [
  ['gen5', 5, 8],
  ['gen10', 8, 9],
  ['gen30', 9, 11],
  ['gen100', 11, 14],
  ['osm', 14, 99],
];

const format = new WKTFormat();
const formatOptions = { decimals: 4 };
const routingControl = new RoutingControl({
  apiKey: process.env.REACT_APP_API_KEY,
  element: document.createElement('span'),
  graphs,
  mot: 'rail',
  url: process.env.REACT_APP_ROUTING_API_URL,
});
const uiSchemaFieldHidden = {
  'ui:readonly': true,
  'ui:widget': 'hidden',
};

function getViaStationProp(prop, via) {
  if (typeof via !== 'object') {
    return '';
  }
  if (typeof via.name === 'object' && via.name[prop]) {
    return via.name[prop];
  }
  return via[prop] || '';
}

const RoutingField = (props) => {
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const classes = useStyles();
  const {
    formContext,
    formData,
    onChange,
    registry,
    schema,
    uiSchema,
    errorSchema,
    required,
  } = props;
  const { map } = formContext;
  const dispatch = useDispatch();
  const routingFeatures = useSelector((state) => state.app.routingFeatures);

  useEffect(() => {
    routingControl.graphsResolutions = RoutingControl.getGraphsResolutions(
      graphs,
      map,
    );
  }, [map]);

  useEffect(() => {
    const { start, vias, end } = formData;
    formData.vias = vias.sort((sa, sb) => sa.index - sb.index); // Ensure via sequence corresponds to the indexes coming from the backend
    const viaPoints = [];
    if (start && start.uid) {
      viaPoints.push(start.uid);
    }
    if (vias && vias.length) {
      viaPoints.push(...vias.map((s) => s.uid));
    }
    if (end && end.uid) {
      viaPoints.push(end.uid);
    }
  }, [formData]);
  if (!formData.vias) {
    formData.vias = [];
  }
  return (
    <div className={error || loading ? classes.loading : ''}>
      {loading && <CircularProgress className={classes.loader} />}
      <ThemeProvider theme={createTheme(theme)}>
        <Form
          validator={validator}
          liveValidate
          ErrorList={() => null}
          templates={{
            ArrayFieldTemplate: (templateProps) => (
              <ArrayFieldTemplate {...templateProps} compact />
            ),
            ObjectFieldTemplate: (templateProps) => (
              <ObjectFieldTemplate {...templateProps} compact />
            ),
          }}
          className={classes.form}
          fields={registry.fields}
          formData={formData}
          schema={schema}
          uiSchema={{
            start: {
              name: {
                'ui:title': 'Start',
                'ui:widget': 'RoutingStopsFinderWidget',
                'ui:required': required,
              },
              uid: uiSchemaFieldHidden,
              geom: uiSchemaFieldHidden,
            },
            end: {
              name: {
                'ui:title': 'Ende',
                'ui:widget': 'RoutingStopsFinderWidget',
                'ui:required': required,
              },
              uid: uiSchemaFieldHidden,
              geom: uiSchemaFieldHidden,
            },
            id: uiSchemaFieldHidden,
            vias: {
              items: uiSchema.vias && {
                ...uiSchema.vias.items,
                index: uiSchemaFieldHidden,
                uid: uiSchemaFieldHidden,
                name: {
                  'ui:widget': 'RoutingStopsFinderWidget',
                },
                geom: uiSchemaFieldHidden,
                'ui:order': [
                  'station',
                  ...Object.keys(
                    schema.properties.vias.items.properties,
                  ).filter((k) => k !== 'station'),
                ],
              },
              'ui:options': {
                'ui:addButtonLabel': 'Station hinzufügen',
                orderable: true,
              },
            },
            geom: uiSchemaFieldHidden,
            geom_gen5: uiSchemaFieldHidden,
            geom_gen10: uiSchemaFieldHidden,
            geom_gen30: uiSchemaFieldHidden,
            geom_gen100: uiSchemaFieldHidden,
            geom_gen150: uiSchemaFieldHidden,
            'ui:order': [
              'start',
              'vias',
              'end',
              ...Object.keys(schema.properties).filter(
                (k) => k !== 'start' && k !== 'vias' && k !== 'end',
              ),
            ],
          }}
          onChange={async (event) => {
            const data = event.formData;

            try {
              // name is a string if station is not modified by RoutingStationFinderWidget
              const startStation =
                typeof data.start.name === 'string'
                  ? data.start
                  : data.start.name;
              const endStation =
                typeof data.end.name === 'string' ? data.end : data.end.name;
              const viaStations = (data.vias || [])
                .map((s) => (typeof s.name === 'string' ? s : s.name))
                .filter((s) => s && s.uid);

              const newFormData = {
                id: data.id,
                start:
                  (startStation && {
                    name: startStation.name,
                    uid: startStation.uid,
                  }) ||
                  {},
                vias: (data.vias || []).map((via, index) => ({
                  ...via,
                  name: getViaStationProp('name', via),
                  uid: getViaStationProp('uid', via),
                  index: index + 1,
                })),
                end:
                  (endStation && {
                    name: endStation.name,
                    uid: endStation.uid,
                  }) ||
                  {},
              };

              if (startStation && endStation) {
                const viaPoints = [
                  startStation.uid,
                  ...viaStations.filter((s) => s.uid).map((s) => s.uid),
                  endStation.uid,
                ];

                setLoading(true);
                routingControl.reset();
                routingControl.viaPoints = viaPoints;
                await routingControl.drawRoute();
                const newFeatures = routingControl.routingLayer.olLayer
                  .getSource()
                  .getFeatures();
                for (let i = 0; i < newFeatures.length; i += 1) {
                  const feature = newFeatures[i];
                  const graph = feature.get('graph');
                  if (graph) {
                    const geomKey = graph === 'osm' ? 'geom' : `geom_${graph}`;
                    newFormData[geomKey] = `SRID=3857;${format.writeGeometry(
                      new MultiLineString([
                        feature.getGeometry().getCoordinates(),
                      ]),
                      formatOptions,
                    )}`;
                  }
                }
                // gen150 and gen100 geometries are the same
                newFormData.geom_gen150 = newFormData.geom_gen100;
                const rf = routingFeatures.find((f) => {
                  const route = f.get('route');
                  return route && data && route.id === data.id;
                });
                const routeProperties = rf ? rf.getProperties() : {};
                onChange(newFormData);
                dispatch(
                  setRoutingFeatures([
                    ...routingFeatures.filter((f) => {
                      const route = f.get('route');
                      return route && data && route.id !== data.id;
                    }),
                    ...newFeatures.map((f) => {
                      const viaPointIdx = f.get('viaPointIdx');
                      if (
                        viaPointIdx > 0 &&
                        viaPointIdx <= newFormData.vias.length
                      ) {
                        f.setProperties({
                          ...routeProperties,
                          route: newFormData,
                          ...newFormData.vias[viaPointIdx - 1],
                          ...f.getProperties(),
                        });
                      } else {
                        f.setProperties({
                          ...routeProperties,
                          route: newFormData,
                          ...f.getProperties(),
                        });
                      }
                      return f;
                    }),
                  ]),
                );
              } else {
                // If the user has chosen some station but not all we still update the formData to improve ux.
                onChange(newFormData);
              }
            } catch (err) {
              setError(err.message);
            }
            setLoading(false);
          }}
          widgets={{
            RoutingStopsFinderWidget: (widgetProps) => (
              <RoutingStopsFinderWidget {...widgetProps} formData={formData} />
            ),
          }}
          extraErrors={errorSchema}
        />
      </ThemeProvider>
      {error && (
        <div className={classes.error}>
          Beim Routing ist folgender Fehler aufgetreten: <pre>{error}</pre>
        </div>
      )}
    </div>
  );
};

RoutingField.propTypes = {
  formContext: AppPropTypes.formContext.isRequired,
  formData: PropTypes.shape(),
  onChange: PropTypes.func.isRequired,
  registry: PropTypes.shape(),
  schema: AppPropTypes.schema.isRequired,
  uiSchema: AppPropTypes.uiSchema.isRequired,
  errorSchema: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.sring),
    PropTypes.shape(),
  ]),
  required: PropTypes.bool,
};

RoutingField.defaultProps = {
  formData: {},
  registry: {},
  errorSchema: {},
  required: false,
};

export default RoutingField;
