import React, { useState, useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import EditLocation from '@mui/icons-material/EditLocation';
import ClearIcon from '@mui/icons-material/Clear';
import { TextField, IconButton, InputAdornment } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { unByKey } from 'ol/Observable';
import { fetchLine } from './utils';
import AppPropTypes from '../../../model/app/propTypes';

const useStyles = makeStyles({
  root: {
    display: 'flex',
  },
  input: {
    margin: 5,
  },
});

let fetchTimeout = null;
let fetchAbortController = new AbortController();

const SegmentationSegment = ({
  segment,
  formContext,
  onChange,
  onDelete,
  onFocus,
  multi,
  required,
}) => {
  const classes = useStyles();
  const [isClickActivated, setClickActivated] = useState(false);
  const { backendApiUrl, map } = formContext;

  // Update the form
  const updateLine = useCallback(
    (features) => {
      if (features.length) {
        const featureProps = features[0].getProperties();
        onChange({
          line_number: featureProps.line_number,
          km_start: featureProps.line_start,
          km_end: featureProps.line_end,
        });
      }
    },
    [onChange],
  );
  // on willUnmount
  useEffect(() => {
    return () => {
      window.clearTimeout(fetchTimeout);
      fetchAbortController.abort();
    };
  }, []);

  // Activate the selection of a line on the next click.
  useEffect(() => {
    let olKey = null;
    const abortController = new AbortController();
    if (isClickActivated) {
      olKey = map.once('singleclick', (evt) => {
        evt.stopPropagation();
        const { coordinate } = evt;
        fetchLine(backendApiUrl, null, coordinate, abortController).then(
          (features) => {
            setClickActivated(false);
            updateLine(features);
          },
        );
      });
    }
    return () => {
      unByKey(olKey);
      abortController.abort();
    };
  }, [backendApiUrl, isClickActivated, map, updateLine]);

  const onLineInputChange = useCallback(
    ({ target: { value } }) => {
      onChange({ ...segment, line_number: value });
      if (value) {
        fetchAbortController.abort();
        window.clearTimeout(fetchTimeout);
        fetchAbortController = new AbortController();
        fetchTimeout = window.setTimeout(() => {
          fetchLine(backendApiUrl, value, null, fetchAbortController).then(
            (features) => {
              if (features.length) {
                updateLine(features);
              }
            },
          );
        }, 500);
      }
    },
    [backendApiUrl, onChange, segment, updateLine],
  );

  const onFocuss = useCallback(() => {
    onFocus(segment);
  }, [onFocus, segment]);

  const onBlur = useCallback(() => {
    onFocus(null);
  }, [onFocus]);

  return (
    <div className={classes.root}>
      <TextField
        type="number"
        label="Linie"
        className={classes.input}
        value={segment.line_number || ''}
        onFocus={onFocuss}
        onBlur={onBlur}
        onChange={onLineInputChange}
        required={required}
        error={!(parseFloat(segment.line_number) >= 0)}
        helperText={
          !(parseFloat(segment.line_number) >= 0) && 'is a required property'
        }
        InputProps={{
          endAdornment: (
            <InputAdornment
              onClick={() => setClickActivated(!isClickActivated)}
              position="end"
            >
              <IconButton>
                <EditLocation
                  style={isClickActivated ? { color: 'red' } : {}}
                />
              </IconButton>
            </InputAdornment>
          ),
        }}
      />
      <TextField
        type="number"
        label="km. Start"
        className={classes.input}
        required={required}
        value={
          segment.km_start === null || segment.km_start === undefined
            ? ''
            : segment.km_start
        }
        onFocus={onFocuss}
        onBlur={onBlur}
        onChange={(e) => {
          onChange({ ...segment, km_start: parseFloat(e.target.value) || 0 });
        }}
      />
      <TextField
        type="number"
        label="km. Ende"
        className={classes.input}
        required={required}
        value={
          segment.km_end === null || segment.km_end === undefined
            ? ''
            : segment.km_end
        }
        onFocus={onFocuss}
        onBlur={onBlur}
        onChange={(e) => {
          onChange({ ...segment, km_end: parseFloat(e.target.value) || 0 });
        }}
      />
      {multi && (
        <IconButton onClick={() => onDelete(segment)}>
          <ClearIcon />
        </IconButton>
      )}
    </div>
  );
};

SegmentationSegment.propTypes = {
  segment: AppPropTypes.lineSegment.isRequired,
  formContext: AppPropTypes.formContext.isRequired,
  onChange: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  onFocus: PropTypes.func.isRequired,
  multi: PropTypes.bool.isRequired,
  required: PropTypes.bool.isRequired,
};

export default React.memo(SegmentationSegment);
