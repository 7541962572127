import { GeoJSON } from 'ol/format';

const fetchStops = (val, apiKey, abortController, otherParamsString) => {
  return fetch(
    `https://api.geops.io/stops/v1/?key=${apiKey}&q=${encodeURIComponent(
      val,
    )}&pref_agencies=sbb${otherParamsString || ''}`,
    {
      signal: abortController?.signal,
    },
  )
    .then((res) => {
      if (!res.ok) {
        return res.json().then((data) => {
          throw data;
        });
      }
      return res.json().catch(() => {
        return Promise.resolve({ type: 'FeatureCollection', features: [] });
      });
    })
    .then((geojson) => {
      return new GeoJSON().readFeatures(geojson);
    });
};

export default fetchStops;
