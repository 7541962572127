import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { isValid } from 'date-fns';
import { makeStyles } from '@mui/styles';
import { useTranslation } from 'react-i18next';
import Button from '@mui/material/Button';
import TimeFilterPicker from './TimeFilterPicker';
import { setFilters } from '../../model/app/actions';

const useStyles = makeStyles(() => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
  },
  bottomButton: {
    textAlign: 'center',
  },
  resetButton: {
    border: '1px solid #eee',
    margin: '20px 0 20px auto',
  },
}));

let timeout;

const onChange = (value, dispatch, timeArray, filterField, filters) => {
  if (value && isValid(value)) {
    /* Dispatch time filter if date is valid */
    clearTimeout(timeout);
    dispatch(
      setFilters({
        ...filters,
        [filterField]: new Date(value.setHours(...timeArray)).toISOString(),
      }),
    );
  } else {
    /* If date is not valid, dispatch null to remove filter.
     * Use timeout to avoid constant filter requests in keypress.
     */
    clearTimeout(timeout);
    timeout = setTimeout(() => {
      dispatch(
        setFilters({
          ...filters,
          [filterField]: null,
        }),
      );
    }, 500);
  }
};

const onClear = (dispatch, filterField, filters) => {
  dispatch(
    setFilters({
      ...filters,
      [filterField]: null,
    }),
  );
};

const TimeFilter = ({ filterConfig }) => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const { t } = useTranslation();
  const { filters } = useSelector((state) => state.app);

  return (
    <div className={classes.container}>
      <TimeFilterPicker
        label={filterConfig.fromField.label}
        value={
          filters[filterConfig.fromField.name] &&
          new Date(filters[filterConfig.fromField.name])
        }
        onChange={(value) => {
          onChange(
            value,
            dispatch,
            [0, 0, 0, 0],
            filterConfig.fromField.name,
            filters,
          );
        }}
        onClear={() => onClear(dispatch, filterConfig.fromField.name, filters)}
      />

      <TimeFilterPicker
        label={filterConfig.toField.label}
        value={
          filters[filterConfig.toField.name] &&
          new Date(filters[filterConfig.toField.name])
        }
        onChange={(value) =>
          onChange(
            value,
            dispatch,
            [23, 59, 59, 0],
            filterConfig.toField.name,
            filters,
          )
        }
        onClear={() => onClear(dispatch, filterConfig.toField.name, filters)}
      />

      <Button
        className={classes.resetButton}
        disabled={
          !filters[filterConfig.fromField.name] &&
          !filters[filterConfig.toField.name]
        }
        onClick={() => {
          dispatch(
            setFilters({
              ...filters,
              [filterConfig.fromField.name]: null,
              [filterConfig.toField.name]: null,
            }),
          );
        }}
      >
        {t('Zurücksetzen')}
      </Button>
    </div>
  );
};

TimeFilter.propTypes = {
  filterConfig: PropTypes.shape({
    label: PropTypes.string,
    fromField: PropTypes.shape({
      label: PropTypes.string,
      name: PropTypes.string,
    }),
    toField: PropTypes.shape({
      label: PropTypes.string,
      name: PropTypes.string,
    }),
  }).isRequired,
};

export default TimeFilter;
