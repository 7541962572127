import React, { useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import Add from '@mui/icons-material/Add';
import Button from '@mui/material/Button';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { useSelector } from 'react-redux';
import ItemFieldTemplate from '../ItemFieldTemplate';
import TemplateWrapper from '../TemplateWrapper';
import DisabledWrapper from '../DisabledWrapper';

const propTypes = {
  compact: PropTypes.bool,
  title: PropTypes.string,
  items: PropTypes.arrayOf(PropTypes.shape()),
  canAdd: PropTypes.bool,
  onAddClick: PropTypes.func,
  uiSchema: PropTypes.shape(),
  idSchema: PropTypes.shape(),
};

const defaultProps = {
  compact: false,
  title: undefined,
  items: [],
  canAdd: true,
  onAddClick: () => {},
  uiSchema: {},
  idSchema: {},
};

function ArrayFieldTemplate(props) {
  const {
    compact,
    title,
    items,
    canAdd,
    onAddClick,
    uiSchema,
    idSchema,
  } = props;
  const options = useMemo(() => uiSchema?.['ui:options'] || {}, [uiSchema]);
  const toggleOptions = options['ui:toggleOptions'];
  const toggleCondition = toggleOptions?.['ui:condition'];
  const bulkFeatures = useSelector((state) => state.app.bulkFeatures);
  const selectedFeature = useSelector((state) => state.app.selectedFeature);
  const isTogglableField = toggleCondition
    ? toggleCondition(bulkFeatures || [selectedFeature])
    : !!toggleOptions;

  const moveCard = useCallback(
    (dragIndex, hoverIndex) => {
      const element = items[dragIndex];
      element.onReorderClick(dragIndex, hoverIndex)();
    },
    [items],
  );

  const component = useMemo(() => {
    return (
      <DndProvider backend={HTML5Backend}>
        <TemplateWrapper title={title} compact={compact}>
          {items &&
            items.map((element, index) => {
              return (
                <ItemFieldTemplate
                  key={element.key}
                  id={element.key}
                  index={index}
                  element={element}
                  // eslint-disable-next-line react/jsx-props-no-spreading
                  {...props}
                  moveCard={moveCard}
                />
              );
            })}
          {canAdd && (
            <Button
              title={options?.['ui:addButtonLabel'] || 'Element hinzufügen'}
              style={{ margin: 10, border: '1px solid #eee' }}
              onClick={onAddClick}
            >
              <Add size={30} style={{ margin: 5 }} />
              {options?.['ui:addButtonLabel'] || 'Element hinzufügen'}
            </Button>
          )}
        </TemplateWrapper>
      </DndProvider>
    );
  }, [canAdd, compact, items, moveCard, onAddClick, props, title, options]);

  return isTogglableField ? (
    <DisabledWrapper
      options={options['ui:toggleOptions']}
      field={idSchema.$id.split('_')[1]}
      title={title}
    >
      {component}
    </DisabledWrapper>
  ) : (
    component
  );
}

ArrayFieldTemplate.propTypes = propTypes;
ArrayFieldTemplate.defaultProps = defaultProps;

export default ArrayFieldTemplate;
