/* eslint-disable react/jsx-props-no-spreading */
import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@mui/styles';
import {
  Accordion as MuiAccordion,
  AccordionDetails,
  AccordionSummary as MuiAccordionSummary,
  styled,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

const barrierefreiRegex = /^barrierefreie_touristische_angebote-(0|[1-9]\d*)$/;
const routeRegex = /^(Route|Vias|Start|End)$/;

const NOT_COLLAPSIBLE_REGEXES = [barrierefreiRegex, routeRegex]; // Prevent template with title xyz to be collapsible

const Accordion = styled((props) => (
  // eslint-disable-next-line react/jsx-props-no-spreading
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(() => ({
  border: `1px solid #eee`,
  '&:not(:last-child)': {
    borderBottom: 0,
  },
  '&:before': {
    display: 'none',
  },
}));

const AccordionSummary = styled((props) => (
  <MuiAccordionSummary expandIcon={<ExpandMoreIcon />} {...props} />
))(({ theme }) => ({
  backgroundColor: '#eee',
  '& .MuiAccordionSummary-content': {
    marginLeft: theme.spacing(1),
  },
}));

const propTypes = {
  title: PropTypes.string,
  compact: PropTypes.bool,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
};

const defaultProps = {
  title: undefined,
  compact: false,
};

const useStyles = makeStyles({
  root: {
    minWidth: 350,
    '& .MuiGrid-item': {
      marginBottom: '0 !important', // hard coded in rjsf
      padding: 8,
    },
    '& >fieldset': {
      border: 'none',
      '& .MuiBox-root': {
        display: 'none',
      },
    },
  },
  title: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    backgroundColor: '#eee',
    color: '#000000',
    cursor: 'pointer',
    width: '100%',
    padding: 10,
  },
  sticky: {
    position: 'sticky',
    top: 0,
    zIndex: 1,
  },
  nested: {
    border: '1px solid #eee',
    padding: '0 10px 10px 10px',
  },
});

function TemplateWrapper(props) {
  const { title, children, compact } = props;
  const { t } = useTranslation();
  const classes = useStyles(props);

  // rjsf v5 generates title automatically from field name, so we need
  // to exclude it here when we don't want a collapsible
  const isCollapsible = useMemo(() => {
    return NOT_COLLAPSIBLE_REGEXES.some((regex) => regex.test(title));
  }, [title]);

  return (
    <div className={classes.root}>
      {title && !isCollapsible ? (
        <Accordion defaultExpanded>
          {!compact && title && <AccordionSummary>{t(title)}</AccordionSummary>}
          <AccordionDetails>{children}</AccordionDetails>
        </Accordion>
      ) : (
        children
      )}
    </div>
  );
}

TemplateWrapper.propTypes = propTypes;
TemplateWrapper.defaultProps = defaultProps;

export default TemplateWrapper;
