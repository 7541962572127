import React, { useEffect, useMemo, useCallback } from 'react';
import PropTypes from 'prop-types';
import AddIcon from '@mui/icons-material/Add';
import { InputLabel, IconButton } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useTranslation } from 'react-i18next';
import VectorLayer from 'ol/layer/Vector';
import VectorSource from 'ol/source/Vector';
import { Style, Stroke, Fill, Text, RegularShape } from 'ol/style';
import Feature from 'ol/Feature';
import Point from 'ol/geom/Point';
import ModifyInteraction from 'ol/interaction/Modify';
import { unByKey } from 'ol/Observable';
import SegmentationSegment from './SegmentationSegment';
import { fetchLine, fetchLinesFromConfig, fetchKilometration } from './utils';
import AppPropTypes from '../../../model/app/propTypes';

const useStyles = makeStyles(() => ({
  inputLabel: {
    '&.MuiInputLabel-root': {
      position: 'relative',
      left: -15,
      top: 10,
    },
  },
}));

const propTypes = {
  formContext: AppPropTypes.formContext.isRequired,
  formData: PropTypes.oneOfType([
    PropTypes.arrayOf(AppPropTypes.lineSegment),
    AppPropTypes.lineSegment,
  ]),
  onChange: PropTypes.func.isRequired,
  required: PropTypes.bool,
  rawErrors: PropTypes.arrayOf(PropTypes.shape()),
};

const defaultProps = {
  formData: [{ line_number: '', km_start: '', km_end: '' }],
  required: false,
  rawErrors: [],
};

const segmentLayer = new VectorLayer({
  source: new VectorSource(),
  style: (feature) => [
    new Style({
      stroke: new Stroke({
        width: feature.get('isSelected') ? 7 : 0,
        color: [0, 0, 0],
        lineDash: [5, 10],
      }),
    }),
    new Style({
      stroke: new Stroke({
        width: 3,
        color: '#ff4444',
        lineDash: [5, 10],
      }),
    }),
  ],
});

const segmentPointLayer = new VectorLayer({
  source: new VectorSource(),
  style: (feature) => [
    new Style({
      image: new RegularShape({
        stroke: new Stroke({ width: 2, color: '#ff4444' }),
        points: 4,
        radius: 10,
        radius2: 0,
        angle: Math.PI / 4,
      }),
      text: new Text({
        font: '12px Arial',
        stroke: new Stroke({ color: 'white', width: 3 }),
        fill: new Fill({ color: 'black' }),
        text: feature.get('is_first_line') && `${feature.get('line_start')}`,
        offsetX: 25,
      }),
    }),
    new Style({
      text: new Text({
        font: '12px Arial',
        stroke: new Stroke({ color: 'white', width: 3 }),
        fill: new Fill({ color: 'black' }),
        text: feature.get('is_last_line') && `${feature.get('line_end')}`,
        offsetX: 25,
      }),
      image: new RegularShape({
        stroke: new Stroke({ width: 2, color: '#ff4444' }),
        points: 4,
        radius: 10,
        radius2: 0,
        angle: Math.PI / 4,
      }),
    }),
  ],
});

const modifyInteraction = new ModifyInteraction({
  source: segmentPointLayer.getSource(),
});

const updateSegmentsFromFeature = (
  segments,
  feature,
  backendApiUrl,
  abortController = {},
) => {
  const coord = feature.getGeometry().getCoordinates();
  const line = feature.get('line_number');

  return fetchKilometration(backendApiUrl, line, coord, abortController).then(
    (km) => {
      const newSegment = segments.find((s) => s.line_number === line);
      if (feature.get('is_first_line')) {
        newSegment.km_start = km;
      } else {
        newSegment.km_end = km;
      }
      return [...segments.filter((s) => s.line_number !== line), newSegment];
    },
  );
};

let changeTimeout = null;

const SegmentationField = ({
  formContext,
  formData,
  onChange,
  required,
  rawErrors,
}) => {
  const { map, backendApiUrl, selectedFeature } = formContext;
  const { t } = useTranslation();
  const classes = useStyles();

  // Define if formData use one segment (a js object) or  multiple segments (an array of js object)
  const multi = useMemo(() => {
    return Array.isArray(formData);
  }, [formData]);

  // Update segments (an array of segement when formData has changed.
  const segments = useMemo(() => {
    return multi ? formData : [formData];
  }, [formData, multi]);

  // Update formData
  const onSegmentChange = useCallback(
    (segts) => {
      onChange(multi ? segts : segts[0]);
    },
    [multi, onChange],
  );

  // Listen to feature changes
  useEffect(() => {
    const abortController = new AbortController();
    const changeKey = segmentPointLayer
      .getSource()
      .on('changefeature', ({ feature }) => {
        window.clearTimeout(changeTimeout);
        changeTimeout = window.setTimeout(() => {
          updateSegmentsFromFeature(
            segments,
            feature,
            backendApiUrl,
            abortController,
          ).then((seg) => onSegmentChange(seg));
        }, 1000);
      });

    return () => {
      abortController.abort();
      window.clearTimeout(changeTimeout);
      unByKey(changeKey);
    };
  }, [backendApiUrl, onSegmentChange, segments]);

  // init layer and interactions
  useEffect(() => {
    map.addLayer(segmentLayer);
    map.addLayer(segmentPointLayer);
    map.addInteraction(modifyInteraction);
    return () => {
      map.removeInteraction(modifyInteraction);
      map.removeLayer(segmentPointLayer);
      map.removeLayer(segmentLayer);
    };
  }, [map]);

  // add features
  useEffect(() => {
    let fetchTimeout = null;
    const abortController = new AbortController();
    const segmentsToFetch = segments.filter((seg) => seg.line_number);
    if (segmentsToFetch.length > 0) {
      fetchTimeout = window.setTimeout(() => {
        fetchLinesFromConfig(backendApiUrl, segments, abortController).then(
          (features) => {
            segmentLayer.getSource().clear();
            segmentLayer.getSource().addFeatures(features);

            const pointFeatures = features
              .filter((f) => f.get('is_first_line') || f.get('is_last_line'))
              .map((f) => {
                return new Feature({
                  ...f.getProperties(),
                  geometry: new Point(
                    f.get('is_first_line')
                      ? f.getGeometry().getFirstCoordinate()
                      : f.getGeometry().getLastCoordinate(),
                  ),
                });
              });

            segmentPointLayer.getSource().clear();
            segmentPointLayer.getSource().addFeatures(pointFeatures);
          },
        );
      }, 500);
    }

    return () => {
      window.clearTimeout(fetchTimeout);
      abortController.abort();
    };
  }, [segments, backendApiUrl, multi]);

  // if a new feature is added
  useEffect(() => {
    const abortController = new AbortController();
    if (
      selectedFeature.getGeometry() &&
      !selectedFeature.get('id') &&
      typeof formData.line_number === 'undefined'
    ) {
      const coord = selectedFeature.getGeometry().getCoordinates();
      fetchLine(backendApiUrl, null, coord, abortController).then(
        (features) => {
          if (features.length) {
            const featureProps = features[0].getProperties();
            const newSegment = {
              line_number: featureProps.line_number,
              km_start: featureProps.line_start,
              km_end: featureProps.line_end,
            };
            onSegmentChange([newSegment]);
          }
        },
      );
    }
    return () => {
      abortController.abort();
    };
    // For when a different feature is selected while form is still open
  }, [selectedFeature, formData, backendApiUrl, onSegmentChange]);

  if (!selectedFeature.getGeometry()) {
    return null;
  }

  // Required property message and style is bad handled in custom field so we develop a similar behavior
  const helperText =
    required &&
    (!formData || !(parseFloat(formData.line_number) >= 0)) &&
    'is a required property';
  const hasError = !!helperText || !!rawErrors.length;

  return (
    <div>
      <InputLabel
        className={classes.inputLabel}
        shrink
        required={required}
        error={hasError}
      >
        {t('Linien-Segment')}
      </InputLabel>
      {segments.map((segment, i) => (
        <SegmentationSegment
          key={segment.id || `new-item-${i}`}
          segment={segment}
          formContext={formContext}
          onChange={(seg) => {
            const newSegments = [...segments];
            newSegments[i] = seg;
            onSegmentChange(newSegments);
          }}
          onDelete={(seg) => {
            const newSegments = segments.filter((s) => s !== seg);
            onSegmentChange(newSegments);
          }}
          onFocus={(seg) => {
            segmentLayer
              .getSource()
              .getFeatures()
              .forEach((feature) => {
                feature.set(
                  'isSelected',
                  feature.get('line_number') === (seg || {}).line_number,
                );
              });
          }}
          multi={segments.length > 1}
          required={required}
          error={hasError}
        />
      ))}
      {multi && (
        <IconButton onClick={() => onSegmentChange([...segments, {}])}>
          <AddIcon />
        </IconButton>
      )}
    </div>
  );
};

SegmentationField.propTypes = propTypes;
SegmentationField.defaultProps = defaultProps;

export default React.memo(SegmentationField);
