import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles({
  textField: {},
  inputRoot: {
    marginTop: 10,
  },
});

const VelovermietungField = ({ formData, onChange }) => {
  const classes = useStyles();
  const [items, setItems] = useState([]);
  const [selectedVelomietung, setSelectedVelomietung] = useState('');

  const findFeature = (data, features) => {
    const found = features.find(
      (f) =>
        f.properties.url_alias === data[0] &&
        f.properties.url_identifier === data[1],
    );
    return found || '';
  };

  useEffect(() => {
    fetch(process.env.REACT_APP_VELO_SERVICE_URL)
      .then((data) => data.json())
      .then((res) => {
        setItems(res.features);
        const selectedFeat = findFeature(formData, res.features);
        setSelectedVelomietung(selectedFeat);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (items.length) {
      const selectedFeat = findFeature(formData, items);
      setSelectedVelomietung(selectedFeat);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formData]);

  return (
    <Autocomplete
      options={['', ...items]}
      value={selectedVelomietung}
      getOptionLabel={(feat) =>
        feat
          ? `Velovermietung ${feat.properties.station_name}`
          : 'Keine Verknüpfung zum IABP'
      }
      onChange={(evt, feature) => {
        if (feature) {
          const {
            url_alias: urlAlias,
            url_identifier: urlIdentifier,
          } = feature.properties;
          const newFormData = [urlAlias, urlIdentifier];
          onChange(newFormData);
          const selectedFeat = findFeature(newFormData, items);
          setSelectedVelomietung(selectedFeat);
        } else {
          onChange(['', '']);
          setSelectedVelomietung('');
        }
      }}
      renderInput={(params) => (
        <TextField
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...params}
          className={classes.textField}
          InputProps={{
            ...params.InputProps,
            className: classes.inputRoot,
          }}
        />
      )}
    />
  );
};

VelovermietungField.propTypes = {
  formData: PropTypes.arrayOf(PropTypes.string).isRequired,
  onChange: PropTypes.func.isRequired,
};

export default VelovermietungField;
