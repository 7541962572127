import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import Button from '@mui/material/Button';
import Dialog from '../Dialog';

const propTypes = {
  onSave: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  onNoSave: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  hasFormErrors: PropTypes.bool.isRequired,
};

function CancelDialog({ onClose, onSave, onNoSave, onCancel, hasFormErrors }) {
  const { t } = useTranslation();
  return (
    <Dialog
      title={<div>{t('Achtung')}</div>}
      onClose={onClose}
      body={
        <div>
          {t(
            'Die Änderungen gehen verloren, wenn Sie sie nicht speichern. Was möchten Sie machen?',
          )}
          {hasFormErrors && (
            <p style={{ color: '#c00' }}>{t('Das Formular enthält Fehler.')}</p>
          )}
        </div>
      }
      footer={
        <>
          <Button
            disabled={hasFormErrors}
            className="ms-dialog-footer-button"
            onClick={onSave}
          >
            {t('Speichern')}
          </Button>
          <Button className="ms-dialog-footer-button" onClick={onNoSave}>
            {t('Nicht speichern')}
          </Button>
          <Button className="ms-dialog-footer-button" onClick={onCancel}>
            {t('Abbrechen')}
          </Button>
        </>
      }
    />
  );
}

CancelDialog.propTypes = propTypes;
export default CancelDialog;
