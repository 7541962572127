/* eslint-disable no-console */
import GeoJSONFormat from 'ol/format/GeoJSON';
import getCookie from '../../../utils/getCookie';

const format = new GeoJSONFormat();

export const fetchLine = (
  backendApiUrl,
  number,
  coord,
  abortController = {},
) => {
  if (!number && !coord) {
    return Promise.resolve([]);
  }

  let url = `${backendApiUrl}/lines/`;
  url += number
    ? `from_number?line_number=${number}`
    : `from_coordinate?coord=${coord.join(',')}`;

  return fetch(url, {
    method: 'GET',
    credentials: 'include',
    headers: {
      'X-CSRFToken': getCookie('csrftoken'),
    },
    signal: abortController.signal,
  })
    .then((data) => data.json())
    .then((data) => format.readFeatures(data))
    .catch((err) => {
      console.error('fetchLine ignore error', err);
      return [];
    });
};

export const fetchLinesFromConfig = (
  backendApiUrl,
  segments,
  abortController = {},
) => {
  const url = `${backendApiUrl}/lines/from_config/`;
  return fetch(url, {
    method: 'POST',
    credentials: 'include',
    headers: {
      'Content-Type': 'application/json',
      'X-CSRFToken': getCookie('csrftoken'),
    },
    signal: abortController.signal,
    body: JSON.stringify(segments),
  })
    .then((data) => data.json())
    .then((data) => format.readFeatures(data))
    .catch((err) => {
      console.error('fetchLinesFromConfig ignore error', err);
      return [];
    });
};

export const fetchKilometration = (
  backendApiUrl,
  line,
  coord,
  abortController = {},
) => {
  let url = `${backendApiUrl}/lines/kilometration`;
  url += `?line_number=${line}&coord=${coord}`;
  return fetch(url, { signal: abortController.signal })
    .then((data) => data.json())
    .then((data) => data.kilometration)
    .catch((err) => {
      console.error('fetchKilometration ignore error', err);
      return null;
    });
};
