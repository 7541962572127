import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import FormControlLabel from '@mui/material/FormControlLabel';

import Toggler from '../Toggler';
import {
  setIsPreviewActive,
  setSelectedFeature,
  setListVisible,
} from '../../model/app/actions';

const PreviewSwitch = ({ enabled, ...props }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const isPreviewActive = useSelector((state) => state.app.isPreviewActive);
  const isPreviewLoading = useSelector((state) => state.app.isPreviewLoading);
  const isFormUnsaved = useSelector((state) => state.app.isFormUnsaved);

  return (
    <FormControlLabel
      label={t('Vorschau anzeigen')}
      checked={isPreviewActive}
      disabled={!enabled || isPreviewLoading}
      control={
        <Toggler
          data-testid="preview-toggler"
          onChange={() => {
            dispatch(setIsPreviewActive(!isPreviewActive));
            if (!isFormUnsaved) {
              dispatch(setSelectedFeature());
              dispatch(setListVisible(false));
            }
          }}
        />
      }
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...props}
    />
  );
};

PreviewSwitch.propTypes = {
  enabled: PropTypes.bool.isRequired,
};

export default PreviewSwitch;
