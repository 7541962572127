import { Style, Circle, Fill, Stroke } from 'ol/style';
import uiSchemas from '../uiSchemas';
import { toDateString } from '../utils';

const { REACT_APP_ENVIRONMENT } = process.env;

// Override the type in the photo schema to allow null values
// See https://rjsf-team.github.io/react-jsonschema-form/docs/json-schema/single#nullable-types
const fotoSchemaFields = Array.from(Array(7).keys(), (key) => key + 1).reduce(
  (allFields, currentPhotoCount) => {
    return {
      ...allFields,
      [`foto_${currentPhotoCount}`]: { type: ['string', 'null'] },
    };
  },
  {},
);

const toExport = (baselayers) => [
  {
    key: 'funkmesswagen',
    name: 'Funkmesswagen',
    allowTilePublication: REACT_APP_ENVIRONMENT === 'prod',
    getFeatureTitle: (feature) => feature.get('bezeichnung') || '',
    search: 'default',
    baseLayers: baselayers,
    styleFunction: (feature, isSelected) => {
      const category = feature.get('category');
      const styles = [
        new Style({
          image: new Circle({
            radius: 8,
            fill: new Fill({
              color: category === 'VERSCHIEDENES' ? 'blue' : 'red',
            }),
            stroke: new Stroke({ color: 'black', width: 2 }),
          }),
          zIndex: isSelected ? 100 : 0,
        }),
      ];
      if (isSelected) {
        styles.unshift(
          new Style({
            image: new Circle({
              radius: 15,
              fill: new Fill({
                color: 'rgba(0, 31, 135, 0.5)',
              }),
            }),
          }),
        );
      }
      return styles;
    },
    uiSchema: uiSchemas.funkmesswagen,
    schemaOverrides: {
      properties: {
        ...fotoSchemaFields,
      },
    },
    oleConfig: {
      canAddGeometries: true,
      canEditGeometries: true,
      canDeleteGeometries: true,
    },
    listConfig: {
      getColumns: () => [
        { field: 'bezeichnung', headerName: 'Bezeichnung', flex: 1 },
        {
          field: 'description',
          headerName: 'Beschreibung',
          flex: 1,
        },
        {
          field: 'category',
          headerName: 'Kategorie',
          flex: 1,
        },
        { field: 'modified_at', headerName: 'Bearbeitet am', flex: 1 },
        { field: 'modified_by', headerName: 'Bearbeitet durch', flex: 1 },
      ],
      getRow: (feature) => ({
        bezeichnung: feature.get('bezeichnung'),
        description: feature.get('description'),
        category:
          feature.get('category') === 'SBB_FUNKMESSWAGEN'
            ? 'SBB-Funkmesswagen'
            : 'Verschiedenes',
        modified_at: toDateString(feature.get('modified_at')),
        modified_by: feature.get('modified_by'),
      }),
    },
  },
];

export default toExport;
