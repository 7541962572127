import React, { useCallback, useEffect, useState, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import WKTFormat from 'ol/format/WKT';
import { Layer, MapboxStyleLayer } from 'mobility-toolbox-js/ol';
import DuplicateWarnDialog from '../DuplicateWarnDialog';

import {
  setFeatures,
  setSelectedFeature,
  setDialogVisible,
  fetchJsonData,
  getFeatures,
} from '../../model/app/actions';
import { setLayers } from '../../model/map/actions';
import { isNewFeature } from '../../utils/featureUtils';
import usePrevious from '../../utils/usePrevious';

const propTypes = {
  vectorLayer: PropTypes.instanceOf(Layer).isRequired,
  layers: PropTypes.arrayOf(PropTypes.instanceOf(Layer)).isRequired,
};

const format = new WKTFormat();

const StationDetector = ({ vectorLayer, layers }) => {
  const topic = useSelector((state) => state.app.topic);
  const map = useSelector((state) => state.map.map);
  const features = useSelector((state) => state.app.features);
  const filters = useSelector((state) => state.app.filters);
  const selectedFeature = useSelector((state) => state.app.selectedFeature);
  const backendApiUrl = useSelector((state) => state.app.backendApiUrl);
  const [newStation, setNewStation] = useState();
  const [showDialog, setShowDialog] = useState();
  const previousStation = usePrevious(newStation);
  const dispatch = useDispatch();
  const baseLayer = topic.baseLayers[0];

  const closeWarnDialog = useCallback(() => {
    // Close the warn dialog
    setShowDialog(false);
    dispatch(setDialogVisible());
  }, [dispatch]);

  const onStationClick = useCallback(
    (feature, olMap, coordinate) => {
      if (
        !feature ||
        !backendApiUrl ||
        olMap.hasFeatureAtPixel(olMap.getPixelFromCoordinate(coordinate), {
          hitTolerance: 15,
        })
      ) {
        // Avoid creating stations if a feature is already present
        return null;
      }

      return fetchJsonData(
        `${backendApiUrl}/${topic.key}/form/${feature.get('sbb_id')}`,
        'GET',
      )
        .then(() => {
          // We prevent feature creation if there is already a feature in the backend with the same sbb_id
          // This is primarily to prevent creating features at locations with hidden features (e.g if a feature has been filtered out)
          setShowDialog(true);
          dispatch(setDialogVisible());
        })
        .catch((error) => {
          // If a no handicap station is returned we proceed with creating the feature
          if (error.detail === 'Nicht gefunden.') {
            setNewStation(feature);
          }
        });
    },
    [dispatch, topic, backendApiUrl],
  );

  // Add stations style layer
  const stationsLayer = useMemo(
    () =>
      new MapboxStyleLayer({
        name: 'ch.sbb.netzkarte.stationen',
        visible: true,
        mapboxLayer: baseLayer,
        styleLayer: {
          id: 'stations',
          source: 'base',
          'source-layer': 'osm_points',
          type: 'circle',
          paint: {
            'circle-radius': 10,
            'circle-color': 'rgb(0, 61, 155)',
            'circle-opacity': [
              'case',
              ['boolean', ['feature-state', 'hover'], false],
              0.5,
              0,
            ],
          },
          filter: ['has', 'sbb_id'], // We only want sbb registered stations
        },
        onClick: (feats, layer, coordinate) => {
          const [feature] = feats;
          const { map: olMap } = layer;
          onStationClick(feature, olMap, coordinate);
        },
        onHover: (feats, layer, coordinate) => {
          if (
            map.hasFeatureAtPixel(map.getPixelFromCoordinate(coordinate), {
              hitTolerance: 15,
            })
          ) {
            // Avoid detecting stations when already mapped
            return;
          }
          map.getTarget().style.cursor = feats.length ? 'pointer' : 'auto';
        },
      }),
    [baseLayer, map, onStationClick],
  );

  useEffect(() => {
    if (
      layers?.length &&
      !layers.find((layer) => layer.name === 'ch.sbb.netzkarte.stationen')
    ) {
      dispatch(setLayers([...layers, stationsLayer]));
      dispatch(
        features?.length
          ? setFeatures(features.filter((feature) => !isNewFeature(feature)))
          : getFeatures(topic, filters, selectedFeature),
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    // Remove newStation and previousStation when a saved station is selected or when the selection is cleared
    if ((selectedFeature && selectedFeature.get('id')) || !selectedFeature) {
      setNewStation();
    }
  }, [selectedFeature]);

  useEffect(() => {
    // Select new station if it doesn't already exist in the edit layer and add it to the app features
    if (newStation && !vectorLayer.olLayer.getSource().hasFeature(newStation)) {
      /**
       * Remove the id to signal it's a new feature and add feature to vectorLayer
       * Set geom and modified_at properties. Update component state.
       * Call the topic specific function
       */
      newStation.unset('id');
      newStation.set(
        'geom',
        `SRID=3857;${format.writeGeometry(newStation.getGeometry())}`,
      );
      newStation.set('modified_at', new Date().toISOString());
      topic.onVtileStationClick(newStation, vectorLayer);
      dispatch(setSelectedFeature(newStation));
      dispatch(setFeatures([...features, newStation]));

      // Remove previousStation from layer and redux when the selected station changes
      if (
        previousStation &&
        previousStation !== newStation &&
        !previousStation.get('id')
      ) {
        dispatch(
          setFeatures(features.filter((feat) => feat !== previousStation)),
        );
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, newStation, previousStation, vectorLayer, topic]);

  return showDialog ? (
    <DuplicateWarnDialog
      onClose={closeWarnDialog}
      onFilterReset={closeWarnDialog}
    />
  ) : null;
};

StationDetector.propTypes = propTypes;

export default StationDetector;
