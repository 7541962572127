import React from 'react';
import PropTypes from 'prop-types';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { makeStyles } from '@mui/styles';
import { de } from 'date-fns/locale';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles(() => ({
  // Remove css of default theme for the date picker.
  dialog: {
    '& .MuiPickersModal-dialogRoot': {
      borderRadius: 0,
    },
  },
  pickerContainer: {
    position: 'relative',
  },
  pickerInput: {
    width: '100%',
    height: 68,
  },
  clearButton: {
    position: 'absolute',
    top: 18,
    right: 70,
    height: 41,
    width: 41,
    padding: 10,
    '& .MuiSvgIcon-root': {
      width: 15,
      height: 15,
    },
  },
  hidden: {
    display: 'none',
  },
}));

const propTypes = {
  label: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.instanceOf(Date),
  onClear: PropTypes.func.isRequired,
};

const defaultProps = {
  value: null,
};

const TimeFilterPicker = ({ label, value, onChange, onClear }) => {
  const { t } = useTranslation();
  const classes = useStyles();
  return (
    <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={de}>
      <div className={classes.pickerContainer}>
        <DatePicker
          className={classes.pickerInput}
          value={value}
          onChange={onChange}
          label={label}
          initialFocusedDate={new Date()}
          format="dd.MM.yyyy"
          showTodayButton
          ampm={false}
          DialogProps={{
            className: classes.dialog,
          }}
          todayLabel={t('Heute')}
          cancelLabel={t('Abbrechen')}
          invalidDateMessage={t('Ungültiges Format', {
            format: 'dd.MM.yyyy',
          })}
        />
        <IconButton
          className={`${classes.clearButton} ${!value ? classes.hidden : ''}`}
          title={t('Filter entfernen')}
          aria-label="Schliessen"
          onClick={onClear}
        >
          <CloseIcon />
        </IconButton>
      </div>
    </LocalizationProvider>
  );
};

TimeFilterPicker.propTypes = propTypes;
TimeFilterPicker.defaultProps = defaultProps;

export default TimeFilterPicker;
