/* eslint-disable no-unused-vars */
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useParams, useHistory } from 'react-router-dom';
import '../../i18n';
import { Provider } from 'react-redux';
import { ThemeProvider } from '@mui/material';
import 'typeface-lato';
import theme from '../../theme';
import {
  setBackendApiUrl,
  setApiKey,
  setTopics,
  setTopic,
  setLoginStatus,
  setHistory,
} from '../../model/app/actions';
import AppPropTypes from '../../model/app/propTypes';
import store from '../../model/store';
import Topics from '../Topics';

const Messtisch = ({
  apiKey,
  backendApiUrl,
  topics,
  translations,
  loginStatus,
}) => {
  const { i18n } = useTranslation();
  const { topicKey } = useParams();
  const history = useHistory();

  useEffect(() => {
    store.dispatch(setHistory(history));
  }, [history]);

  useEffect(() => {
    store.dispatch(setApiKey(apiKey));
  }, [apiKey]);

  useEffect(() => {
    store.dispatch(setBackendApiUrl(backendApiUrl));
  }, [backendApiUrl]);

  useEffect(() => {
    store.dispatch(setTopics(topics));
  }, [topics]);

  useEffect(() => {
    store.dispatch(setLoginStatus(loginStatus));
  }, [loginStatus]);

  useEffect(() => {
    const topic = topics.find(({ key }) => key === topicKey) || topics[0];
    store.dispatch(setTopic(topic));
  }, [topics, topicKey]);

  useEffect(() => {
    if (translations) {
      Object.entries(translations).forEach(([lang, trans]) => {
        i18n.addResourceBundle(lang, 'translation', trans);
      });
    }
  }, [i18n, translations]);

  store.dispatch(setBackendApiUrl(backendApiUrl));
  store.dispatch(setApiKey(apiKey));
  store.dispatch(setTopics(topics));

  return (
    <Provider store={store}>
      <ThemeProvider theme={theme}>
        <Topics />
      </ThemeProvider>
    </Provider>
  );
};

Messtisch.propTypes = {
  apiKey: PropTypes.string.isRequired,
  backendApiUrl: PropTypes.string,
  topics: PropTypes.arrayOf(AppPropTypes.topic).isRequired,
  translations: PropTypes.shape(),
  loginStatus: PropTypes.shape(),
};

Messtisch.defaultProps = {
  backendApiUrl: null,
  translations: null,
  loginStatus: {},
};

export default Messtisch;
