import React from 'react';
import PropTypes from 'prop-types';
import { Input, InputLabel } from '@mui/material';
import { makeStyles } from '@mui/styles';

const propTypes = {
  value: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  label: PropTypes.string.isRequired,
};

const defaultProps = {
  value: '#000000',
};

const useStyles = makeStyles({
  widget: {
    '& input': {
      cursor: 'pointer',
      width: 50,
      height: 35,
    },
  },
});

const ColorWidget = ({ value, onChange, label }) => {
  const classes = useStyles();
  return (
    <>
      <InputLabel>{label}</InputLabel>
      <Input
        type="color"
        value={value}
        className={classes.widget}
        onChange={(e) => onChange(e.target.value)}
        disableUnderline
      />
    </>
  );
};

ColorWidget.propTypes = propTypes;
ColorWidget.defaultProps = defaultProps;
export default ColorWidget;
