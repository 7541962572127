import qs from 'query-string';
import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'lodash/fp';
import OLMap from 'ol/Map';
import Feature from 'ol/Feature';
import WKTFormat from 'ol/format/WKT';
import { unByKey } from 'ol/Observable';
import { withTranslation } from 'react-i18next';
import FullExtent from '@mui/icons-material/ZoomOutMap';
import IconButton from '@mui/material/IconButton';
import { never } from 'ol/events/condition';
import {
  Layer,
  MapboxLayer,
  RoutingControl,
  RoutingLayer,
} from 'mobility-toolbox-js/ol';
import Zoom from 'react-spatial/components/Zoom';
import BasicMap from 'react-spatial/components/BasicMap';
import VectorLayer from 'ol/layer/Vector';
import VectorSource from 'ol/source/Vector';
import OLEEditor from 'ole/src/editor';
import DrawControl from 'ole/src/control/draw';
import ModifyControl from 'ole/src/control/modify';
import './MapContainer.scss';
import { isNewFeature } from '../../utils/featureUtils';
import AppPropTypes from '../../model/app/propTypes';
import {
  setSelectedFeature,
  setSelectableFeatures,
  setOle,
  setFeatures,
  getFeatures,
} from '../../model/app/actions';
import {
  setLayers,
  setResolution,
  setCenter,
  setZoom,
} from '../../model/map/actions';
import { graphs } from '../fields/RoutingField/RoutingField';
import ProgressLine from '../ProgressLine';
import StationDetector from '../StationDetector';

const propTypes = {
  map: PropTypes.instanceOf(OLMap).isRequired,
  oleConfig: PropTypes.shape({
    canAddGeometries: PropTypes.bool,
    canEditGeometries: PropTypes.bool,
  }).isRequired,
  layers: PropTypes.arrayOf(PropTypes.instanceOf(Layer)),
  topic: PropTypes.shape({
    styleFunction: PropTypes.func,
    onVtileStationClick: PropTypes.func,
    baseLayers: PropTypes.arrayOf(PropTypes.instanceOf(Layer)),
    previewConfig: PropTypes.shape({
      previewLayer: PropTypes.instanceOf(MapboxLayer),
      previewEnableValue: PropTypes.string,
    }),
  }).isRequired,
  features: PropTypes.arrayOf(PropTypes.instanceOf(Feature)),
  routingFeatures: PropTypes.arrayOf(PropTypes.instanceOf(Feature)),
  fullExtent: PropTypes.arrayOf(PropTypes.number),
  filters: PropTypes.shape(),

  // mapStateToProps
  schema: AppPropTypes.schema,
  isFeaturesLoading: PropTypes.bool.isRequired,
  selectedFeature: PropTypes.instanceOf(Feature),
  center: PropTypes.arrayOf(PropTypes.number),
  resolution: PropTypes.number,
  zoom: PropTypes.number,
  viewOptions: PropTypes.shape({
    minZoom: PropTypes.number,
    maxZoom: PropTypes.number,
    extent: PropTypes.arrayOf(PropTypes.number),
    projection: PropTypes.string,
  }),
  ole: PropTypes.instanceOf(OLEEditor),
  isPreviewActive: PropTypes.bool,
  isFormUnsaved: PropTypes.bool,
  formData: PropTypes.shape(),

  // mapDispatchToProps
  dispatchSetCenter: PropTypes.func.isRequired,
  dispatchSetZoom: PropTypes.func.isRequired,
  dispatchSetResolution: PropTypes.func.isRequired,
  dispatchSetSelectedFeature: PropTypes.func.isRequired,
  dispatchSetSelectableFeatures: PropTypes.func.isRequired,
  dispatchSetLayers: PropTypes.func.isRequired,
  dispatchSetOle: PropTypes.func.isRequired,
  dispatchSetFeatures: PropTypes.func.isRequired,
  dispatchGetFeatures: PropTypes.func.isRequired,

  // react-i18next
  t: PropTypes.func.isRequired,
};

const defaultProps = {
  center: [0, 0],
  features: [],
  routingFeatures: [],
  layers: [],
  resolution: undefined,
  selectedFeature: null,
  zoom: 9,
  viewOptions: {
    minZoom: 5,
    maxZoom: 20,
  },
  fullExtent: [647268.7555, 5739503.5799, 1188748.6639, 6088362.177],
  ole: null,
  schema: null,
  filters: {},
  isPreviewActive: false,
  isFormUnsaved: false,
  formData: null,
};

const fullExtentStyles = {
  position: 'absolute',
  right: 20,
  top: 320,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  borderRadius: '50%',
  height: 40,
  width: 40,
  boxShadow: '0px 0px 8px rgba(0, 0, 0, 0.199)',
  padding: 0,
  color: '#515151',
  backgroundColor: '#fff',
  '&:hover': {
    backgroundColor: '#fff',
  },
};

class MapContainer extends PureComponent {
  static updateFeatureGeometryField(feature) {
    const format = new WKTFormat();
    feature.set(
      'geom',
      `SRID=3857;${format.writeGeometry(feature.getGeometry())}`,
    );
  }

  constructor(props) {
    super(props);
    const { dispatchSetCenter, dispatchSetZoom } = props;
    // Restore map view from url params.
    const parameters = qs.parse(window.location.search);

    const z = parseInt(parameters.z, 10);
    const x = parseFloat(parameters.x);
    const y = parseFloat(parameters.y);

    if (x && y) {
      dispatchSetCenter([x, y]);
    }

    if (z) {
      dispatchSetZoom(z);
    }

    this.mapRef = React.createRef();
    const { features } = this.props;

    this.editLayer = new Layer({
      key: 'map-editor',
      olLayer: new VectorLayer({
        style: (f) => {
          const { selectedFeature, topic } = this.props; // IMPORTANT: Props need to be imported inside style function
          const isSelected =
            selectedFeature && f.get('id') === selectedFeature.get('id');
          if (topic.styleFunction) {
            const modifyActive = this.modifyCtrl && this.modifyCtrl.getActive();
            return topic.styleFunction(f, isSelected, modifyActive);
          }

          return isSelected ? this.selectStyle : this.defaultStyle;
        },
        source: new VectorSource({
          features,
        }),
      }),
    });

    this.routingLayer = new RoutingLayer({
      name: 'routing-layer',
      style: (feature, resolution) => {
        const { selectedFeature, topic } = this.props; // IMPORTANT: Props need to be imported inside style function
        if (topic.styleFunction) {
          const isSelected =
            selectedFeature && feature.get('id') === selectedFeature.get('id');
          return topic.styleFunction(feature, resolution, isSelected);
        }
        return null;
      },
    });
    this.routingGraphsResolutions = RoutingControl.getGraphsResolutions(
      graphs,
      props.map,
    );
  }

  componentDidMount() {
    const {
      selectedFeature,
      topic,
      features,
      dispatchSetLayers,
      dispatchGetFeatures,
      filters,
      dispatchSetFeatures,
      isPreviewActive,
    } = this.props;

    if (topic && topic.baseLayers && !topic.onVtileStationClick) {
      dispatchSetLayers(
        isPreviewActive && topic?.previewConfig?.previewLayer
          ? [topic?.previewConfig?.previewLayer]
          : [...topic.baseLayers, this.editLayer, this.routingLayer],
      );
      dispatchGetFeatures(topic, filters, selectedFeature);
    }

    // Clean unsaved features from features array on mount
    dispatchSetFeatures(features.filter((feature) => !isNewFeature(feature)));

    // Initialize Ole with topic configs on mount
    this.initializeOle();

    // Set the addFeatureKey
    this.resetAddFeatureKey();

    if (selectedFeature) {
      this.handleModifyControl();
    }
  }

  componentDidUpdate(prevProps) {
    const {
      selectedFeature,
      features,
      routingFeatures,
      topic,
      schema,
      isPreviewActive,
      dispatchSetLayers,
      dispatchSetSelectedFeature,
      isFormUnsaved,
      formData,
    } = this.props;
    const editSource = this.editLayer.olLayer.getSource();
    const routingSource = this.routingLayer.olLayer.getSource();

    if (topic !== prevProps.topic || schema !== prevProps.schema) {
      this.initializeOle();
    }

    if (
      prevProps.features !== features ||
      prevProps.routingFeatures !== routingFeatures ||
      prevProps.formData !== formData
    ) {
      this.resetAddFeatureKey();
    }

    if (selectedFeature !== prevProps.selectedFeature) {
      // Reload OLE to update control options
      this.initializeOle();

      // Update feature styles on edits & activate Modify control
      this.handleModifyControl();
    }

    if (
      topic?.previewConfig?.previewLayer &&
      isPreviewActive !== prevProps.isPreviewActive &&
      !isFormUnsaved
    ) {
      if (isPreviewActive) {
        dispatchSetLayers([topic.previewConfig.previewLayer]);
        dispatchSetSelectedFeature();
      } else {
        dispatchSetLayers([
          ...topic.baseLayers,
          this.editLayer,
          this.routingLayer,
        ]);
      }
    }

    editSource.changed();
    routingSource.changed();
  }

  onFeaturesHover(features) {
    if (this.mapRef) {
      this.mapRef.current.state.node.style.cursor = features.length
        ? 'pointer'
        : 'inherit';
    }
  }

  onFeaturesClick(features) {
    if (!features.length) {
      return;
    }
    const {
      dispatchSetSelectedFeature,
      dispatchSetSelectableFeatures,
    } = this.props;
    const [feature] = features;
    if (
      feature &&
      (this.editLayer.olLayer.getSource().hasFeature(feature) ||
        this.routingLayer.olLayer.getSource().hasFeature(feature))
    ) {
      MapContainer.updateFeatureGeometryField(feature);
      dispatchSetSelectedFeature(feature);
      dispatchSetSelectableFeatures(features);
      feature.changed();
    }
  }

  onMapMoved(evt) {
    const {
      center,
      resolution,
      dispatchSetCenter,
      dispatchSetResolution,
      dispatchSetZoom,
      zoom,
    } = this.props;

    const newResolution = evt.map.getView().getResolution();
    const newZoom = evt.map.getView().getZoom();
    const newCenter = evt.map.getView().getCenter();

    if (zoom !== newZoom) {
      dispatchSetZoom(newZoom);
    }

    if (resolution !== newResolution) {
      dispatchSetResolution(newResolution);
    }

    if (center[0] !== newCenter[0] || center[1] !== newCenter[1]) {
      dispatchSetCenter(newCenter);
    }
  }

  resetAddFeatureKey() {
    const {
      features,
      routingFeatures,
      dispatchSetSelectedFeature,
      dispatchSetFeatures,
    } = this.props;
    const editSource = this.editLayer.olLayer.getSource();
    const routingSource = this.routingLayer.olLayer.getSource();
    // Disable feature add callback
    unByKey(this.addFeatureKey);

    // Clear source and add features
    editSource.clear();
    editSource.addFeatures(features);

    routingSource.clear();
    routingSource.addFeatures(
      routingFeatures.map((f) => {
        const graph = f.get('graph');
        const graphIndex = graphs.findIndex((g) => g[0] === graph);
        if (graphIndex >= 0) {
          f.set('mot', 'rail');
          f.set('minResolution', this.routingGraphsResolutions[graphIndex][0]);
          f.set('maxResolution', this.routingGraphsResolutions[graphIndex][1]);
        }
        return f;
      }),
    );

    // Re-add feature add callback
    this.addFeatureKey = editSource.on('addfeature', (e) => {
      MapContainer.updateFeatureGeometryField(e.feature);
      dispatchSetSelectedFeature(e.feature);
      dispatchSetFeatures([...features, e.feature]);
    });
  }

  initializeOle() {
    const { oleConfig, dispatchSetOle, map, ole } = this.props;
    const controls = [];
    const element = document.createElement('div');

    // Clean active controls
    if (ole) {
      ole.controls.forEach((control) => control.deactivate());
    }

    // Create new OlEEditor
    const newOle = new OLEEditor(map, { showToolbar: false });

    // Add OLE controls depending on topic config
    if (oleConfig.canAddGeometries) {
      this.drawPoints = new DrawControl({
        element,
        title: 'Inhalt hinzufügen',
        source: this.editLayer.olLayer.getSource(),
      });

      this.drawPoints.drawInteraction.on('drawend', (evt) => {
        // Set required and isNewFeature properties on new feature
        evt.feature.set('modified_at', new Date().toISOString());
        MapContainer.updateFeatureGeometryField(evt.feature);
      });
      controls.push(this.drawPoints);
    }

    // Modify interaction.
    if (oleConfig.canEditGeometries) {
      this.modifyCtrl = new ModifyControl({
        element,
        source: this.editLayer.olLayer.getSource(),
        title: 'Geometrie bearbeiten',
        hitTolerance: 1,
        selectMoveOptions: {
          style: null,
        },
        selectModifyOptions: {
          style: null,
        },
        modifyInteractionOptions: {
          deleteCondition: never,
        },
        deleteInteractionOptions: {
          condition: never,
        },
        deselectInteractionOptions: {
          condition: never,
        },
      });

      controls.push(this.modifyCtrl);
    }

    // Add controls
    newOle.addControls(controls);

    // Dispatch new OLEEditor to app state
    dispatchSetOle(newOle);
  }

  handleModifyControl() {
    const { oleConfig, selectedFeature } = this.props;
    /* Remove previous keys and interactions */
    unByKey(this.geometryChangeKey);
    if (this.modifyCtrl) {
      this.modifyCtrl.deactivate();
    }

    if (oleConfig.canEditGeometries && selectedFeature) {
      this.modifyCtrl.activate();
      /* Push feature to Select interaction when switching to map view */
      this.modifyCtrl.selectMove.getFeatures().push(selectedFeature);
      this.geometryChangeKey = selectedFeature.on('change', () => {
        MapContainer.updateFeatureGeometryField(selectedFeature);
      });
    }
  }

  render() {
    const {
      map,
      center,
      zoom,
      isFeaturesLoading,
      viewOptions,
      layers,
      fullExtent,
      topic,
      t,
    } = this.props;

    if (!topic || !layers) {
      return null;
    }

    return (
      <>
        {isFeaturesLoading ? <ProgressLine /> : null}
        <BasicMap
          ref={this.mapRef}
          layers={layers}
          map={map}
          center={center}
          zoom={zoom}
          onMapMoved={(evt) => this.onMapMoved(evt)}
          onFeaturesClick={(feats) => {
            this.onFeaturesClick(feats);
          }}
          onFeaturesHover={(feats) => {
            this.onFeaturesHover(feats);
          }}
          viewOptions={viewOptions}
        />
        <Zoom
          map={map}
          zoomSlider
          titles={{ zoomIn: t('Hineinzoomen'), zoomOut: t('Rauszoomen') }}
        />
        <IconButton
          sx={fullExtentStyles}
          title={t('Volles Ausmass')}
          onClick={() => {
            map.getView().cancelAnimations();
            map.getView().fit(fullExtent, { duration: 1000 });
          }}
        >
          <FullExtent />
        </IconButton>
        {topic.onVtileStationClick instanceof Function && (
          <StationDetector
            vectorLayer={this.editLayer}
            layers={[...topic.baseLayers, this.editLayer, this.routingLayer]}
          />
        )}
      </>
    );
  }
}

MapContainer.propTypes = propTypes;
MapContainer.defaultProps = defaultProps;

const mapStateToProps = (state) => ({
  layers: state.map.layers,
  center: state.map.center,
  topic: state.app.topic,
  resolution: state.map.resolution,
  zoom: state.map.zoom,
  features: state.app.features,
  routingFeatures: state.app.routingFeatures,
  selectedFeature: state.app.selectedFeature,
  uiSchema: state.app.uiSchema,
  isFeaturesLoading: state.app.isFeaturesLoading,
  ole: state.app.ole,
  schema: state.app.schema,
  filters: state.app.filters,
  isPreviewActive: state.app.isPreviewActive,
  isFormUnsaved: state.app.isFormUnsaved,
  formData: state.app.formData,
});

const mapDispatchToProps = {
  dispatchSetSelectedFeature: setSelectedFeature,
  dispatchSetSelectableFeatures: setSelectableFeatures,
  dispatchSetFeatures: setFeatures,
  dispatchGetFeatures: getFeatures,
  dispatchSetResolution: setResolution,
  dispatchSetLayers: setLayers,
  dispatchSetCenter: setCenter,
  dispatchSetZoom: setZoom,
  dispatchSetOle: setOle,
};

export default compose(
  withTranslation(),
  connect(mapStateToProps, mapDispatchToProps),
)(MapContainer);
