import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import Checkbox from '@mui/material/Checkbox';
import Typography from '@mui/material/Typography';
import FormControlLabel from '@mui/material/FormControlLabel';
import { makeStyles } from '@mui/styles';
import { setFilters } from '../../model/app/actions';

const propTypes = {
  filterConfig: PropTypes.shape({
    label: PropTypes.string.isRequired,
    field: PropTypes.string.isRequired,
  }).isRequired,
};

const useStyles = makeStyles({
  checkbox: {
    color: '#767676',
    '&$checked': {
      color: '#eb0000',
    },
  },
});

const BooleanFilter = ({ filterConfig }) => {
  const classNames = useStyles();
  const dispatch = useDispatch();
  const { filters } = useSelector((state) => state.app);
  const { label, field } = filterConfig;

  return (
    <FormControlLabel
      label={<Typography>{label}</Typography>}
      className={classNames.root}
      control={
        <Checkbox
          value={filters[field]}
          className={classNames.checkbox}
          checked={!!filters[field]}
          onChange={(e) => {
            dispatch(
              setFilters({
                ...filters,
                [field]: e.target.checked,
              }),
            );
          }}
        />
      }
    />
  );
};

BooleanFilter.propTypes = propTypes;
export default BooleanFilter;
