import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

i18n
  .use(initReactI18next)
  .use(LanguageDetector)
  .init(
    {
      detection: {
        order: ['querystring'],
        lookupQuerystring: 'lang',
      },
      resources: {
        de: { translation: {} },
      },
      fallbackLng: 'de',
      interpolation: {
        escapeValue: false,
      },
      missingKeyHandler(lng, ns, key, fallbackValue) {
        // eslint-disable-next-line no-console
        console.log(`Missing translations: ${key}`);
        return fallbackValue;
      },
      react: {
        wait: false,
        useSuspense: false,
      },
    },
    (err) => {
      if (err) {
        // eslint-disable-next-line no-console
        console.error('i18n initialisation failed:', err);
      }
    },
  );
