/* eslint-disable react/jsx-props-no-spreading */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Feature } from 'ol';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@mui/styles';
import TextField from '@mui/material/TextField';
import { CircularProgress, MenuItem } from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';

import { fetchJsonData } from '../../model/app/actions';

export const NAME = 'Search';

const propTypes = {
  onSelect: PropTypes.func.isRequired,
  searchEndpoint: PropTypes.string,
  searchValueParam: PropTypes.string,
  otherSearchParams: PropTypes.arrayOf(
    PropTypes.shape({
      param: PropTypes.string,
      value: PropTypes.string,
    }),
  ),
  onInputChange: PropTypes.func,
};

const defaultProps = {
  searchEndpoint: null,
  searchValueParam: 'search',
  otherSearchParams: [],
  onInputChange: null,
};

const useStylesMenuItem = makeStyles({
  root: {
    width: 265,
    display: 'inline-block !important',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
});

const useStyles = makeStyles({
  input: {
    padding: '9px 0',
    '&::placeholder': {
      color: '#515151',
    },
  },
  paper: {
    '&.MuiPaper-root': {
      zIndex: 2,
      boxShadow:
        '0px 5px 5px -3px rgba(0,0,0,0.2), 0px 8px 10px 1px rgba(0,0,0,0.14), 0px 3px 14px 2px rgba(0,0,0,0.12)',
    },
  },
  paperClosed: {
    // To avoid having the list to open on search click, even if empty
    display: 'none',
  },
});

let abortController = new AbortController();

function Search({
  onSelect,
  searchEndpoint,
  onInputChange,
  searchValueParam,
  otherSearchParams,
  ...otherProps
}) {
  const classes = useStyles();
  const classesMenuItem = useStylesMenuItem();
  const { t } = useTranslation();
  const topic = useSelector((state) => state.app.topic);
  const [isClosed, setIsClosed] = useState(true);
  const [items, setItems] = useState([]);
  const [loading, setLoading] = useState(false);

  return (
    <Autocomplete
      options={[...items]}
      freeSolo
      className={classes.autocomplete}
      filterOptions={(feats) => feats}
      getOptionLabel={(feature) =>
        feature && topic?.getFeatureTitle ? topic.getFeatureTitle(feature) : ''
      }
      renderOption={(props, feature) => {
        const title =
          feature && topic?.getFeatureTitle
            ? topic.getFeatureTitle(feature)
            : '';
        return (
          <MenuItem
            {...props}
            key={feature.get('id') || feature.get('name') || feature.get('uid')}
            onClick={() => {
              document.activeElement.blur();
              onSelect(feature);
            }}
            classes={classesMenuItem}
            title={title}
          >
            {title}
          </MenuItem>
        );
      }}
      noOptionsText={t('Kein Ergebnis')}
      onChange={(evt, feature) => {
        if (feature) {
          onSelect(feature);
        }
      }}
      onInputChange={async (evt, val) => {
        if (val.length > 0) {
          abortController.abort();
          abortController = new AbortController();
          setLoading(true);
          await (onInputChange
            ? onInputChange(evt, val, abortController).then((feats) => {
                setLoading(false);
                setIsClosed(false);
                setItems(feats);
              })
            : fetchJsonData(
                `${searchEndpoint}/?${searchValueParam}=${encodeURIComponent(
                  val,
                )}${
                  otherSearchParams.length
                    ? `&${otherSearchParams
                        .map((p) => `${p.param}=${p.value}`)
                        .join('&')}`
                    : ''
                }`,
                'GET',
                { signal: abortController.signal },
              ).then((features) => {
                setLoading(false);
                setIsClosed(false);
                setItems(features.map((f) => new Feature(f)));
              })
          ).catch((err) => {
            if (!/AbortError/.test(err.toString())) {
              setLoading(false);
            }
            // eslint-disable-next-line no-console
            console.error(err);
          });
        } else if (items.length) {
          setIsClosed(true);
          setItems([]);
        }
      }}
      classes={{
        input: classes.input,
        inputRoot: classes.inputRoot,
        paper: `${classes.paper}${isClosed ? ` ${classes.paperClosed}` : ''}`,
        popupIndicator: classes.popupIndicator,
        listbox: classes.listbox,
      }}
      renderInput={(params) => (
        <span style={{ position: 'relative' }}>
          <TextField placeholder={t('Suche nach Features')} {...params} />
          {loading && (
            <CircularProgress
              size={15}
              sx={{ position: 'absolute', top: 15, right: 30, zIndex: 2 }}
            />
          )}
        </span>
      )}
      {...otherProps}
    />
  );
}

Search.propTypes = propTypes;
Search.defaultProps = defaultProps;

export default Search;
