/* eslint-disable no-restricted-globals */
import React, {
  useCallback,
  useMemo,
  useEffect,
  useState,
  createRef,
} from 'react';
import { MdDone, MdClear } from 'react-icons/md';
import { useDispatch, useSelector } from 'react-redux';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TableSortLabel,
} from '@mui/material';
import { format, isValid } from 'date-fns';
import ProgressLine from '../ProgressLine';
import { isNewFeature, getFeatureProperty } from '../../utils/featureUtils';
import { setSelectedFeature } from '../../model/app/actions';

import './List.scss';

/**
 * Source sortable material-ui table:
 * https://material-ui.com/components/tables/#sorting-amp-selecting
 * */
const desc = (a, b, orderBy) => {
  if (typeof orderBy === 'object') {
    const firstKey = Object.keys(orderBy)[0];
    const subKey = orderBy[firstKey];
    if (b.get(firstKey)[subKey] < a.get(firstKey)[subKey]) {
      return -1;
    }
    if (b.get(firstKey)[subKey] > a.get(firstKey)[subKey]) {
      return 1;
    }
    return 0;
  }
  if (getFeatureProperty(b, orderBy) < getFeatureProperty(a, orderBy)) {
    return -1;
  }
  if (getFeatureProperty(b, orderBy) > getFeatureProperty(a, orderBy)) {
    return 1;
  }
  return 0;
};

const stableSort = (array, cmp) => {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = cmp(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
};

const getSorting = (order, orderBy) =>
  order === 'desc'
    ? (a, b) => desc(a, b, orderBy)
    : (a, b) => -desc(a, b, orderBy);

// Get property infos even if nested in another field properties.
const getProperty = (properties, columnValue) => {
  const selectedProperty = [];
  // if nested, the properties are separated by commas.
  const columnValues = columnValue.split('.');
  const column = columnValues[columnValues.length - 1];
  Object.entries(properties).forEach((prop) => {
    if (prop[0] === column) {
      selectedProperty.push(prop);
    }
    if (prop[1].properties && prop[1].properties[column]) {
      selectedProperty.push([column, prop[1].properties[column]]);
    }
  });
  return selectedProperty[0];
};

// Get property ui schema infos even if nested in another field properties.
const getUiSchemaProperty = (uiSchema, columnValue) => {
  const selectedProperty = [];
  // if nested, the properties are separated by commas.
  const columnValues = columnValue.split('.');
  const column = columnValues[columnValues.length - 1];
  Object.entries(uiSchema).forEach((prop) => {
    if (prop[0] === column) {
      selectedProperty.push(prop);
    }
    if (prop[1] && prop[1][column]) {
      selectedProperty.push([column, prop[1][column]]);
    }
  });
  return selectedProperty[0];
};

const renderField = (row, column, schema, uiSchema) => {
  const [, property] = getProperty(schema.properties, column);
  const [, uiSchemaProperty] = getUiSchemaProperty(uiSchema, column) || [];
  const featureProperty = getFeatureProperty(row, column);
  if (property && property.type === 'boolean') {
    return !featureProperty ? (
      <MdClear focusable={false} />
    ) : (
      <MdDone focusable={false} />
    );
  }
  if (
    featureProperty &&
    (['date-time'].includes(property.type) ||
      ['date-time'].includes(property.format)) &&
    isValid(new Date(featureProperty))
  ) {
    return format(
      new Date(featureProperty),
      (uiSchemaProperty && uiSchemaProperty['ui:options']?.dateFormat) ||
        'yyyy-MM-dd HH:mm:ss',
    );
  }
  if (
    featureProperty &&
    (['date'].includes(property.type) || ['date'].includes(property.format)) &&
    isValid(new Date(featureProperty))
  ) {
    return format(
      new Date(featureProperty),
      (uiSchemaProperty && uiSchemaProperty['ui:options']?.dateFormat) ||
        'yyyy-MM-dd',
    );
  }
  return featureProperty;
};

const renderTitle = (column, schema) => {
  if (!schema) return null;
  const [, property] = getProperty(schema.properties, column);
  return property.title;
};

const List = () => {
  const {
    schema,
    uiSchema,
    features,
    routingFeatures,
    selectedFeature,
    topic,
    isFeaturesLoading,
  } = useSelector((state) => state.app);

  const dispatch = useDispatch();
  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState('stationsbezeichnung');

  const createSortHandler = useCallback(
    (property) => () => {
      const isDesc = orderBy === property && order === 'desc';
      setOrder(isDesc ? 'asc' : 'desc');
      setOrderBy(property);
    },
    [order, setOrder, orderBy, setOrderBy],
  );

  // list of ref to be able to scroll one into view.
  const refs = useMemo(
    () =>
      [
        ...features,
        ...routingFeatures.filter((f) => f.get('graph') === 'osm'),
      ].reduce((acc, feat) => {
        acc[feat.get('id')] = createRef();
        return acc;
      }, {}),
    [features, routingFeatures],
  );

  const selectedInView = () => {
    if (selectedFeature && !isNewFeature(selectedFeature)) {
      refs[selectedFeature.get('id')].current.scrollIntoView();
    }
  };

  // Remove unsaved features from list
  const cleanFeatures = useMemo(
    () =>
      [
        ...features,
        ...routingFeatures.filter((f) => f.get('graph') === 'osm'),
      ].filter((f) => !isNewFeature(f)),
    [features, routingFeatures],
  );

  useEffect(() => {
    // ComponentDidMount
    selectedInView();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { listColumns } = topic;

  return (
    <>
      {isFeaturesLoading ? (
        <>
          <ProgressLine />
          <div className="ms-list-loading" />
        </>
      ) : null}
      {cleanFeatures && cleanFeatures.length > 0 && listColumns && (
        <Table
          className="ms-list"
          aria-label="simple table"
          data-testid="cartaro-table-list"
        >
          <TableHead>
            <TableRow>
              {listColumns.map((column) => (
                <TableCell key={column}>
                  <TableSortLabel
                    key={column}
                    active={orderBy === column}
                    direction={order}
                    onClick={createSortHandler(column)}
                  >
                    {renderTitle(column, schema)}
                  </TableSortLabel>
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {stableSort(cleanFeatures, getSorting(order, orderBy)).map(
              (row) => (
                <TableRow
                  key={row.get('id')}
                  ref={refs[row.get('id')]}
                  onClick={() => dispatch(setSelectedFeature(row))}
                >
                  {listColumns.map((column) => (
                    <TableCell
                      key={column}
                      className={
                        selectedFeature &&
                        selectedFeature.get('id') === row.get('id')
                          ? 'active'
                          : ''
                      }
                    >
                      {renderField(row, column, schema, uiSchema)}
                    </TableCell>
                  ))}
                </TableRow>
              ),
            )}
          </TableBody>
        </Table>
      )}
    </>
  );
};

export default React.memo(List);
