import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { makeStyles } from '@mui/styles';
import 'typeface-lato';
import Permalink from 'react-spatial/components/Permalink';
import Header from '../Header';
import List from '../List';
import ConfigurableList from '../List/ConfigurableList';
import FormWrapper from '../FormWrapper';
import MapContainer from '../MapContainer';
import Sidebar from '../Sidebar';
import MainDialog from '../MainDialog';
import Notification from '../Notification';
import {
  fetchSchema,
  fetchApiEndpoints,
  setSelectedFeature,
  subscribeToPublication,
  getFeatures,
  setFormData,
  setIgnoreFieldsOnSave,
  setListVisible,
} from '../../model/app/actions';
import { isNewFeature } from '../../utils/featureUtils';
import useIsGeomTopic from '../../utils/useIsGeomTopic';

const defaultOleConfig = {
  canAddGeometries: false,
  canEditGeometries: false,
};

const useStyles = makeStyles({
  root: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    width: '100%',
  },
  content: {
    flex: 1,
    display: 'flex',
  },
  mapList: {
    overflowY: 'auto',
    position: 'relative',
    flexGrow: 1,
  },
  form: {
    overflowY: 'auto',
    overflowX: 'hidden',
    boxShadow: '1px 2px 6px rgba(0, 0, 0, 0.199)',
    zIndex: 5,
    maxWidth: 500,
  },
  formOpen: { minWidth: 500 },
  splitPaneWrapper: {
    height: 'calc(100vh - 50px)',
    display: 'flex',
    flex: 1,
    position: 'relative',
    overflow: 'hidden',
  },
});

const Topics = () => {
  const dispatch = useDispatch();
  const map = useSelector((state) => state.map.map);
  const currentPublication = useSelector(
    (state) => state.app.currentPublication,
  );
  const backendApiUrl = useSelector((state) => state.app.backendApiUrl);
  const topics = useSelector((state) => state.app.topics);
  const topic = useSelector((state) => state.app.topic);
  const selectedFeature = useSelector((state) => state.app.selectedFeature);
  const bulkFeatures = useSelector((state) => state.app.bulkFeatures);
  const ignoreFieldsOnSave = useSelector(
    (state) => state.app.ignoreFieldsOnSave,
  );
  const prevSelectedFeature = useSelector(
    (state) => state.app.prevSelectedFeature,
  );
  const isListVisible = useSelector((state) => state.app.isListVisible);
  const features = useSelector((state) => state.app.features);
  const filters = useSelector((state) => state.app.filters);
  const classes = useStyles({ selectedFeature });
  const [hasGeom, hasRoute] = useIsGeomTopic();

  useEffect(() => {
    if (topic) {
      const { schema } = topic;
      dispatch(fetchApiEndpoints(topic));
      if (!schema) {
        dispatch(fetchSchema(topic));
      }
    }
  }, [topic, dispatch]);

  useEffect(() => {
    if (topic) {
      dispatch(getFeatures(topic, filters, selectedFeature));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [topic, dispatch, filters]);

  useEffect(() => {
    if (
      selectedFeature &&
      selectedFeature.getGeometry() &&
      isNewFeature(selectedFeature) &&
      isListVisible
    ) {
      // Remove selected feature when switching from map to list view and new feature is selected
      dispatch(setSelectedFeature());
    }
    if (!selectedFeature) {
      dispatch(setFormData());
    }
  }, [selectedFeature, features, dispatch, isListVisible]);

  useEffect(() => {
    if (currentPublication && currentPublication.length) {
      dispatch(subscribeToPublication(currentPublication));
    }
  }, [currentPublication, dispatch]);

  useEffect(() => {
    // We clean the ignore fields when the selected feature changes
    if (
      selectedFeature !== prevSelectedFeature &&
      bulkFeatures?.length < 2 &&
      ignoreFieldsOnSave.length
    ) {
      dispatch(setIgnoreFieldsOnSave([]));
    }
  });

  useEffect(() => {
    dispatch(setListVisible(!hasGeom && !hasRoute));
  }, [dispatch, hasGeom, hasRoute]);

  if (!topic) {
    return null;
  }

  return (
    <div className={classes.root}>
      <Permalink map={map} />
      <Header topics={Object.values(topics)} />
      {topic && (
        <div className={classes.content}>
          <Sidebar
            oleConfig={topic.oleConfig || defaultOleConfig}
            filterConfig={topic.filterConfig}
          />
          <div className={classes.splitPaneWrapper}>
            <div className={classes.mapList}>
              {!isListVisible && (
                <MapContainer
                  map={map}
                  oleConfig={topic.oleConfig || defaultOleConfig}
                  topic={topic}
                  viewOptions={topic.viewOptions}
                  fullExtent={topic.fullExtent}
                />
              )}
              {isListVisible && topic.listColumns && <List />}
              {isListVisible && topic.listConfig && <ConfigurableList />}
            </div>
            <div
              className={`${classes.form}${
                selectedFeature ? ` ${classes.formOpen}` : ''
              }`}
            >
              {selectedFeature && <FormWrapper />}
            </div>
          </div>
        </div>
      )}
      <MainDialog backendApiUrl={backendApiUrl} />
      <Notification />
    </div>
  );
};

export default Topics;
