import { format, isValid } from 'date-fns';

const toDateString = (isoString, dateFormat = 'yyyy-MM-dd HH:mm:ss') => {
  if (!isoString) {
    return null;
  }
  const date = new Date(isoString);
  return isValid(date) ? `${format(date, dateFormat)}` : '';
};

export default toDateString;
