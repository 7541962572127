import { FormControlLabel, Checkbox, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { setIgnoreFieldsOnSave } from '../../model/app/actions';

function DisabledWrapper({ children, options, field, title }) {
  const dispatch = useDispatch();
  const [isDisabled, setIsDisabled] = useState(true);
  const ignoreFieldsOnSave = useSelector(
    (state) => state.app.ignoreFieldsOnSave,
  );

  useEffect(() => {
    if (!field) return;
    if (ignoreFieldsOnSave.includes(field) && !isDisabled) {
      dispatch(
        setIgnoreFieldsOnSave(ignoreFieldsOnSave.filter((f) => f !== field)),
      );
    }
    if (!ignoreFieldsOnSave.includes(field) && isDisabled) {
      dispatch(setIgnoreFieldsOnSave([...ignoreFieldsOnSave, field]));
    }
  }, [dispatch, field, ignoreFieldsOnSave, isDisabled]);

  if (!field) return children;

  return (
    <>
      <FormControlLabel
        style={{ padding: '20px 0 10px' }}
        control={
          <Checkbox
            checked={!isDisabled}
            onChange={() => setIsDisabled((val) => !val)}
          />
        }
        label={
          options['ui:label'] ||
          (isDisabled ? `${title} aktivieren` : `${title} deaktivieren`)
        }
      />
      {options['ui:message:disabled'] && isDisabled && (
        <Typography paragraph variant="subtitle2">
          <i>{options['ui:message:disabled']}</i>
        </Typography>
      )}
      {options['ui:message:enabled'] && !isDisabled && (
        <Typography paragraph variant="subtitle2">
          <i>{options['ui:message:enabled']}</i>
        </Typography>
      )}
      <div
        style={{
          opacity: isDisabled ? 0.5 : 1,
          pointerEvents: isDisabled ? 'none' : 'auto',
        }}
      >
        {children}
      </div>
    </>
  );
}

DisabledWrapper.propTypes = {
  field: PropTypes.string.isRequired,
  children: PropTypes.node,
  options: PropTypes.shape({
    'ui:label': PropTypes.string,
    'ui:message:disabled': PropTypes.string,
    'ui:message:enabled': PropTypes.string,
  }),
  title: PropTypes.string,
};
DisabledWrapper.defaultProps = {
  children: null,
  options: {},
  title: null,
};

export default DisabledWrapper;
