import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

function useIsGeomTopic() {
  const schema = useSelector((state) => state.app.schema);
  const [hasGeom, setHasGeom] = useState(true);
  const [hasRoute, setHasRoute] = useState(true);

  useEffect(() => {
    setHasGeom(!!schema?.properties?.geom);
    setHasRoute(!!schema?.properties?.route);
  }, [schema]);

  return [hasGeom, hasRoute];
}

export default useIsGeomTopic;
