/* eslint-disable no-param-reassign */
/* eslint-disable no-underscore-dangle */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  ERROR_REQUIRED,
  PERSON_ERROR_LENGTH,
  PERSON_ERROR_NOT_FOUND,
} from '../../utils/constants';
import PersonSearch from '../PersonSearch';

const propTypes = {
  formData: PropTypes.shape(),
  onChange: PropTypes.func.isRequired,
  schema: PropTypes.shape().isRequired,
  required: PropTypes.bool,
  uiSchema: PropTypes.shape(),
};

const defaultProps = {
  formData: null,
  required: false,
  uiSchema: {},
};

/**
 * A person has only uri field as possible required field.
 */
const PersonSelect = (props) => {
  const { formData, schema, onChange, required, uiSchema } = props;
  const [inputLabel, setInputLabel] = useState(formData?.label);
  const isRequired = required || !!uiSchema?.['ui:required'];
  // Update input when the selected feature change.
  useEffect(() => {
    setInputLabel(formData?.label);
  }, [formData]);

  const errors = [];
  if (isRequired && formData && !formData.uri) {
    errors.push(ERROR_REQUIRED);
  }

  if (
    formData &&
    !formData.uri &&
    formData.label &&
    formData.label.length > 0 &&
    formData.label.length !== 7
  ) {
    errors.push(PERSON_ERROR_LENGTH);
  } else if (formData && !formData.uri && formData?.label?.length === 7) {
    errors.push(PERSON_ERROR_NOT_FOUND);
  }

  return (
    <PersonSearch
      inputValue={inputLabel}
      onChange={onChange}
      endpoint={schema['x-target-list']}
      isRequired={isRequired}
      errors={errors}
      label={uiSchema['ui:title'] || schema.title}
    />
  );
};

PersonSelect.propTypes = propTypes;
PersonSelect.defaultProps = defaultProps;

export default React.memo(PersonSelect);
